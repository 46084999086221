import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "@/api";
import { CompanyReview, TagsInterface } from "@/interfaces";

const api = new API();
@Module({ namespaced: true })
class Reviews extends VuexModule {
  public reviews: CompanyReview[] = [];
  public tags: TagsInterface[] = [];
  public review: CompanyReview | undefined = undefined;

  @Mutation
  public setReviews(newValue: CompanyReview[]): void {
    this.reviews = newValue;
  }
  @Mutation
  public setTags(newValue: TagsInterface[]): void {
    this.tags = newValue;
  }

  @Mutation
  public setReview(newValue: CompanyReview | undefined): void {
    this.review = newValue;
  }

  @Action
  public getReviews() {
    const filtered = this.tags.filter(item => item.isSelected);
    const list = filtered.map(e => e.name).join(",");
    // recording response to store
    const record = (resp: any) => {
      // console.log(resp);

      this.context.commit("setReviews", resp.items);
      if (filtered.length === 0) {
        const tagsIncaps: TagsInterface[] = [];
        resp.tags.forEach((tag: { name: string }) => {
          tagsIncaps.push({ name: tag.name, isSelected: false });
        });
        this.context.commit("setTags", tagsIncaps);
      }
    };
    //
    if (filtered.length > 0) {
      api.getFilteredReviews(list).then(resp => record(resp));
    } else {
      api.getAllReviews().then(resp => record(resp));
    }
  }

  @Action
  public updateTags(payload: TagsInterface[]) {
    this.context.commit("setTags", payload);
  }

  // @Action
  // public getReview(alias: string) {
  //     api.getReview(alias).then(response => {
  //         this.context.commit('setReview', response.item)
  //     })
  // }

  @Action
  public updateReview(payload: CompanyReview) {
    this.context.commit("setReview", payload);
  }
  @Action
  public delReview() {
    this.context.commit("setReview", undefined);
  }
}

export default Reviews;

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class Modal extends VuexModule {
  public isModalShow = false;
  public modalType = "request";

  @Mutation
  public setModalShow(newValue: boolean): void {
    this.isModalShow = newValue;
  }

  @Action
  public showModal(switcher: boolean): void {
    this.context.commit("setModalShow", switcher);
  }

  @Mutation
  public setModalType(newValue: string): void {
    this.modalType = newValue;
  }

  @Action
  public ModalType(modalType: string): void {
    this.context.commit("setModalType", modalType);
  }
}

export default Modal;

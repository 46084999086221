import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "@/api";
import { CasesInterface, TagsInterface } from "@/interfaces";

const api = new API();
@Module({ namespaced: true })
class Projects extends VuexModule {
  public cards: CasesInterface[] = [];
  public tags: TagsInterface[] = [];
  // public selectedProject: CasesInterface | null = localStorage.getItem('selectedProject') ? JSON.parse(localStorage.getItem('selectedProject') as string) : null;
  public selectedProject: CasesInterface | null = null;
  // public preferences: CasesInterface[] = [
  //     {
  //         preview: "projectalfa.png",
  //         projectType: "тип проекта",
  //         name: "Альфа",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "alfa",
  //         color: "#F12F04",
  //         background: "white"
  //     },
  //     {
  //         preview: "projectalfa.png",
  //         projectType: "мобильные приложения",
  //         name: "Spar",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "spar",
  //         color: "#31B742",
  //         background: "white"
  //     },
  //     {
  //         preview: "avisto.png",
  //         projectType: "CRM/ERP",
  //         name: "Ависто",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "avisto",
  //         color: "#63AEFC",
  //         background: "#63AEFC"
  //     },
  //     {
  //         preview: "rosseti.png",
  //         projectType: "мобильные приложения",
  //         name: "россети",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "rosseti",
  //         color: "#2D8FFF",
  //         background: "white"
  //     },
  //     {
  //         preview: "dengimigom.png",
  //         projectType: "CRM/ERP",
  //         name: "деньгимигом",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "dengimigom",
  //         color: "#65BB2F",
  //         background: "white"
  //     },
  //     {
  //         preview: "beeline.png",
  //         projectType: "тип проекта",
  //         name: "beeline",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "beeline",
  //         color: "#FED544",
  //         background: "white"
  //     },
  //     {
  //         preview: "rompetrol.png",
  //         projectType: "мобильные приложения",
  //         name: "rompetrol",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "rompetrol",
  //         color: "#ED4330",
  //         background: "linear-gradient(76.55deg, #FFBEA3 26.16%, #F96A2F 92.82%)"
  //     },
  //     {
  //         preview: "skypark.png",
  //         projectType: "CRM/ERP",
  //         name: "парк высотных развлечений SkyPark",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "skypark",
  //         color: "#D3AA17",
  //         background: "white"
  //     },
  //     {
  //         preview: "elsa.png",
  //         projectType: "мобильные приложения",
  //         name: "elsa",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "elsa",
  //         color: "#6C069C",
  //         background: "white"
  //     },
  //     {
  //         preview: "basecam.png",
  //         projectType: "мобильные приложения",
  //         name: "basecam",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "basecam",
  //         color: "#D1D1D1",
  //         background: "white"
  //     },
  //     {
  //         preview: "tag.png",
  //         projectType: "тип проекта",
  //         name: "t.a.g.",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "tag",
  //         color: "#FF8C72",
  //         background: "white"
  //     },
  //     {
  //         preview: "rosseti.png",
  //         projectType: "тип проекта",
  //         name: "hamster soft",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "hamstersoft",
  //         color: "#E59C29",
  //         background: "white"
  //     },
  //     {
  //         preview: "amigo.png",
  //         projectType: "CRM/ERP",
  //         name: "амиго",
  //         description: "Разработка специализированного программного обеспечения:nbsp;и мобильного приложения для службы такси",
  //         path: "amigo",
  //         color: "#A8BA74",
  //         background: "white"
  //     },
  // ];

  @Mutation
  public setProjects(newValue: CasesInterface[]): void {
    this.cards = newValue;
  }
  @Mutation
  public setTags(newValue: TagsInterface[]): void {
    this.tags = newValue;
  }
  @Mutation
  public setSelectedProject(newValue: CasesInterface | null): void {
    this.selectedProject = newValue;
  }

  @Action
  public getProjects() {
    const filtered = this.tags.filter(item => item.isSelected);
    const list = filtered.map(e => e.name).join(",");
    // recording response to store
    const record = (resp: any) => {
      const toInterface: CasesInterface[] = JSON.parse(
        JSON.stringify(resp.items)
      );
      toInterface.forEach((item: any) => {
        if (
          (!item.color || item.color === "") &&
          (!item.background || item.background === "")
        ) {
          item.color = "#63aefc";
          item.background = "white";
        }
        // this.preferences.find((element) => {
        //     if (item.path === element.path) {
        //         item.preview = element.preview;
        //         item.projectType = element.projectType;
        //         item.color = element.color;
        //         item.background = element.background
        //     } else if (item.color==='' && item.background==='') {
        //         item.color = "#63aefc";
        //         item.background = "white"
        //     }
        // })
      });
      this.context.commit("setProjects", toInterface);
      if (filtered.length === 0) {
        const tagsIncaps: TagsInterface[] = [];
        resp.tags.forEach((tag: { name: string }) => {
          tagsIncaps.push({ name: tag.name, isSelected: false });
        });
        this.context.commit("setTags", tagsIncaps);
      }
    };
    //
    if (filtered.length > 0) {
      api.getFilteredProjects(list).then(resp => record(resp));
    } else {
      api.getAllProjects().then(resp => {
        // console.log(resp);
        record(resp);
      });
    }
  }
  @Action
  public updateSelectedProject(payload: CasesInterface | null) {
    this.context.commit("setSelectedProject", payload);
    localStorage.setItem("selectedProject", JSON.stringify(payload));
  }
  @Action
  public updateTags(payload: TagsInterface[]) {
    this.context.commit("setTags", payload);
  }
  @Action
  public getProjectByAlias(payload: string) {
    api.getSelectedProject(payload).then((response: any) => {
      this.context.commit("setSelectedProject", response.data);
      localStorage.setItem("selectedProject", JSON.stringify(response.data));
    });
  }
}

export default Projects;

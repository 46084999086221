import Axios, { AxiosInstance } from "axios";
import {
  CasesInterface,
  CompanyPageReview,
  CompanyReview,
  Ratings,
  TagsInterface,
  TecArticle
} from "@/interfaces";
import { TranslateResult } from "vue-i18n";
import { i18n } from "@/main";
import { Locales } from "@/i18n/locales";

export class API {
  public client: AxiosInstance;
  projects: any;
  articles: any;

  constructor() {
    this.client = Axios.create({
      baseURL: `${location.protocol}//api.tecman.ru/v1/`,
      // baseURL: location.protocol + '//tecman_api.tecman.ru/v1/',
      headers: {
        "Content-Type": "application/json",
        // "Accept-Language": localStorage.getItem("Locales")
      }
    });
  }

  public reviews: CompanyReview[] = [
    // {
    //   author:
    //     "<p>Константин,</p>" +
    //     "<p>заместитель директора по развитию компании Деньгимигом</p>",
    //   createdAt: 1612171800,
    //   files: ["/temp/dengiMigomReview.png"],
    //   project: {
    //     preview: "/previews/dengimigom.png",
    //     projectType: "CRM/ERP",
    //     path: "деньгимигом",
    //     color: "#65BB2F",
    //     background: "white",

    //     selects: [
    //       {
    //         title: "Отрасль",
    //         select: "Автомобильные сигнализации"
    //       },
    //       {
    //         title: "Технология",
    //         select: "Adobe Air"
    //       },
    //       {
    //         title: "Решение",
    //         select: "Мобильная разработка"
    //       },
    //       {
    //         title: "Платформа",
    //         select: "iOS, Android"
    //       }
    //     ],

    //     image: {
    //       src: "/backgrounds/Dengimigom.svg",
    //       coords: {
    //         lg: {
    //           top: "170px",
    //           left: "700px"
    //         },
    //         md: {
    //           top: "320px",
    //           left: "350px"
    //         },
    //         sm: {
    //           top: "180px",
    //           left: "175px"
    //         }
    //       }
    //     },
    //     title: "Новая система учета для Деньгимигом",
    //     name: "Новая система учета для Деньгимигом",
    //     description:
    //       "Разработка специализированного программного обеспечения и мобильного приложения для службы такси",
    //     summary:
    //       "Деньгимигом – российская микрофинансовая компания (МФК), предоставляющая быстрые и краткосрочные займы физическим лицам. Начала свою работу в 2010 году. Сегодня у Деньгимигом 200+ представительств в 100 населенных пунктах РФ, а число сотрудников достигло 800 человек.",
    //     fullDescription:
    //       "<p>от клиента: Приложение упрощает и ускоряет бизнес-процессы</p>" +
    //       "<p>Россетей. Помогает контролерам фиксировать показания</p>" +
    //       "<p>электросчетчиков и подготавливать отчеты. Приложение упрощает</p>" +
    //       "<p>и ускоряет бизнес-процессы Россетей.</p>",
    //     logo: "/logos/dengimigom.svg",
    //     goals:
    //       "<p>Деньгимигом выбрала ТЕКМЭН в качестве делового партнера и поставила задачу – достигнуть целей, связанных с автоматизацией бизнес-процессов. Деньгимигом планирует максимально переходить в онлайн и сокращать количество микроофисов.</p>" +
    //       "<p>В силу закона № 407-ФЗ, который предполагает существенные изменения в профильном законе 'О микрофинансовой деятельности и микрофинансовых организациях' рынок микрофинансирования кардинально изменится. В текущих условиях рынка требуется разработка принципиально новой системы. Она ускорит масштабирование бизнеса за счёт сбора онлайн-аудитории, упростит работу сотрудников на местах, улучшит контроль за работой в регионах, увеличит прозрачность и управляемость бизнеса для собственников.</p>",
    //     objectives:
    //       "<p>После детального рассмотрения и анализа целей заказчика с разработчиками были сформированы следующие задачи:</p>" +
    //       "<ol style='font-weight: bold'>" +
    //       "<li class='mb-2'><p style='font-weight: normal'>Создание новой системы учета, позволяющей оперативно вносить изменения в бизнес-процессы, а также оптимизировать выдачу займов, работу с просроченной задолженностью, взаимодействие сотрудников по любым вопросам. Микрофинансовая деятельность - непростой процесс, а имеющаяся IT-система не отвечала растущим требованиям бизнеса.</p></li>" +
    //       "<li><p style='font-weight: normal'>Внедрение разработанной нами системы и выход на рынок онлайн кредитования.</p></li>" +
    //       "</ol>",
    //     slider: [
    //       "/temp/dengimigom1.png",
    //       "/temp/dengimigom2.png",
    //       "/temp/dengimigom3.png",
    //       "/temp/dengimigom4.png",
    //       "/temp/dengimigom5.png"
    //     ],
    //     haveDone:
    //       "<div class='col col-12 base-color'>" +
    //       "<img style='margin-bottom: 200px' alt='dengimigiom_ill' src='/temp/dengimigom_ill.png' />" +
    //       "<h2 style='margin-bottom: 30px'>Что было</h2>" +
    //       "<p class='base-color'>Заказчик обратился к нам с такими исходными данными:</p>" +
    //       "<p class='base-color'>– Старая система автоматизации была написана на Delphi 7 + MSSQL и позволяла удовлетворительно справляться с задачами Деньгимигом. Одни разработчики создавали программу в течение нескольких лет, а другие поддерживали и дорабатывали систему под нужды заказчика. За последнее время в МФК накопилось много задач, с которыми не мог справиться штатный программист. Используемые технологии устарели, и как следствие, на рынке труда резко снизилось количество специалистов по ним. </p>" +
    //       "<p class='base-color'>Разработка новой системы гораздо проще, чем отладка ошибок у имеющейся. В текущих условиях требовалось решение следующих задач:</p>" +
    //       "<ol class='bold-counter'>" +
    //       "<li><p>Старая система учета нуждалась в постоянных обновлениях, поддержке и сопровождении. На каждом ПК была установлена программа «Деньги+». В каждом городе, где присутствовал МФК, был 1+ офис, а IT-отдел обслуживал до 400 рабочих станций и решал проблемы пользователей с программой.</p></li>" +
    //       "<li><p>Система выдачи займов. Деньги предоставлялись только оффлайн, при личном визите заемщика в офис.</p></li>" +
    //       "</ol>" +
    //       "<h2 style='margin-top: 200px; margin-bottom: 30px'>Что сделали</h2>" +
    //       "<p class='base-color'>Специалисты из ТЕКМЭН начали с аудита действующей системы учёта и бизнес-процессов, требующих оцифровки. По итогу была составлена пользовательская документация и краткое описание технической части.</p>" +
    //       "<p class='base-color'>В процессе подготовки и разработки новой системы учёта был разработан список приоритетных направлений работы. На первом месте была работа с клиентской частью, которая важна для сотрудников и мидл-менеджеров." +
    //       "<br>Первые результаты, в отличие от апгрейда базы данных и действующей системы, были заметны и понятны сразу. Они помогли быстро повысить лояльность со стороны руководства Деньгимигом.</p>" +
    //       "<h5 style='margin-bottom: 30px'>Список работ по проекту за 2 года:</h5>" +
    //       "<div class='text--body'>" +
    //       "<ol class='ol--numbers ol-two-cols height--300'>" +
    //       "<li><p>Поддержка и доработка существующей системы оперативного учета на базе Delphi 7 + MSSQL.</p></li>" +
    //       "<li><p>Разработка системы выдачи займов через Интернет (Онлайн Займы) Vue.js + PHP 7 + Laravel 5.4 + NodeJS + MSSQL. </p></li>" +
    //       "<li><p>Интеграция с сервисами: Equifax, Scorista, ФССП, ХLombard, MandarinBank, РНКО, смс-шлюзы. </p></li>" +
    //       "<li><div>" +
    //       "<p>Разработка новой системы оперативного учета. Vue.js + PHP7 + Laravel 5.4 + NodeJS + MSSQL:</p>" +
    //       "<ul style='list-style-type: disc'>" +
    //       "<li style='list-style-position: initial'><p>Модуль автоматизации выдачи займов.</p></li>" +
    //       "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу юридических служб и служб взыскания задолженности.</p></li>" +
    //       "<li style='list-style-position: initial'><p>Модуль документооборота компании.</p></li>" +
    //       "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу единого контакт-центра компании.</p></li>" +
    //       "<li style='list-style-position: initial'><p>Глобальное дополнение существующего функционала, которое позволяет выдавать займы на карты, перечислять займы и осуществлять прием платежей на сайте.</p></li>" +
    //       "</ul>" +
    //       "</div></li>" +
    //       "</ol>" +
    //       "</div>" +
    //       "</div>",
    //     result:
    //       "<p class='base-color'>Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.</p>" +
    //       "<div class='row no-gutters mt-11'>" +
    //       "<div class='col col-12 col-md-4 base-color px-5'><h5>Новая система учета</h5><p>Новая система учета – локальное решение на серверах компании, реализованное как WEB-интерфейс. Это разгрузило IT-отдел. Теперь он занимается решением локальных вопросов пользователей, возникающих во время работы с единой системой. </p></div>" +
    //       "<div class='col col-12 col-md-4 base-color px-5'><h5>Полноценный личный кабинет</h5><p>На сайте Деньгимигом теперь есть полноценный личный кабинет. Клиент может оформить, получить и погасить займ всего за 15 минут. В ближайших планах компании – максимальное сокращение оффлайн отделений и увеличение объемов онлайн-займов. </p></div>" +
    //       "<div class='col col-12 col-md-4 base-color px-5'><h5>Увеличение интернет-аудитории</h5><p>Сейчас микрофинансовая компания выдает займы сразу на банковскую карту. Количество офисов уменьшается с каждым днем, а это многократно сокращает издержки и расширяет географию клиентской базы за счёт интернет-аудитории. В ТЕКМЭН удалось разработать и внедрить скидочную систему, которая мотивирует клиентов брать займы на свою карту, не выходя из дома.</p></div>" +
    //       "</div>",
    //     published: true
    //   },
    //   rates: [
    //     {
    //       rate: 5,
    //       title: "Удовлетворенность"
    //     },
    //     {
    //       rate: 5,
    //       title: "Профессионализм"
    //     },
    //     {
    //       rate: 5,
    //       title: "Сроки"
    //     },
    //     {
    //       rate: 5,
    //       title: "Качество"
    //     }
    //   ],
    //   tags: [
    //     { name: "desktop", isSelected: false },
    //     { name: "МФО", isSelected: false },
    //     { name: "хэштег", isSelected: false },
    //     { name: "разработка", isSelected: false }
    //   ],
    //   text:
    //     "“Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.”",
    //   link: "Деньгимигом"
    // }
  ];
  public selections() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ items: i18n.t("selectList") });
      }, 100);
    })
  }

  // public articles: TecArticle[] = [
  //   {
  //     title: "Клиент: — Сколько стоит копия FACEBOOK?",
  //     text:
  //       "<p>«Сколько стоит сделать копию Facebook (Avito, Яндекс.Такси, fl.ru...)?» — один из самых популярных вопросов клиентов, на который мы сегодня дадим развернутый ответ и расскажем, как это выглядит со стороны людей, которым предстоит это делать.</p>" +
  //       "<h2>«Черный ящик»</h2>" +
  //       "<p>Когда нам дают задачу скопировать какой-то сервис, то для нас он представляет собой некий «черный ящик». Абсолютно неважно, что это за программа: сайт, мобильное приложение или драйвер. В любом случае, мы сможем увидеть, как он выглядит снаружи, но у нас нет доступа к тому, что находится внутри.</p>" +
  //       "<p>Это примерно, как если бы нам показали автомобиль и попросили сделать точную копию, но не дали возможности заглянуть под капот: мы можем ограничиться лишь внешним осмотром и посидеть за рулем. А залезть в багажник — уже нельзя!</p>" +
  //       "<p>Соответственно, мы будем вынуждены решать следующие задачи:<br>" +
  //       "Предположить и придумать — а как же внутри устроен этот «автомобиль», у которого мы видим только кузов?</p>" +
  //       "<p>Представить, из каких деталей он состоит. Для понимания: любой современный автомобиль — это примерно 18 000 деталей…</p>" +
  //       "<p>Прикинуть, какие специалисты нужны для создания этих 18 000 деталей и сколько времени займет создание каждой.</p>" +
  //       "<p>В разработке программного обеспечения — похожий процесс: систему, которую мы создаем, нужно разбить на кучу мелких составляющих. Придумать, как и кем их создать, и как они будут взаимодействовать друг с другом. Именно поэтому «просто скопировать» — непростая и объемная задача.</p>" +
  //       "<h2>«Верхушка айсберга»</h2>" +
  //       "<p>Avito, Facebook, Яндекс.Такси… Если бы клиент знал изнутри бизнес, на который ссылается, то он бы обнаружил, что в нем трудятся десятки, а то и сотни программистов, которые создавали сервис в течение нескольких лет.</p>" +
  //       "<p>Оплачены тысячи часов специалистов, которые ушли на производство продукта.</p>" +
  //       "<p>Считая, “сколько стоит скопировать Facebook” мы увидим все результаты их труда. И, когда мы сделаем список этих результатов, клиент всегда обнаруживает, что видел, от силы, 10% “Facebook”.</p>" +
  //       "<p>Остальные 90% ему становятся видны лишь после того, как мы проделали довольно большую работу. Вы ведь не видите двигатель, рулевые рейки, топливопровод, когда садитесь за руль автомобиля?</p>" +
  //       "<p>Что будет потом?</p>" +
  //       "<p>Клиент понимает, что 90% возможностей сервиса ему вообще не нужны. Это трудозатраты, которые не дадут ему никакой пользы. Тысячи человеко-часов, потраченные на возможности, которые ему никогда не пригодятся. Дорогие и бесполезные.</p>" +
  //       "<h2>«Скопируйте дочку соседа, да подешевле!»</h2>" +
  //       "<p>Почему вообще клиент приходит с таким запросом? Ему кажется, что раз эта работа уже проделана, то нет ничего проще, чем взять и скопировать. Экономит кучу денег!</p>" +
  //       "<p>Но есть небольшая проблема — мы ничего не можем взять из Facebook, потому что:</p>" +
  //       "<ol>" +
  //       "<li>У нас (и ни у одного другого подрядчика) нет доступа к исходному коду. Да даже если бы и был — он собственность другой компании.</li>" +
  //       "<li>У нас нет исходников дизайна, то есть дизайн тоже нужно будет пересоздавать.</li>" +
  //       "<li>У нас нет знаний об архитектуре продукта. Мы можем только предположить, как он устроен внутри. Даже если прочтем кучу статей на хабре — там будет лишь приблизительное описание.</li>" +
  //       "</ol>" +
  //       "<p>Увы, запрос “сделайте как у соседа” — не делает работу дешевле :(</p>" +
  //       "<h2>«Дайте мне кочергу!»</h2>" +
  //       "<p>Программный продукт — не самоцель: с его помощью клиент хочет решить свою бизнес-задачу. Например, заработать или сэкономить денег, захватить аудиторию, создать удобный инструмент для сотрудников.</p>" +
  //       "<p>Только вот парадокс: клиент не приходит к нам с вопросом о бизнес-задаче. Он приходит с вопросом о техническом решении. То есть, с запросом типа «мне нужна кочерга». А для чего она ему? Может, он собирается дрова колоть, а там топор нужен?</p>" +
  //       "<p>Клиент не является специалистом по решениям (обычно он решает такую задачу первый раз в жизни), но, увидев кочергу, ему кажется, что ВОТ ОНА, волшебная палочка!</p>" +
  //       "<p>Но, когда мы зададим вопрос «а какую бизнес-задачу вы решаете?» и подумаем, какое решение было бы действительно оптимальным, окажется, что оно не имеет вообще никакого отношения ни к Facebook, ни к кочерге. Ну, вот вообще ничего общего.</p>" +
  //       "<h2>Резюме</h2>" +
  //       "<p>По всему выходит, что запрос «сколько стоит копия…?» — бессмысленный. Для того, чтобы ответить на него буквально, нужно проделать колоссальную работу, которая никак не пригодится ни нам, ни клиенту. Почему так уверены? Да мы делали эту работу, много раз =)</p>" +
  //       "<p>Что же делать? Имеем мнение — писать техническое задание.</p>" +
  //       "<p>Любой нормальный читатель на этом месте подумал “вы говорите это потому, что хотите нам продать!!!”</p>" +
  //       "<p>И да, и нет. Попробуйте найти хорошего строителя, который начнет строительство дома без проектно-сметной документации. Или автомеханика, создающего автомобиль без чертежей. Или опытного предпринимателя, создающего новый бизнес без финмодели.</p>" +
  //       "<p>Даже если мы делаем программу для себя — мы начнем с техзадания. Нам так же, как и вам, не хочется тратить на это “лишние” деньги. Но мы знаем, что без этого не обойтись. Иначе небоскреб — завалится, бизнес будет больше забирать, чем приносить, а с автомобилем — неизвестно, кто на ком будет ездить.</p>" +
  //       "<p>У данной статьи лишь одна цель: избежать бесполезной работы, и делать полезную работу для вас. Поговорим, зачем вам “кочерга”?</p>",
  //     image: "/backgrounds/creditCard.png",
  //     alias: "klient-ckolko-stoit-kopija-facebook",
  //     preview: "/backgrounds/creditCard.png",
  //     description:
  //       "«Сколько стоит сделать копию Facebook (Avito, Яндекс.Такси, fl.ru...)?» — один из самых популярных вопросов клиентов, на который мы сегодня дадим развернутый ответ и расскажем, как это выглядит со стороны людей, которым предстоит это делать.",
  //     tags: [{ name: "Частые вопросы", isSelected: false }],
  //     published: "1612171800"
  //   },
  //   {
  //     title: "AMO, БИТРИКС, 1C и другие: как выбрать и с чего начать?",
  //     text:
  //       "<p>Вы ― собственник компании. Вы поняли, что пора посмотреть на свой бизнес в цифрах. Найти узкие места, утечки денег и информации, все упорядочить и посчитать.</p>" +
  //       "<p>Или же вы ― миддл-менеджер компании, и наконец-то донесли до Биг Босса, что учет и автоматизация бизнеса ― не только круто, а жизненно необходимо.</p>" +
  //       "<p>И вот вы идете в IT-компанию, чтобы специалисты помогли вам внедрить учетную систему. Идете и боитесь: «А вдруг мне зарядят сейчас 100500 мильонов денег, а я даже не буду понимать ― за что? А вдруг меня разведут, как на техосмотре, впарят вместо плановой замены масла и тормозной жидкости новые колодки и перебор двигателя?»</p>" +
  //       "<p>Специально чтобы собственники и миддл-менеджеры понимали, чего ждать, мы начнем с азов — расскажем, из чего исходят IT-компании, когда предлагают разные учетные решения.</p>" +
  //       "<h2>Три уровня задач для учетных систем</h2>" +
  //       "<p>Основные задачи, которые решают системы учета для бизнеса, делятся на три уровня. Каждый последующий уровень более продвинут по отношению к предыдущему.</p>" +
  //       "<p><strong>Уровень 1</strong>. Собрать все данные в одну большую кучу, создав единое информационное пространство. Чаще всего эта задача решается без учета удобства для пользователей. Сотрудники компаний плюются, ругаются матом, и кое-как осваивают новые функции ― хотя и считают их ненужными и излишними. Но все-таки осваивают, и единое информационное пространство начинает наполняться смыслом и цифрами.</p>" +
  //       "<p><strong>Уровень 2.</strong> Задача второго уровня ― сделать учетную систему «с человеческим лицом», дружественным интерфейсом и понятным функционалом. С такой системой должно быть легко работать каждому пользователю. Эффект от решения задач второго уровня может быть разным ― от уменьшения трудозатрат и количества персонала, до начала работы с задачами, к которым в принципе невозможно было подступиться до автоматизации.</p>" +
  //       "<p><strong>Уровень 3.</strong> На этом уровне начинают решаться задачи собственника бизнеса — повышение прозрачности и управляемости бизнеса. Системы учета помогают формировать срезы данных, на основе которых собственник или руководитель может принимать конкретные управленческие решения. Для этого в цифровом выражении должны быть представлены затраты и польза от множества конкретных операций. Это поможет увидеть утечки, неэффективное расходование средств или воровство, либо наоборот — выявить наиболее эффективные направления, и усилить их.</p>" +
  //       "<p>Специалисты, работающие с учетными системами, ориентируются на ваши задачи. Если вам нужно просто единое информационное пространство, ответственный профи не будет впаривать крутые инструменты, позволяющие принимать управленческие решения. Потому, что это дольше, дороже, сложнее, и скорее всего вы не сможете этим пользоваться — это требует квалификации. Если вы хотите получить удобный инструмент, например, для «осуществления быстрых продаж», а управленческие решения ― не ваша епархия, специалисты это тоже учтут.</p>" +
  //       "<p><strong>Промежуточный итог:</strong> система учета в бизнесе решает три основные задачи.</p>" +
  //       "<ol>" +
  //       "<li>Создание единого информационного пространства.</li>" +
  //       "<li>Выявление узких мест, точек роста.</li>" +
  //       "<li>Помощь в принятии управленческих решений.</li>" +
  //       "</ol>" +
  //       "<p><br>" +
  //       "<strong>Совет первый:</strong> посмотрите вокруг, трезво содрогнитесь (с) и определите те задачи, которые должна решить система учета. Как изменится ваша компания в результате того, что будет внедрена система? И если вы — не опытный пользователь учетных систем, возможно, вам стоит такого найти и спросить — «А что вообще может дать система? Покажи свою?»</p>" +
  //       "<h2>Три товарища</h2>" +
  //       "<p>Представим, что живут в одном городе три товарища ― Митя, Геннадий и Олег.</p>" +
  //       "<p>Митя замутил бизнес по продаже надувных шариков и оформлению праздников и мероприятий. Геннадий открыл службу такси, а Олег начал выдавать страждущим микрозаймы.</p>" +
  //       "<p>Митя ведет учет по-простому, в Google Docs и частично в Excel, а кое-что вообще считает на пальцах (профи иногда называют это системой учета из дерьма и палок). Когда (и если) объемы его продаж возрастут, такое ведение учета может стать проблематичным.</p>" +
  //       "<p>Геннадий выделил те зоны бизнеса, где важнее всего автоматизация, и купил коробочное решение, специально разработанное для сферы такси. В нем ограниченный набор модулей ― рабочее место водителя, рабочее место диспетчера и мобильное приложение для пользователей. Для налогового и кадрового учета Геннадий использует традиционные продукты 1С. Такой набор хорошо подходит именно для такси, но если Геннадий займется грузоперевозками, то эту сову на глобус ему уже не натянуть ― придется искать другие инструменты.</p>" +
  //       "<p>Работа хваткого Олега требует жесточайшего учета и контроля. Если он будет плохо управлять финансовыми потоками ― его бизнес скончается так же громко и быстро, как «Древпром», «Слон-финанс» и «Домашние деньги». Олег встраивал в свой бизнес несколько готовых систем учета, но быстро перерос коробочные решения, и задумался о чем-то более тонко настроенном, менее типовом ― о системе, на 100% соответствующей именно его специфике.</p>" +
  //       "<p>Ответа на вопрос «Вот в этих трех бизнесах — какое из решений правильное, идеальное?» у меня нет. Все варианты имеют право на существование, все они работают и помогают решать бизнес-задачи их владельцев. Разница между ними лишь в стоимости и количестве комфорта для пользователей. Поэтому когда три соседа-бизнесмена придут к IT-разработчикам за инструментами для ведения учета, им предложат разные варианты решений ― и это будет правильно.</p>" +
  //       "<p><strong>Совет второй:</strong> Подготовьте для IT-спецов информацию о сфере деятельности компании; о том, кто будет пользоваться системой и как; о том, как вы ведете учет сейчас; о том, что не нравится сейчас, и что захотите от системы в будущем.</p>" +
  //       "<p><a href='https://docs.google.com/document/d/1FPzjISVajiv_wMr1i7eHRXYq1YxqrDcgQp7kvo3Ukj0/edit'>Вот бриф</a>, который я отправляю заказчикам. С одной стороны, он облегчит поиск общего языка с IT-специалистами, которые будут разрабатывать продукт, с другой — поможет вам лучше понимать назначение будущей системы.</p>" +
  //       "<h2>Деньги и ограничения «сверху» и «снизу»</h2>" +
  //       "<p>Два ключевых параметра, которые скажутся на выборе учетных систем для компании ― бюджет и компетенции лица, принимающего решение.</p>" +
  //       "<p>Сегодня на рынке найдется куча инструментов, которые помогут наладить учет, от условно-бесплатных, до стоящих несколько миллионов рублей в месяц. Чем больше ваш бюджет на разработку/внедрение учетной системы для бизнеса, тем большее количество решений вам доступно. Но и бюджет, и количество подходящих вариантов зависят не только от наличия у вашей компании денег ― еще и от вашего понимания задач, которые должна решать ваша система.</p>" +
  //       "<p>Если отчасти наивному в бизнесе Мите предложить разработку навороченной, крутой, суперудобной и обсчитывающей вообще все бизнес-процессы учетной системы, он не согласится. Во-первых, у него нет на это денег. А во-вторых, если деньги у него есть, то все равно ему это не нужно ― система «из дерьма и палок» на его уровне вполне работает! И ему предложат ― что? ― да просто доработку и улучшение этой работающей системы. Это можно назвать ограничением «сверху»: есть решения, которые для Мити слишком хороши, явно too much.</p>" +
  //       "<p>Если Геннадий не собирается, помимо такси, запускать каршеринг или грузоперевозки — ему вряд ли понадобится разработка индивидуальной системы учета. Ему нужна помощь в подборе более удобных, современных и информативных коробочных решений, созданных специально для его сферы бизнеса. И, конечно, в их внедрении и интеграции между собой. Именно это хорошие специалисты ему и предложат.</p>" +
  //       "<p>Но когда к IT-разработчикам придет Олег, ситуация будет принципиально другой. Олег бизнесмен прошаренный, он отлично понимает, что учет и контроль ― добрая половина успеха его микрозаймового дела. Без отслеживания и оценки эффективности всех бизнес-процессов он работу своей компании не представляет. Ему нужна разработка системы учета с нуля и конкретно под его нужды. Поэтому часть решений, которые ему точно по карману, Олег отметет, как недостаточно гибкие и информативные ― и это ограничение количества вариантов учетных решений «снизу».</p>" +
  //       "<p><strong>Совет третий</strong>: Определитесь с тем, какую сумму, исходя из финансовой ситуации и своего понимания бизнес-задач, вы готовы потратить на инструменты учета. Часто именно с обсуждения бюджета начинают общение с вами опытные IT-специалисты (они знают десятки решений вашей задачи, и выбирают из тех, которые вам по карману).</p>" +
  //       "<h2>Зачем так заморачиваться? Excel — самое крутое решение!</h2>" +
  //       "<p>Имею мнение:<strong> автоматизация и учетные системы в бизнесе ― не просто мода</strong>. Это необходимость и возможность для бизнеса выйти на новый уровень.</p>" +
  //       "<p>Приведу пример: я знаком с собственником компании X, которая занималась интернет-торговлей детскими товарами. Всего за 4 года работы эта компания стала лидером рынка. Как им это удалось? Они изначально сделали очень амбициозное заявление: «Доставим ваш заказ в любую точку России за 24 часа». За сутки в любую точку огромной страны ― это очень круто! И требует большой точности и эффективности каждого бизнес-процесса. Отладив, стандартизировав и автоматизировав в своей работе все, что можно, они справились ― и, как следствие, вышли в топ-3 своей отрасли.</p>" +
  //       "<p>Есть и сотни других интернет-магазинов детских товаров ― которые вполне довольны своей долей рынка и своей прибылью: они вкладывают в учет и контроль бизнес-процессов минимум, получая такое же минимальное органическое развитие.</p>" +
  //       "<p>Могут ли «другие» функционировать и приносить прибыль без навороченной системы, разработанной компанией X? Конечно да! Но могут ли стать топовой, пользуясь Excel? Полагаю, вы сами знаете ответ.</p>" +
  //       "<h2>Подведем итоги</h2>" +
  //       "<p><strong>Чтобы понимать, что и почему предлагают вам IT- специалисты,</strong> воспользуйтесь нашими тремя советами. Определитесь со своими задачами, подумайте о том, куда и как вы будете расти, и решите, какие суммы вы готовы вкладывать в развитие ваших личных систем учета.</p>" +
  //       "<h2>С чего начать?</h2>" +
  //       "<p>У опытных бизнес-аналитиков есть поговорка: автоматизация хаоса порождает автоматизированный хаос. Если вы задаетесь вопросом «с чего начать» ― возможно, эта поговорка про вас. В таких случаях первым этапом часто становится постановка управленческого учета. Это больше про процедуры и процессы, нежели про программирование. Делает эту работу бизнес-аналитик либо финдиректор, она помогает собственнику понять, какие задачи в принципе могут решаться системой учета. И даже увидеть ее возможности работы до начала программирования и вложений в приобретение системы. Попробуйте ― и, возможно, следующим в топ-3 мы увидим вас. Удачи!</p>" +
  //       "<div id='gtx-trans' style='position: absolute; left: -26px; top: 2128.72px;'>" +
  //       "<div class='gtx-trans-icon'>&nbsp;</div>",
  //     image: "/backgrounds/creditCard.png",
  //     alias: "amo-bitriks-1c-i-drugie-kak-vybrat-s-chego-nachat",
  //     preview: "/backgrounds/articleB.png",
  //     description:
  //       "<p>Вы ― собственник компании. Вы поняли, что пора посмотреть на свой бизнес в цифрах. Найти узкие места, утечки денег и информации, все упорядочить и посчитать.</p>" +
  //       "<p>Или же вы ― миддл-менеджер компании, и наконец-то донесли до Биг Босса, что учет и автоматизация бизнеса ― не только круто, а жизненно необходимо.</p>",
  //     tags: [
  //       { name: "Частые вопросы", isSelected: false },
  //       { name: "Разработка CRM-систем", isSelected: false },
  //       { name: "Автоматизация", isSelected: false }
  //     ],
  //     published: "1612171800"
  //   },
  //   {
  //     title: "Как с EXCEL пересесть на CRM?",
  //     text:
  //       "<p>Практически любой бизнес начинается с Excel. В нем ведется учет клиентов, финансов и всего, на что хватит фантазии. По мере роста бизнеса данных становится больше, и наступает полная неразбериха: информация путается, теряется, используется неэффективно. Из этого следует, что Excel в условиях современных реалий стоит применять как вспомогательный инструмент в работе, но не как полноценную систему учета для бизнеса. В такой ситуации стоит задуматься о переходе на CRM. Ниже мы расскажем, какие проблемы могут возникать, когда в компании отсутствует эффективная система учета и о том, как осуществить переход к CRM.</p> " +
  //       "<h2>CRM — оперативность взаимодействия с клиентами</h2>" +
  //       "<p>Случается, что менеджеры забывают сделать повторный звонок клиенту, проинформировать о новой услуге или напомнить постоянному покупателю о давно забытой бонусной карте. Стикеры, приклеенные на монитор, не спасут. Отсутствие оперативного взаимодействия с клиентами неминуемо приведет к ухудшению взаимоотношений с ними, что равняется потере прибыли для компании. Решением этой проблемы становится внедрение CRM-системы. Она возьмет на себя работу по отправке уведомлений сотруднику, предоставит исчерпывающую информацию о клиентах, чтобы ни один из них не остался в стороне. Определиться с оптимальным вариантом системы учета для вашего бизнеса поможет консультация бизнес-аналитика.</p>" +
  //       "<h2>Единая база данных</h2>" +
  //       "<p>Большой проблемой является разрозненность информации и отсутствие единого поля для взаимодействия между сотрудниками. Это влечет за собой искажение данных, их потерю и даже кражу. CRM-система представляет из себя единую базу данных: сотрудники используют в работе актуальную и однозначную для всех информацию, а строгое разграничение прав доступа обеспечивает ее безопасность. CRM повышает прозрачность работы сотрудников, позволяет контролировать ситуацию на каждом этапе взаимодействия с клиентом, дает возможность быстрого реагирования на возникающие проблемы для устранения последних или предотвращения развития негативных последствий для бизнеса.</p>" +
  //       "<h2>Комплексный инструмент для автоматизации</h2>" +
  //       "<p>“Зоопарк ПО” - возможно, вы слышали это выражение, которое означает, что в компании используется большое количество разного программного обеспечения под определенные задачи. Это приводит к увеличению издержек в компании: ПО поглощает ресурсы и стоит немалых денег. CRM помогает решить эту проблему и являет собой комплексный инструмент для автоматизации учета. Консультация бизнес-аналитика поможет сформулировать требования к будущей CRM на предмет соответствия вашим бизнес-процессам.</p>" +
  //       "<h2>CRM для аналитики</h2>" +
  //       "<p>Сбор аналитических данных о работе компании - важная составляющая для руководителя. Статистика позволяет оценить эффективность принятой стратегии развития бизнеса и спланировать дальнейшие шаги. В Excel можно столкнуться с трудностью: ценное время уходит на сбор, обработку и анализ информации. В условиях отсутствия единой информационной базы, результат может оказаться ошибочным и неактуальным. CRM-система возьмет на себя работу над рутинными задачами, существенно снизит влияние человеческого фактора и риск возникновения ошибки. С легкостью сформирует отчет в нужном разрезе и периоде времени на основе имеющихся данных. Таким образом, руководитель сможет в режиме онлайн увидеть и оценить текущее состояние дел в компании.</p> " +
  //       "<h2>7 шагов к переходу на CRM</h2>" +
  //       "<h3>Вы уже сталкивались с проблемами, описанными выше? Тогда переходим на CRM.</h3>" +
  //       "<div class='step'>01.</div>" +
  //       "<h3>Начните с себя</h3>" +
  //       "<p>Определите, какие задачи хотите решить с помощью CRM. В качестве советчиков можно привлечь менеджеров, они хорошо знакомы с тонкостями работы. Составьте список, сделайте его подробным наглядным, например, в виде ментальной карты или таблицы; расставьте приоритеты.</p>" +
  //       "<h4>Примерами таких задач могут быть:</h4>" +
  //       "<ul>" +
  //       "<li>обеспечение прозрачности работы сотрудников;</li>" +
  //       "<li>повышение эффективности труда;</li>" +
  //       "<li>автоматизация рутинных операций;</li>" +
  //       "<li>минимизация влияния человеческого фактора;</li>" +
  //       "<li>создание единой базы данных;</li>" +
  //       "<li>интеграция с сервисами;</li>" +
  //       "<li>автоматическое создание отчетов;</li>" +
  //       "<li>планирование встреч, звонков;</li>" +
  //       "<li>контроль продаж на каждом этапе;</li>" +
  //       "<li>оптимизация затрат на маркетинг.</li>" +
  //       "<li>продуманный список в дальнейшем поможет вам определиться, какого типа CRM вам нужна для решения сформулированных задач.</li>" +
  //       "</ul>" +
  //       "<div class='step'>02.</div>" +
  //       "<h3>Определение требований к CRM</h3>" +
  //       "<p>В процессе подготовки к выбору CRM нужно учитывать, для решения какого типа задач она вам потребуется. C распространением CRM и развитием ее внутренних инструментов, стало возможным использовать систему под разные цели и задачи.</p>" +
  //       "<h4>Мы выделили 3 основных типа систем учета по их назначению:</h4>" +
  //       "<ul>" +
  //       "<li>операционная CRM — самая распространенная разновидность, которая призвана упростить, автоматизировать процесс взаимодействия с клиентом;</li>" +
  //       "<li>аналитическая CRM представляет собой инструмент для анализа накопленной информации и планирования стратегии развития;</li>" +
  //       "<li>коллаборационная CRM (англ. collaboration — сотрудничество) существует для совместной работы компании и клиента.</li>" +
  //       "</ul>" +
  //       "<div class='step'>03.</div>" +
  //       "<h3>Варианты решений</h3>" +
  //       "<p>По степени готовности к внедрению CRM системы бывают трёх типов:</p>" +
  //       "<ul>" +
  //       "<li>SaaS-сервисы (представляют собой программное обеспечение, которым сотрудник пользуется через web-браузер или мобильное приложение; провайдер предоставляет вам ПО в аренду);</li>" +
  //       "<li>коробочные решения (универсальная система со стандартным набором функций); </li>" +
  //       "<li>индивидуально разработанные CRM (решение, полностью разработанное под ваш бизнес).</li>" +
  //       "</ul>" +
  //       "<div class='step'>04.</div>" +
  //       "<h3>Пробный период</h3>" +
  //       "<p>Практически для всех готовых решений производители предоставляют бесплатный тестовый период в целях знакомства с системой. Конечно, такая версия далеко не всегда сможет показать весь спектр возможностей CRM, но представление составить можно. Не пренебрегайте этой возможностью: это отличный вариант опробовать решение и лучше понять, что вам нужно от работы с ней.</p>" +
  //       "<div class='step'>05.</div>" +
  //       "<h3>Выбор CRM</h3>" +
  //       "<h4>Резюмируем:</h4>" +
  //       "<ul>" +
  //       "<li>Saas-сервис может быть интересен малому бизнесу со стандартными бизнес-процессами, если в будущем не планируются доработки.</li>" +
  //       "<li>“Коробочный” вариант CRM может подойти малому и среднему бизнесу с наиболее стандартным набором бизнес-процессов.</li>" +
  //       "<li>собственная CRM-система подойдет среднему и крупному бизнесу, поскольку является масштабируемым, готовым к высоким нагрузкам, решением; подойдет компаниям со сложными, нетипичными, уникальными бизнес-процессами. Такая CRM может использоваться для продажи другим компаниям.</li>" +
  //       "</ul>" +
  //       "<p>Мы советуем обращаться за разработкой CRM в компании, у которых есть опыт в реализации таких проектов. Вы будете иметь дело со специалистами, знающими свое дело, и хорошим сервисом. К слову, нашей компанией реализована CRM для парка высотных развлечений SkyPark, бухгалтерской компании “Avisto”, CRM для микрофинансовой организации “ДеньгиМигом”.</p>" +
  //       "<div class='step'>06.</div>" +
  //       "<h3>Перенос данных</h3>" +
  //       "<p>Вы приобрели CRM, но на этом процесс не заканчивается: стоит подумать о переносе данных в CRM. Для начала, определитесь:</p>" +
  //       "<ul>" +
  //       "<li>нужны ли старые данные в новой системе? Полностью или частично?</li>" +
  //       "<li>каким образом будет осуществляться их перенос: вручную (здесь возможны ошибки, потеря данных), с помощью специалиста или с помощью поставщика выбранной CRM (если такая возможность существует).</li>" +
  //       "</ul>" +
  //       "<p>Мы советуем обращаться к поставщику CRM: возможно, в новой системе присутствуют встроенные механизмы переноса данных. В таком случае вы не потеряете ценные данные и сэкономите кучу времени и нервов :)</br>" +
  //       "В случае с индивидуально разработанной CRM, миграция данных происходит плавно, по мере внедрения модулей в работу, под контролем специалистов.</p>" +
  //       "<div class='step'>07.</div>" +
  //       "<h3>Обучение сотрудников</h3>" +
  //       "<p>Обучение сотрудников для работы в CRM – очень важный шаг, от которого зависит успех внедрения. Для этого вы должны:</p>" +
  //       "<ul>" +
  //       "<li>быть заинтересованным. Руководитель — вот кто, в первую очередь, должен быть заинтересован в том, чтобы CRM-система работала эффективно и деньги не были потрачены впустую, быть нацеленным на результат и верить во внедряемый продукт. Сотрудники это чувствуют;</li>" +
  //       "<li>правильно донести суть CRM. Рассказать, для чего она нужна, как может упростить работу, как поможет достичь больших успехов в работе каждого сотрудника, и какие плоды это принесет компании в будущем;</li>" +
  //       "<li>подготовить обучающие материалы: это может быть текстовый файл-инструкция или видео; по окончании обучения можно провести тестирование на понимание того, как пользоваться системой;</li> " +
  //       "<li>сформировать экспертную группу сотрудников, которые впоследствии будут заниматься обучением внутри компании;</li>" +
  //       "<li>создать базу знаний — полезный обучающий ресурс внутри CRM; пополняйте ее новыми материалами и находками, которые помогут пользователям эффективно работать с CRM, а также быстро адаптироваться новичкам.</li>" +
  //       "</ul>" +
  //       "<h2>CRM-система – важный инструмент управления бизнесом</h2>" +
  //       "<p>Это помощник, который упростит работу сотрудников, сделает ее прозрачной и повысит эффективность. Во время консультации с бизнес-аналитиком вы узнаете, сколько времени и средств потребуется для внедрения CRM. Средняя стоимость разработки индивидуальной CRM-системы начинается от 400 000 руб. Решив внедрить CRM в работу, вы обрекаете себя на возможность увеличить продажи, повысить лояльность клиентов и продуктивно управлять компанией.</p>",
  //     image: "/backgrounds/creditCard.png",
  //     alias: "kak-s-excel-peresest-na-crm",
  //     preview: "/backgrounds/creditCard.png",
  //     description:
  //       "Практически любой бизнес начинается с Excel. В нем ведется учет клиентов, финансов и всего, на что хватит фантазии. По мере роста бизнеса данных становится больше, и наступает полная неразбериха: информация путается, теряется, используется неэффективно. Из этого следует, что Excel в условиях современных реалий стоит применять как вспомогательный инструмент в работе, но не как полноценную систему учета для бизнеса.",
  //     tags: [
  //       { name: "Частые вопросы", isSelected: false },
  //       { name: "Автоматизация", isSelected: false }
  //     ],
  //     published: "1612171800"
  //   }
  // ];

  // public tags: TagsInterface[] = [
  //   { name: "mmm" },
  //   { name: "mobile" },
  //   { name: "design" }
  // ];
  public tags() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ items: i18n.t("tags") });
      }, 100);
    })
  }
  // public reviewTags: TagsInterface[] = [
  //   { name: "desktop", isSelected: false },
  //   { name: "МФО", isSelected: false },
  //   { name: "хэштег", isSelected: false },
  //   { name: "разработка", isSelected: false }
  // ];
  public artTags: TagsInterface[] = [
    // { name: "Частые вопросы" },
    // { name: "Разработка CRM-систем " },
    // { name: "Автоматизация " }
  ];

  // public getAllProjects() {
  //     return this.client.get('project').then((response: any) => {
  //         return response.data.success ? {
  //             items: response.data.items,
  //             tags: response.data.tagList
  //         } : response.data.success;
  //     })
  // };
  public getAllProjects() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ items: i18n.t("cases"), tags: i18n.t("tags") });
      }, 100);
    });
  }

  // public getSelectedProject(alias: string) {
  //     return this.client.get(`project/${alias}`).then((response: any) => {
  //         return response.data.success ? {
  //             data: response.data.model
  //         } : response.data.success;
  //     })
  // };
  public getSelectedProject(alias: string) {
    return new Promise(resolve => {
      setTimeout(() => {
        let data: CasesInterface[] = (i18n.t(
          "cases"
        ) as unknown) as CasesInterface[];
        data = data.filter((item: any) => item.path.match(alias));
        if (data.length > 0 && data[0].published) resolve({ data: data[0] });
        else resolve({ data: null });
      }, 100);
    });
  }
  // public getFilteredProjects(list: string) {
  //     return this.client.get(`project/tag?list=${list}`).then((response: any) => {
  //         return response.data.success ? {
  //             items: response.data.items,
  //             tags: response.data.tagList
  //         } : response.data.success;
  //     })
  // }
  public getFilteredProjects(list: string) {
    const listArr = list.split(",");
    return new Promise(resolve => {
      setTimeout(() => {
        const filtered = this.projects.filter((item: { tags: any[] }) => {
          const taglist = item?.tags?.map(e => e.name);
          if (taglist !== undefined) {
            let isEqual = false;
            listArr.forEach(listArrItem => {
              taglist.some(taglistItem => {
                return (isEqual = !!listArrItem.match(taglistItem));
              });
            });
            return isEqual;
          } else return false;
        });
        resolve({ items: filtered });
      }, 100);
    });
  }
  // public getAllReviews() {
  //     return this.client.get('review').then((response: any) => {
  //         return response.data.success ? {
  //             items: response.data.items,
  //             tags: response.data.tagList
  //         } : response.data.success;
  //     })
  // };
  public getAllReviews() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ items: i18n.t("reviews"), tags: i18n.t("reviewTags") });

      }, 100);
    });
  }
  // public getReview(alias: string) {
  //     return this.client.get( `review/${alias}`).then((response: any) => {
  //         return response.data.success ? {
  //             item: response.data.model,
  //         } : response.data.success;
  //     })
  // };
  // public getFilteredReviews(list: string) {
  //     return this.client.get(`review/tag?list=${list}`).then((response: any) => {
  //         return response.data.success ? {
  //             items: response.data.items,
  //             tags: response.data.tagList
  //         } : response.data.success;
  //     })
  // }
  public getFilteredReviews(list: string) {
    const listArr = list.split(",");
    return new Promise(resolve => {
      setTimeout(() => {
        const filtered = this.reviews.filter(item => {
          const taglist = item?.tags?.map(e => e.name);
          if (taglist !== undefined) {
            let isEqual = false;
            listArr.forEach(listArrItem => {
              taglist.some(taglistItem => {
                return (isEqual = !!listArrItem.match(taglistItem));
              });
            });
            return isEqual;
          } else return false;
        });
        resolve({ items: filtered });
      }, 100);
    });
  }
  // public getAllArticles() {
  //     return this.client.get('article').then((response: any) => {
  //         return response.data.success ? {
  //             items: response.data.items,
  //             tags: response.data.tagList
  //         } : response.data.success;
  //     })
  // };
  public getAllArticles() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({ items: i18n.t("articles"), tags: this.artTags });
      }, 100);
    });
  }
  // public getFilteredArticles(list: string) {
  //     return this.client.get(`article/tag?list=${list}`).then((response: any) => {
  //         return response.data.success ? {
  //             items: response.data.items,
  //             tags: response.data.tagList
  //         } : response.data.success;
  //     })
  // }
  public getFilteredArticles(list: string) {
    const listArr = list.split(",");
    return new Promise(resolve => {
      setTimeout(() => {
        const filtered = this.articles.filter((item: { tags: any[]; }) => {
          const taglist = item?.tags?.map(e => e.name);
          if (taglist !== undefined) {
            let isEqual = false;
            listArr.forEach(listArrItem => {
              taglist.some(taglistItem => {
                return (isEqual = !!listArrItem.match(taglistItem));
              });
            });
            return isEqual;
          } else return false;
        });
        resolve({ items: filtered });
      }, 100);
    });
  }
  public sendRequestForm(data: any) {
    return this.client.post("lead", data).then((response: any) => {
      return response.data.success;
    });
  }
  // public sendRequestForm(data: any) {
  //     return new Promise((resolve) => {
  //         setTimeout(() => {
  //             resolve(true)
  //         }, 100)
  //     });
  // }
}

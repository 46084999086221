


















































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { MenuList } from "@/interfaces";

@Component({})
export default class TecMenu extends Vue {
  @Prop() readonly menuList!: Array<MenuList>;

  public closeMenu() {
    this.$emit("closeMenu");
  }
}

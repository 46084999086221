












































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MapData } from '@/interfaces';
import TecBtn from '@/components/tecBtn/TecBtn.vue';

@Component({
  components: { TecBtn },
})
export default class Map extends Vue {
  @Prop({ default: undefined }) readonly mapData?: MapData;
}




































import { Component, Prop, Vue } from "vue-property-decorator";
import Crumbs from "@/components/crumbs/Crumbs.vue";
import { Crumb } from "@/interfaces";

@Component({
  components: { Crumbs },
})
export default class PageHeader extends Vue {
  @Prop({ default: undefined }) readonly crumbs?: Array<Crumb>;
  @Prop({ default: "" }) readonly title?: string;
  @Prop({ default: "" }) readonly subtitle?: string;
  @Prop({ default: undefined }) readonly subtitleWidth?: string;
  @Prop({ default: undefined }) readonly hashString?: string;
  @Prop({ default: 6 }) readonly headersCols?: number;

  get sbtWidth() {
    return this.subtitleWidth ? `max-width: ${this.subtitleWidth}px` : "";
  }
}




















import { Component, Prop, Vue } from "vue-property-decorator";
import { Crumb } from "@/interfaces";

@Component({})
export default class Crumbs extends Vue {
  @Prop() readonly crumbs?: Array<Crumb>;
}








































import { Component, Prop, Vue } from "vue-property-decorator";
import { TagsInterface, TecArticle } from "@/interfaces";
import TCard from "@/components/tecCard/TCard.vue";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn, TCard }
})
export default class ArticlesSlider extends Vue {
  @Prop({ default: undefined }) readonly articles?: TecArticle[][];

  private onboarding = 0;
  private color = "#3852CE";

  public tegString(tags: TagsInterface[]) {
    return tags?.map(e => `#${e.name}`).join(", ");
  }
}

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class SwitchHeader extends VuexModule {
  public switcher = false;

  @Mutation
  public setSwitcher(newValue: boolean): void {
    this.switcher = newValue;
  }

  @Action
  public showHeader(switcher: boolean): void {
    this.context.commit("setSwitcher", switcher);
  }
}

export default SwitchHeader;

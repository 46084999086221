











//Подключение декораторов для typescript
import { Component, Vue, Watch } from "vue-property-decorator";
// Подключение дочернего компонента
import Tecman from "@/components/Tecman.vue"; // @ is an alias to /src
// Подключение метатегов через декораторы typescript
import { Meta } from "@/decorators/vue-meta-decorator";
// Подключение пространства имен для vuex + typescript
import { namespace, State } from "vuex-class";
// Подключение интерфейса описывающего структуру статьи
import { TecArticle } from "@/interfaces";
import { i18n } from "@/main";

// Подключение именованного хранилища
const switcher = namespace("SwitchHeader");
const Articles = namespace("Articles");
const Locale = namespace("Locale");

// Используемые компоненты
@Component({
  components: {
    Tecman
  }
})
export default class Home extends Vue {
  // С помощью декораторов получаем данные из хранилища и
  // регистрируем методы для работы с данными из хранилища
  @switcher.Action
  public showHeader!: (switcher: boolean) => void;
  @Articles.Getter
  public mainArticles!: TecArticle[][];
  @Articles.Getter
  public ifArticlesExist!: boolean;
  @Articles.Action
  public getArticles!: () => void;
  @Articles.State
  public articles!: TecArticle[];
  @Locale.State
  public lang!: string;

  // с помощью декоратора метатегов задаем название страницы и
  // определяем другие необходимые метатеги
  @Meta
  myMetaConfig() {
    return {
      title: `${i18n.t("title.homeTitle")} — TECMAN.RU`
    };
  }
  @Watch("lang")
  onPropertyChanged(value: string, oldValue: string) {
    this.getArticles();
  }
  get selectedArticles(): TecArticle[][] | TecArticle[] {
    return this.$vuetify.breakpoint.smAndDown && this.mainArticles
      ? this.articles.slice(0, 1)
      : this.mainArticles;
  }
  mounted() {
    this.showHeader(true);
    if (!this.ifArticlesExist) {
      this.getArticles();
    }
  }
  destroyed() {
    this.showHeader(false);
  }
}

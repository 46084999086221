







































































































import { Component, Vue } from "vue-property-decorator";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
// import {namespace} from "vuex-class";
import { Chips, formInterface } from "@/interfaces";
import { i18n } from "@/main";

const trigger = (el: HTMLElement, type: string) => {
  const e = document.createEvent("HTMLEvents");
  e.initEvent(type, true, true);
  el.dispatchEvent(e);
};

@Component({
  components: { TecBtn },
})
export default class Request extends Vue {
  get placeholder(): string {
    return `${i18n.t("field.placeholder")}`;
  }
  get chips(): Chips[] {
    return [
      { title: i18n.t("chips.satisfaction"), rating: 5, id: "satisfaction" },
      {
        title: i18n.t("chips.professionalism"),
        rating: 5,
        id: "professionalism",
      },
      { title: i18n.t("chips.deadlines"), rating: 5, id: "timing" },
      { title: i18n.t("chips.quality"), rating: 5, id: "quality" },
    ];
  }
  public text = "";
  public name = "";
  public loading = false;
  public sheet = false;
  public err = false;

  public closeMenu() {
    this.$emit("closeModal");
  }

  public sendReview() {
    this.loading = true;
    setTimeout(() => {
      this.sheet = true;
      this.loading = false;
      this.text = "";
      this.name = "";
      this.err = true;
      console.log(this.text, this.chips);
    }, 2000);
  }
}

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

// shut off the NavigationDuplicated error
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(
  location: import("vue-router").RawLocation
): any {
  // @ts-ignore
  return routerPush.call(this, location).catch((err: any) => err);
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue")
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  // {
  //     path: "/page_not_found",
  //     name: "404",
  //     component: () =>
  //         import(/* webpackChunkName: "404" */ "../views/404.vue")
  // },
  {
    path: "/article/:articleID",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/Article.vue")
  },
  {
    path: "/articles",
    name: "Articles",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/Articles.vue")
  },
  {
    path: "/stack",
    name: "Stack",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/Stack.vue")
  },
  {
    path: "/feedback/:company",
    name: "CompanyFeedback",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/ReviewPage.vue")
  },
  {
    path: "/feedback",
    name: "Feedbacks",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/ReviewsList.vue")
  },
  {
    path: "/projects",
    name: "Projects",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/Projects.vue")
  },
  {
    path: "/project/:projectId",
    name: "Project",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/Project.vue")
  },
  {
    path: "*",
    redirect: "/page_not_found"
  }
];

function scrollBehavior(to: any, from: any, savedPosition: any) {
  if (savedPosition) {
    return savedPosition;
  } else {
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
  }
  return { x: 0, y: 0 };
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

export default router;
















































































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TecCardMain extends Vue {
  @Prop({ default: "" }) readonly img?: string;
  @Prop({ default: "Default Title two lines" }) readonly title?: string;
  @Prop({ default: "Default Title two lines" }) readonly description?: string;
  @Prop({ default: "25" }) readonly messagesCount?: string;
  @Prop({ default: "5" }) readonly targetCount?: string;
  @Prop({ default: "" }) readonly path?: string;
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cards)?_c('v-row',{staticClass:"tecman-cards",attrs:{"no-gutters":""}},_vm._l((_vm.cards),function(card,index){return _c('v-col',{key:("" + index + (card.title)),staticClass:"card-container",attrs:{"cols":"12","md":"4"}},[(_vm.blog)?_c('v-card',{class:[
        'blog-card',
        'px-9',
        _vm.$vuetify.breakpoint.smAndDown ? 'mb-3 mx-auto' : '' ],style:(_vm.bordered ? ("border: 2px solid; border-color: " + _vm.color) : ''),attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push(card.path)}}},[_c('v-list-item',{staticStyle:{"height":"100%"}},[_c('v-list-item-content',{staticClass:"overflow-visible",staticStyle:{"height":"inherit","position":"relative"}},[_c('v-list-item-icon',{staticClass:"ma-0"},[_c('img',{staticStyle:{"max-height":"90px"},attrs:{"src":require(("../../assets/cards/blog/" + (card.imgSRC))),"alt":"icon"}})]),_c('v-list-item-title',{staticClass:"tec-title",staticStyle:{"margin-top":"35px"}},[_c('h4',{staticClass:"base-color"},[_vm._v(_vm._s(card.title))])]),_c('v-list-item-subtitle',[_c('p',{staticClass:"description base-color px-0",domProps:{"innerHTML":_vm._s(card.description)}})]),_c('div',{staticClass:"text-right px-4 tec-arrow"},[_c('svg',{attrs:{"width":"171","height":"15","viewBox":"0 0 171 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 7.5H170M170 7.5L165 14.5M170 7.5L165 1","stroke":"#3852CE"}})])])],1)],1)],1):(_vm.article)?_c('v-card',{class:[
        'article-card',
        'px-9',
        _vm.$vuetify.breakpoint.xs ? 'mb-3 mx-auto' : '',
        _vm.$vuetify.breakpoint.sm ? 'mb-3' : '' ],style:(_vm.bordered ? ("border: 2px solid; border-color: " + _vm.color) : ''),attrs:{"flat":""},on:{"click":function($event){return _vm.$router.push(("/article/" + (card.alias)))}}},[_c('v-list-item',{staticClass:"card-body"},[_c('div',{staticClass:"overflow-visible card-wrap"},[(_vm.tegString(card.tags))?_c('div',{staticClass:"tag"},[(_vm.tegString(card.tags).length > 20)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({class:[
                    'px-0',
                    _vm.$vuetify.breakpoint.smAndDown ? 'mb-0' : '' ]},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.tegString(card.tags))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tegString(card.tags, true).toUpperCase()))])]):_c('p',{class:[
                'tag',
                'px-0',
                _vm.$vuetify.breakpoint.smAndDown ? 'mb-0' : '' ]},[_vm._v(" "+_vm._s(_vm.tegString(card.tags))+" ")])],1):_vm._e(),_c('div',{staticClass:"tec-title"},[_c('h4',{staticClass:"base-color"},[_vm._v(_vm._s(card.title))])]),_c('div',{staticClass:"description"},[_c('p',{staticClass:"base-color px-0 pt-0",domProps:{"innerHTML":_vm._s(card.description)}})]),_c('div',{staticClass:"text-right tec-arrow"},[_c('svg',{attrs:{"width":"171","height":"15","viewBox":"0 0 171 15","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M0 7.5H170M170 7.5L165 14.5M170 7.5L165 1","stroke":"#3852CE"}})])])])])],1):_c('v-card',{class:[
        !_vm.bordered && _vm.$vuetify.breakpoint.smAndDown ? 'pa-2' : 'px-4',
        'middle-card',
        _vm.$vuetify.breakpoint.xs ? 'mx-auto' : '' ],style:(_vm.bordered ? ("border: 2px solid; border-color: " + _vm.color) : ''),attrs:{"dark":"","flat":""}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[(card.imgSRC)?_c('v-col',{staticClass:"mx-auto text-center",class:_vm.titleColor ? 'img' : {},attrs:{"cols":"12"}},[_c('img',{staticClass:"mx-auto",class:_vm.upline ? 'mb-5' : '',staticStyle:{"max-height":"90px","min-height":"90px","max-width":"160px"},attrs:{"src":require(("@/assets/cards/" + (card.imgSRC))),"alt":"icon"}})]):_vm._e(),_c('v-col',{class:[
                'mx-auto',
                'text-center',
                'title',
                _vm.bordered ? 'mt-4 mb-3' : '',
                _vm.upline ? 'upline' : '' ],attrs:{"cols":"12"}},[_c('h4',{class:_vm.titleColor ? 'card-title' : '',style:(_vm.titleColor ? ("color:" + _vm.titleColor + ";") : ''),domProps:{"innerHTML":_vm._s(card.title)}})])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-col',{class:['mx-auto', 'description', !_vm.bordered ? 'outborder' : ''],attrs:{"cols":"12"}},[_c('p',{staticClass:"mx-auto text-center text",class:!_vm.bordered ? 'pl-5' : '',domProps:{"innerHTML":_vm._s(card.description)}})])],1)],1)],1)],1)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
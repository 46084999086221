import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "@/api";
import { formInterface } from "@/interfaces";

const api = new API();
@Module({ namespaced: true })
class FormStore extends VuexModule {
  public formData: formInterface = localStorage.getItem("setFormData")
    ? JSON.parse(localStorage.getItem("setFormData") as string)
    : { name: "", phone: "" };
  public isSendingSuccess = false;

  @Mutation
  public setFormData(newValue: formInterface) {
    this.formData = newValue;
  }
  @Mutation
  public setSendingSuccess(newValue: boolean) {
    this.isSendingSuccess = newValue;
  }

  @Action
  public updateFormData(payload: formInterface) {
    this.context.commit("setFormData", payload);
    localStorage.setItem("FormData", JSON.stringify(payload));
  }

  @Action
  public sendFormData(payload: formInterface) {
    api.sendRequestForm(payload).then(resp => {
      this.context.commit("setSendingSuccess", resp);
    });
  }
}

export default FormStore;





























































































































import { Component, Vue } from "vue-property-decorator";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import { namespace } from "vuex-class";
import { MenuList } from "@/interfaces";
import TecMenu from "@/components/menu/TecMenu.vue";
import Request from "@/components/modal/Request.vue";
import MakeReview from "@/components/modal/makeReview.vue";
import TecLang from "@/components/tecLang/TecLang.vue";
import { i18n } from "@/main";
import { Locales } from "@/i18n/locales";

const switcher = namespace("SwitchHeader");
const menu = namespace("Menu");
const Modal = namespace("Modal");
const Locale = namespace("Locale");

@Component({
  components: { MakeReview, Request, TecMenu, TecBtn, TecLang },
})
export default class TecAppBar extends Vue {
  @switcher.State
  public switcher!: boolean;

  @menu.State
  public isMenuShow!: boolean;

  @menu.State
  public menuList!: Array<MenuList>;

  @menu.Action
  public showMenu!: (switcher: boolean) => void;

  @Modal.State
  public isModalShow!: boolean;

  @Modal.State
  public modalType!: string;

  @Modal.Action
  public showModal!: (switcher: boolean) => void;

  @Modal.Action
  public ModalType!: (ModalType: string) => void;
  @Locale.State
  public lang!: Locales;
  @Locale.Action
  public SAVE_LANG!: (newLang: Locales) => void;

  get menuColor(): string {
    return this.switcher ? "white" : "#3852CE";
  }

  private body = document.body;

  get menuList1(): object {
    return [
      {
        title: i18n.t("menu.profile"),
        link: "/",
        subMenu: [
          {
            title: i18n.t("menu.profileCrm"),
            link: "https://crm.tecman.ru/",
          },
          {
            title: i18n.t("menu.profileMobile"),
            link: "https://mobile-dev.tecman.ru/",
          },
          {
            title: i18n.t("menu.profileSupport"),
            link: "http://support.tecman.ru/",
          },
          // {
          //     title: "веб-разработка",
          //     link: "/a"
          // },
          // {
          //     title: "корпоративные системы",
          //     link: "/q"
          // },
          // {
          //     title: "коробочные решения",
          //     link: "/n"
          // },
          // {
          //     title: "решения для мфо",
          //     link: "/y"
          // },
        ],
      },
      {
        title: i18n.t("menu.projects"),
        link: "/projects",
      },
      {
        title: i18n.t("menu.feed"),
        link: "/feedback",
      },
      {
        title: i18n.t("menu.articles"),
        link: "/articles",
      },
    ];
  }
  public showModalFunc() {
    this.showModal(true);
    this.ModalType("request");
    this.body.classList.add("noscroll");
  }
  public closeModal() {
    this.showModal(false);
    this.body.classList.remove("noscroll");
  }

  public showMenuFunc() {
    this.showMenu(true);
    this.body.classList.add("noscroll");
  }
  public closeMenu() {
    this.showMenu(false);
    this.body.classList.remove("noscroll");
  }
  get selectedLang(): Locales {
    return this.lang;
  }
  set selectedLang(newValue: Locales) {
    this.SAVE_LANG(newValue);
    i18n.locale = newValue;
  }
}

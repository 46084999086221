import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SearchSelects, Selects } from "@/interfaces";
import { API } from "@/api";
const api = new API();

@Module({ namespaced: true })
class Filters extends VuexModule {
  public selectList: SearchSelects[] = [];

  @Mutation
  public setSelectList(newValue: SearchSelects[]): void {
    this.selectList = newValue;
  }

  @Mutation
  public setSelectedItem(payload: {
    isSelected: boolean | Selects;
    id: number;
  }): void {
    const index = this.selectList.findIndex(
      item => item.id && item.id === payload.id
    );
    if (index > -1) this.selectList[index].isSelected = payload.isSelected;
  }

  @Action
  public updateSelectionList(): void {
    const record = (resp: any) => {
      this.context.commit("setSelectList", resp.items);
    };
    api.selections().then(resp => record(resp));
  }

  @Action
  public updateSelectedItem(payload: {
    SelectedItem: boolean | Selects;
    id: number;
  }): void {
    this.context.commit("setSelectedItem", payload);
  }
}

export default Filters;

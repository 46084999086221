export default {
  buttons: {
    consultation: 'заказать консультацию',
    application: 'оставить заявку',
    articlesAll: 'все статьи',
    tagAll: 'все теги',
    feedback: 'отправить отзыв',
    feedbackView: 'посмотреть отзыв',
    again: 'Попробовать позже',
    backHome: 'Вернитесь на главную',
    backHome1: 'на главную',
    projectView: 'посмотреть проект',
    readArticle: 'читать статью',
    more: 'Загрузить еще',
    projectShow: 'Показать проекты по фильтрам',
    cancel: 'отменить',
  },
  main: {
    filters: 'фильтры',
    tags: 'тэги',
  },
  menu: {
    cityMoscow: 'Москва',
    addressMoscow: 'Москва, Рубцовская набережная',
    addressMoscow2: '3с1, этаж 10, офис 4А',
    cityYaroslavl: 'Ярославль',
    addressYaroslavl: 'Ярославль, ул. Пушкина 3Б',
    addressYaroslavl1: 'ул. Пушкина 3Б',
    europa: 'ЕВРОПА/ДРУГИЕ ГОРОДА',
    cityAnd: 'а также...',
    Kaliningrad: '• Калининград',
    Spain: '• Испания',
    CzechRepublic: '• Чехия',
    Germany: '• Германия',
    Ukraine: '• Украина',
    profile: 'направления разработки',
    profileCrm: 'разработка crm/erp систем',
    profileMobile: 'мобильные приложения',
    profileSupport: 'техническое сопровождение',
    projects: 'кейсы',
    feed: 'отзывы',
    articles: 'статьи',
  },
  title: {
    homeTitle: 'Разработка программного обеспечения',
    homeDescr:
      'Решаем сложные, исследовательские, узкоспециализированные, наукоемкие задачи.',
    titleProfileB: 'основные',
    titleProfileP: 'направления',
    titleCases: 'Кейсы',
    titleOfice: 'Наши офисы',
    titleOfice1: 'Наши',
    titleOfice2: 'офисы',
    titleFeedback1: 'Много отзывов',
    titleFeedback2: 'наших клиентов',
    titleAdvantages: 'Преимущества',
    titleFeedbackClient: 'отзывы наших клиентов',
    titleArticles: 'статьи',
    portfolio: 'Портфолио',
    feedback: 'Отзывы',
    feedTitle: 'Оставьте свой отзыв о сотрудничестве с нами',
    development1: 'Описание',
    development2: 'процесса',
    development3: ' разработки',
    client: 'Клиент',
    goals: 'Цели',
    issues: 'Проблемы',
    projectDev: 'Разработка проекта',
    resultDev: 'результат разработки',
    result: 'Результат',
    home: 'Главная',
    articles: 'Статьи',
  },
  map: {
    mapTitle: 'Готовы начать проект с личной встречи?',
    mapDescr:
      '43 сотрудника в 2 офисах (Москва, Ярославль) и более 100 проверенных подрядчиков для редких языков и технологий',
    mapCityGer: 'Германия',
    mapCityCR: 'Чехия',
    mapCitySpan: 'Испания',
    mapCityUkr: 'Украина',
    mapCityKalg: 'Калининград',
    mapCityMos: 'Москва',
    mapCityYar: 'Ярославль',
    mapManager: 'Менеджер продаж',
    mapName: 'Александр Ларин',
  },
  links: {
    web: 'Веб-разработка',
    system: 'Корпоративные системы',
    boxed: 'Внедрение коробочных решений',
    mfis: 'Решения для МФО',
  },
  tabs: {
    tabMore: 'Подробнее о проекте…',
    tabAllCases: 'все кейсы',
    tabOneTitle: 'CRM и ERP системы',
    tabOneContentOneTitle: 'Автоматизация бизнеса для МФК ДеньгиМигом',
    tabOneContentOneDescr:
      'Запущена онлайн-система выдачи займов. Компания теперь выдает деньги населению через интернет с меньшими издержками. Создана новая система оперативного учета для контроля бизнес-процессов. Можно оперативно принимать управленческие решения. Автоматизирована работа с просроченной задолженностью, служба взыскания работает эффективнее.',
    tabOneContentTwoTitle:
      'Разработка CRM-системы для бухгалтерской компании Ависто',
    tabOneContentTwoDescr:
      'Замещение таких программ, как почтовые и текстовые мессенджеры, электронные календари и доски, а также пакеты офисных программ. Разработка функционала для планирования дел и постановки внутренних задач. Создание единой базы клиентов и налоговых органов. Работа с корпоративной почтой, входящие и исходящие письма',
    tabTwoTitle: 'Мобильная разработка',
    tabTwoContentOneTitle:
      'Приложение для сотрудников крупнейшей в мире энергетической компании',
    tabTwoContentOneDescr1:
      'Эта история о том, как вовремя разработанное приложение, помогает упростить жизнь десяткам тысяч сотрудников крупнейшей энергетической компании России.',
    tabTwoContentOneDescr2:
      'Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.',
    tabTwoContentTwoTitle:
      'Система управления многоквартирным домом для стартапа',
    tabTwoContentTwoDescr1:
      'В проекте ELSA мы работали с главной болью в взаимоотношения УК и собственников многоквартирных домов. Боль решить не удалось, люди остаюсь людьми даже с крутым мобильным приложением.',
    tabTwoContentTwoDescr2:
      'А вот процесс взаимодействия сделали проще, например можно внести показания всех счетчиков и получить квитанцию на телефоне, открыть дискуссию с УК или создать голосование, посмотреть историю любых изменений и операций.',
    tabTwoContentThreeTitle: 'Платежный сервис для Билайна и Альфабанка',
    tabTwoContentThreeDescr:
      'Чтобы вы понимали уровень нашей ответственности за проект, скажем так: дедлайном разработки была публичная презентация приложения, на которой присутствовали представители VISA, Билайна и Альфабанка.',
    tabThreeTitle: 'Сложные решения',
    tabThreeContentOneTitle:
      'Система электронных очередей для LETA Engineering',
    tabThreeContentOneDescr:
      'Результатом работ стала готовая система, выдерживающая колоссальные объемы информации, обеспечивающая работу нескольких офисов в разных городах\n' +
      'с большим количеством устройств: электронная очередь для банков,\n' +
      '1С и web-интерфейс для терминалов, Smart-TV приложение. Также появилась возможность доступа через мобильные устройства.\n',
  },
  cards: {
    cardOneTitle1: 'Разработка',
    cardOneTitle2: 'CRM/ERP-систем',
    cardOneDescr:
      'Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений.',
    cardTwoTitle1: 'Мобильные',
    cardTwoTitle2: 'приложения',
    cardTwoDescr:
      'Мы разрабатываем мобильные приложения, которые обеспечат дополнительную точку контакта с клиентом, канал продаж или внутренний сервис для сотрудников.',
    cardThreeTitle1: 'Техническое',
    cardThreeTitle2: 'сопровождение',
    cardThreeDescr:
      'Берем на себя все задачи по разработке и сопровождению программ учета, серверов или порталов, чтобы вы могли сократить финансовые издержки на содержании it-персонала.',
  },
  feedbacks: {
    more: 'еще',
    feedbacks: 'отзывов',
    feedbackOneDescr:
      'Мы ценим оперативность, высокий уровень предоставленных нам услуг, а также надежность разработанных вами программно-аппаратных комплексов.',
    feedbackOneCompany: 'АО «АРМО-ГРУПП»',
    feedbackOneName: 'Горбунов А,Н.',
    feedbackTwoDescr:
      'Разработчикам ООО «Текмэн» удалось успешно завершить разработку и внедрить системы за первоначально запланированные три месяца.',
    feedbackTwoCompany: 'ООО «Милмакс»',
    feedbackTwoName: 'Малиновский И.Е',
  },
  advantages: {
    advantageOneTitle1: 'Выделенная',
    advantageOneTitle2: 'команда',
    advantageOneDescr:
      'Проектная группа занимается только вашим проектом. Не берем параллельные задачи.',
    advantageTwoTitle1: 'Квалификация.',
    advantageTwoTitle2: 'Стандарты. Люди.',
    advantageTwoDescr:
      'Все специалисты участвующие в вашем проекте сертифицированы.',
    advantageThreeTitle1: 'Гарантия без',
    advantageThreeTitle2: 'срока давности',
    advantageThreeDescr:
      'Мы обеспечиваем качество на каждом этапе работы и подтверждаем это гарантией.',
  },
  advertising: {
    advertisingOneTitle: '1 место',
    advertisingOneDescr:
      'Номер один в мобильной разработке по версии CMS Magazine и Ratingruneta.',
    advertisingTwoTitle: '1 место',
    advertisingTwoDescr:
      'Входим в сотню лучших разработчиков ПО для крупнейших компаний по версии Ratingruneta.',
    advertisingThreeTitle: 'in 10-TOP',
    advertisingThreeDescr:
      'Входим в 30 лучших разработчиков сайтов для промышленности по версии Ratingruneta.',
  },
  footer: {
    tecman: 'ООО Текмэн',
    reserved: 'Все права защищены',
    info: 'Правовая информация',
    about: 'О компани',
    services: 'Услуги',
    project: 'Кейсы',
    feedback: 'Отзывы',
    articles: 'Статьи',
    titleOfice: 'Наши офисы',
    oficeMos: 'Москва, Рубцовская набережная, 3с1, этаж 10, офис 4А',
    oficeYar: 'Ярославль, ул. Пушкина 3Б',
    logo: 'Разработка програмного обеспечения',
    stack: 'Технологический стек',
    price: 'Прайс-лист',
  },
  field: {
    name: 'Ваше имя',
    placeholder: 'Напишите ваш отзыв...',
  },
  chips: {
    satisfaction: 'Удовлетворенность',
    professionalism: 'Профессионализм',
    deadlines: 'Сроки',
    quality: 'Качество',
  },
  form: {
    title: 'Оставьте ваши контакты',
    title1: 'и мы вам обязательно перезвоним',
    subtitle: 'Специалисты из ТЕКМЭН начали с аудита действующей системы',
    subtitle1: 'учёта и бизнес-процессов, требующих оцифровки.',
    confid:
      'Нажимая кнопку, вы даёте согласие на обработку персональных данных',
    name: 'ФИО',
    nameRule: 'Введите Ваше имя',
    phoneRule1: 'Формат номера 7 ### ### ## ##',
    phoneRule: 'Начните ввод номера с цифры 7',
    link: 'обработку персональных данных',
    button: 'оставить заявку',
    wrong:
      'Что-то пошло не так и ваша заявка не была отправлена, попробуйте отправить ее позже',
    thanks:
      'Ваш отзыв успешно отправлен, он будет добавлен на сайт после проверки модератором. Спасибо.',
  },
  message: {
    errorMessage:
      'Что-то пошло не так и ваша заявка не была отправлена, попробуйте отправить ее позже',
    successMessage:
      'Спасибо за ваше обращение, наш специалист свяжется с вами в ближайшее время.',
  },
  notFound: {
    backText: 'или посмотрите одни из наших кейсов, пока мы решаем проблему:',
    title: 'Кажется,<br />что-то пошло не так...',
  },
  dm: {
    projectType: 'CRM-СИСТЕМЫ ПОД МИКРОФИНАНСОВЫЕ ОГРАНИЗАЦИИ',
    name: 'ДЕНЬГИМИГОМ',
    descr:
      'Деньгимигом – одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.',
  },
  articles: [
    {
      title: 'Техническая поддержка программного обеспечения',
      text:
        '<h3>Предыстория. Кому и зачем?</h3>' +
        '<p>Клиенты, интересующиеся вопросом технической поддержки, являются обладателями программного обеспечения. Часто оно было разработано с минимальными вложениями - чтобы быстро запустить первую версию продукта.</p>' +
        '<p>В процессе разработки клиенты проходят все возможные проблемы, возникающие, когда пытаешься в первый раз реализовать масштабный проект в новой для себя области и сталкиваешься с задачами, которые могут иметь факторы риска:</p>' +
        '<ul><li>выбор слабых или лукавых исполнителей;</li><li>неполная, противоречивая проектная документация;</li><li>- отсутствие стандартов разработки;</li><li>невозможность “глубоко копнуть” в техническую составляющую проекта;</li><li>непонимание, какие работы нужны, а какие - нет.</li></ul>' +
        "<p class='margin-t'>После реализации продукта - проходит время, появляются серьезные клиенты, большое количество пользователей, и проявляются последствия:</p>" +
        '<ul>  <li>старые подрядчики умеют создавать проекты с нуля, но не умеют поддерживать их жизнь;</li>  <li>очередь задач растягивается на годы, а их реализация идет медленнее, чем хотелось бы;</li>  <li>быстродействие системы оставляет желать лучшего;</li>  <li>нововведения и исправления ошибок приводят к новым ошибкам;</li>  <li>часть задач заходят в тупик, становятся не реализуемы;</li>  <li>заказчику приходится выделять массу времени на процессы, не относящиеся к его бизнесу;</li>  <li>если технология выбрана неудачно - возникают сложности с наймом новых разработчиков.</li></ul>' +
        "<p class='margin-t'>Что можно делать в данной ситуации? Вариантов всего два:</p>" +
        '<ul><li>либо обратиться к специалистам, кто фокусируется на решении таких задач,</li><li>либо воссоздать их бизнес внутри своего (и это примерно, как построить маленький заводик).</li></ul>' +
        '<h2>Что такое техническая поддержка и что туда входит?</h2>',

      text2:
        '<p>Но, так как первые версии систем обычно делаются на коленке, большую часть этих специалистов в своем проекте вы, скорее всего, никогда не видели :)</p>' +
        "<p class='margin-t'>Обеспечение дальнейшей жизни проекта включает в себя те же процессы, а еще:</p>" +
        '<ul><li>управление требованиями,</li><li>обеспечение быстродействия,</li><li>обеспечение информационной безопасности.</li></ul>' +
        "<p class='margin-t'>Строго говоря, эти процессы не помешали бы и в проектной разработке, но когда продукт “живет” - их важность возрастает, и без них уже нельзя обойтись. Цена ошибки (потеря клиентских данных, простои, репутационные риски) становится слишком велика.</p>" +
        "<p class='margin-t'>Когда клиент приходит к нам, необходимо выстроить все эти процессы конкретно под ваш продукт. А для этого нужно, для начала:</p>" +
        '<ul>  <li>разобраться, что представляет из себя система;</li>  <li>определить, какие узкие места есть в текущей реализации системы;</li>  <li>собрать требования бизнес-заказчика, формализовать и упорядочить их.</li></ul>',
      text3:
        '<h2>Первым этапом сотрудничества является аудит системы.</h2>' +
        '<p>Многим клиентам не нравится слово “аудит”. Им кажется, что они платят “за воздух”, который нужен только исполнителям. <br> Это не совсем так. Скорее, они платят за:</p>' +
        '<ul>  <li>документацию, описывающую состав системы, её отдельных модулей, функций, связей;</li>  <li>развертывание инфраструктуры, необходимой для дальнейшей поддержки системы (тестовые и рабочие сервера, специализированное программное обеспечение);</li>  <li>документирование кода;</li>  <li>выявление дыр в информационной безопасности;</li>  <li>грамотный план - как жить дальше. Чтобы проблемы ушли, а пожелания реализовались.</li></ul>' +
        '<p>Срок аудита зависит от размера системы. Для систем, которые писались менее 3 лет, обычно хватает месяца-двух. Если ваш проект больше - надо на него смотреть, чтобы дать оценку.</p>' +
        '<p>Стоимость аудита напрямую зависит от количества и состава работ, которые необходимы клиенту. К примеру, кто-то закажет аудит безопасности, а кто-то нет. Кому-то нужен редизайн. А у кого-то количество доработок таково, что это практически новый отдельный проект.</p>' +
        '<p>Мы не стараемся зарабатывать на аудите. Для нас это возможность показать себя и узнать партнера - заказчика. То есть это не “несколько миллионов занесите, а там посмотрим”.</p>' +
        '<p>Следующим этапом сотрудничества является собственно техническая поддержка.</p>' +
        '<p>Поддержка обычно состоит из трех видов работ:</p>' +
        '<ul>  <li>рефакторинг (переписать существующий программный код, чтобы он работал стабильно);</li>  <li>доработки (реализовать новые пожелания бизнес-заказчика);</li>  <li>миграция (по простому - “переезд”, в случае, если текущая реализация совсем плоха - к примеру, выбрана умирающая технология, которую поддерживать нецелесообразно).</li></ul>' +
        '<p>Во всех этих работах используются специалисты, упомянутые выше:</p>' +
        '<ul>  <li>руководитель проекта,</li>  <li>аналитик,</li>  <li>дизайнер,</li>  <li>верстальщик,</li>  <li>разработчик фронтенд,</li>  <li>разработчик бекенд,</li>  <li>тестировщик,</li>  <li>безопасник… и другие.</li></ul>' +
        '<p>Бывает, что один проект “автоматизация предприятия” включает в себя десятки мини-проектов. И специалисты для их выполнения требуются очень разные.</p>' +
        '<p>Мы предоставляем их в том составе и количестве, которое вам реально необходимо для решения ваших бизнес-задач. Формат договора, который мы заключим с вами, предполагает, что вы ежемесячно приобретаете некоторое фиксированное количество нормо-часов, которые можете использовать по своему усмотрению. Часы, входящие в ежемесячную квоту, даются со скидкой. В случае, если вы использовали больше часов, чем было включено в квоту, выставляется отдельный счет на превышенный объем, по розничным ценам. Если не использовали, сумма оплаты за месяц останется без изменений.</p>' +
        '<p>К примеру, средняя розничная стоимость специалиста в нашем прайс-листе на 2022-ой год составляет 2600 рублей/час.</p>' +
        '<p>Предположим мы заключаем договор, с ежемесячной оплатой 165 000 рублей в месяц за 100 нормо-часов. В первый месяц вы использовали 100 часов и оплатили 165 000. Во второй месяц вы использовали 150 часов, оплатили 165 000 и мы выставили дополнительный счет на 105 000. В третий месяц вы использовали 70 часов, и оплатили 165 000.</p>' +
        '<p>В общем - похоже, как у провайдера интернета или сотовой связи. С той разницей, что мы не просто поддерживаем работу оборудования, у нас много нестандартной ручной работы.</p>' +
        '<p>Большинство клиентов начинают с минимального ежемесячного объема в 100 часов и за первый год “входят во вкус” - увеличивают его, бывает, и в десять раз.</p>' +
        '<p>Чем техническая поддержка лучше проектной работы?</p>' +
        '<p>Возможно, после прочтения этого документа, у вас возник вопрос:</p>' +
        '<p>“Почему мне стоит обратиться к вам, а не к своим старым подрядчикам, или к новым - за небольшой порцией доработок? Зачем мне эти длительные отношения и издержки?”</p>' +
        '<h4>Отвечаю.</h4>' +
        '<p>Знаете, сколько раз переписывали Библию? Догадываетесь, насколько сильно она изменилась в процессе? Примерно то же самое происходит с программой. Ей занимаются годами десятки разных людей, которые работают каждый по своему стандарту. Поэтому все системы, со временем, становятся похожими на лоскутное одеяло - здесь тронул, там расползлось.</p>' +
        '<p>Чем мы отличаемся? Да все просто. Есть два подхода к разработке программ. “Для себя” и “для кого-то”. Если вы возьмете любую ИТ компанию, которая пишет программы для себя, их стандарт качества примерно такой же, как у нас. Только они не напишут программы для вас - у них другой бизнес, они продают программы (это выгоднее, чем продавать специалистов).</p>' +
        '<p>Если же вы возьмете своего старого подрядчика, то что бы вы не думали по этому поводу - он работает по стандарту “для кого-то”. И это всегда игра, где заказчику выгодно отжать побольше работ за свои деньги, а исполнителю - сделать поменьше (пожертвовав качеством). Так как большинство заказчиков не могут “заглянуть под капот” - они получают внешне терпимое, но “такое себе” внутри. Мы позволяем себе так работать - этим и отличаемся.</p>' +
        '<p>Наше предложение - для компаний, которые сильно растут или намерены расти. У которых всегда будет большой поток требований на изменения продукта. Для таких компаний развивать информационную систему мини-проектами - неприемлемо долго. Кроме того, такие мини-проекты часто ухудшают качество системы в целом.</p>' +
        '<p>Также это предложение - для тех компаний, которые считают, что вложения времени в профильный бизнес окупаются лучше, чем в создание нового ИТ бизнеса внутри себя. И готовы платить за возможность СПОКОЙНО заниматься СВОИМ делом.</p>' +
        '<p>Также это предложение для тех компаний, которые считают, что приобрести одного надежного партнера на всю жизнь выгоднее, чем сменить десяток дешевых.</p>' +
        '<p>Если это про вас - будем рады познакомиться.</p>' +
        '<h4>Кто мы:</h4>' +
        '<p>Компания “Текмэн” была создана в 2007 году и задумана для решения задач в области разработки ПАК. В течении 15 лет мы выращивали ключевые компетенции по решению нестандартных задач, часто повышенной сложности, и работе с удаленными подрядчиками. Среди клиентов - Альфа Банк, Газпром, Армо, Протек, Росатом, Легранд, НТВ, Спар и стартапы - Pay-me, Hamstersoft, Radiometer</p>' +
        '<p>Как правило, наши клиенты - те, кто уже сделал одну-две попытки разработать свой продукт или получили проблемы с настройкой готовых коробочных решений. К нам обращаются потому, что не могут рисковать репутацией, терпеть простои, терять деньги или их задача слишком сложна, чтобы поручать её “средним” специалистам.</p>' +
        "<p>Подробнее о компании и нашем портфолио <a target='_blank' href='https://tecman.ru/projects'>www.tecman.ru</a></p>",
      textImage:
        '<p>Программное обеспечение начинается с проекта, включающего в себя процессы:</p>' +
        '<ul>  <li>разработка технической документации</li>  <li>прототипирование</li>  <li>дизайн</li>  <li>разработка</li>  <li>отладка</li>  <li>внедрение</li></ul>' +
        "<p class='margin-t'>И требующего участия, как минимум, следующих специалистов:</p>" +
        '<ul>  <li>руководитель проекта</li>  <li>дизайнер</li>  <li>аналитик</li>  <li>разработчик front-end</li>  <li>разработчик back-end</li>  <li>тестировщик</li></ul>',
      textImageLeft:
        '<p>Зачем вообще это делать?</p>' +
        '<p>Представьте, что вы - писатель и поддерживаете проект «Война и мир».У вас есть техзадание — дописать главу, как Наташа Ростова гуляла по лесу под дождём.</p>' +
        '<p>Вы пишете «шёл дождь», сохраняете, и вдруг вылетает сообщение об ошибке «Наташа Ростова умерла, продолжение невозможно». Почему умерла? Начинаете разбираться.</p>' +
        '<p>Выясняется, что у Пьера Безухова скользкие туфли, он упал, его пистолет ударился о землю и выстрелил в столб, а пуля от столба срикошетила в Наташу. Что делать? Зарядить пистолет холостыми? Поменять туфли? Решили убрать столб. Получаем сообщение «Поручик ржевский умер». Выясняется, что он в следующей главе облокачивается о столб, которого уже нет… Примерно так же происходит работа с системой, у которой нет описания. С той разницей, что её писал не Лев Толстой, а несколько поколений специалистов, не думавших - зачем в лесу, в начале 19 века, нужен столб?</p>',
      description:
        'Клиенты, интересующиеся вопросом технической поддержки, являются обладателями программного обеспечения. Часто оно было разработано с минимальными вложениями - чтобы быстро запустить первую версию продукта.',
      image: '/backgrounds/article-5.png',
      imageRight: '/backgrounds/article-5.1.png',
      imageLeft: '/backgrounds/article-5.2.png',
      preview: '/backgrounds/article-5.png',
      alias: 'tehnicheskaya-podderzhka-programmnogo-obespecheniya',
      tags: [{ name: 'Статьи', isSelected: false }],
      published: '28/04/2022',
    },
    {
      title: 'ЗАЧЕМ РАЗРАБАТЫВАТЬ ТЗ',
      text:
        "<p>Часто клиенты, которые обращаются за разработкой IT-продуктов либо решений не имеют даже 'салфетки в кармане'. Про себя мы внутри компании называем термином 'салфетка' - документ, концептуально отвечающий на вопросы: что делаем, зачем и кому это нужно как результат, и, главное, какова монетизация или вся затея ради благотворительности? (Ау, меценаты, энтузиасты - много ли вас, кто разрабатывает просто так, бескорыстно)))</p>" +
        '<p>Ведь IT продукт или решение - это тоже бизнес, существующий в сети, который открывается, растет, развивается, реагирует на конкуренцию, не так ли?</p>' +
        "<p>Мы можем пойти в проект, начиная с таких 'салфеток' и далее сделать профессиональное техническое задание (ТЗ) за разумные деньги.</p>" +
        '<p>Мы сделали огромное количество ТЗ и как эксперты понимаем, в чем сила документов. Ведь это фундамент будущего решения.</p>' +
        '<p>Кто-то пишет без денег, кто-то за деньги. И вроде бы уже столько вебинаров, конференций, контента, где разъясняется,  что ТЗ - очень важная часть замысла.</p>',
      text2:
        '<h3>Ок.</h3>' +
        '<p>Производство (возьмем любой бизнес) может делать работу разного качества, в зависимости от бюджета заказчика. Так если бюджет небольшой - то используем дешевую рабочую силу, убираем клиентский сервис, получаем слабее рычаги управления всем этим процессом - ведь мы же экономим, так? </p>' +
        '<h3>Или не так? </h3>' +
        '<p>Цель как у Исполнителя, так и у Заказчика получить проект быстро и без переделок.</p>' +
        '<p>Как этого добиться? И вот здесь качество ТЗ играет первостепенную роль. Профессионально составленное ТЗ - залог того, что количество переделок будет минимальным и стороны по завершению проекта останутся довольны результатом и друг другом.</p>' +
        "<p>Отсутствие ТЗ или 'хилое' ТЗ риск, который может привести к конфликту, а еще печальнее, если время, силы и инвестиции будут потрачены зря, т.к. проект попадет в 'корзину'. К сожалению - такое бывает, поэтому опытные заказчики в первую очередь ищут грамотного аналитика и технического писателя, чтобы зафиксировать идею в документе.</p>",
      text3:
        '<h3>Что такое ТЗ? (Надо же разобрать тему, не так ли?)</h3>' +
        '<p>Есть ГОСТы, 19 и 34, которые задают стандарт написания технических заданий. Если делать все прям по ГОСТам, это будет толмут документации, которую большинство заказчиков не будут читать (да, да, ведь это еще нужно согласовать).</p>' +
        " <p>5-10 страничек текста, которые обычно присылает клиент под грифом секретно - тоже не тот объем для успеха. Если мы возьмем за образ строительство дома - то ТЗ на несколько страниц - это про стены, крышу, пол и окна. А сколько будет окон, из какого материала рама, в стенах или на крыше - не написано. Как в песне: 'Милый мой, хороший - догадайся сам'))) Полноценное ТЗ - это документ, содержащий исчерпывающую постановку задачи (т.е. любой подрядчик без уточнений все понимает и может приступать к реализации).</p>",
      quote:
        '<h3>Цитата</h3>' +
        '<p>Производство (возьмем любой бизнес) может делать работу разного качества, в зависимости от бюджета заказчика. Так если бюджет небольшой - то используем дешевую рабочую силу, убираем клиентский сервис, получаем слабее рычаги управления всем этим процессом - ведь мы же экономим, так? </p>',
      textImage:
        '<h3>И...?</h3>' +
        '<p>Некоторые клиенты все же убеждены, что и без ТЗ можно сделать хорошее решение. Вы не один из них? Так бывает, когда впервые идешь в разработку под заказ и когда кажется, что всё просто – тяп-ляп и готово. Или всё-таки есть процессы, которые не так просты, а?</p>' +
        '<p>Помнится мне один миллиардер (если он читает...надеюсь, что опыт ошибок научил его не делать так впредь, а если сделает - лично приду и дам ремня!!! Простите, погорячилась)</p>' +
        "Он решил, что наймет команду разработчиков и будет им говорить по сантиметру что и как, где, какого цвета и пр. Итог печальный. Проект не завершен. То, что сделали за год - не похоже на то, как он себе представлял в своем воображении. Тысячи рисунков на его уникальном цифровом флипе во всех оттенках - не смогли донести до программистов - как реализовать мечту. Почему? Да потому что есть такое понятие как 'язык', на котором понимают друг друга люди различных профессий. Чтобы избежать трагедии а-ля вавилонская башня - нужно уметь перевести то, что хочет заказчик на язык, понятный разработчикам." +
        '<p>Вот-с история, бывает. Бережешь вас, бережешь - но нужно же все испытать на себе, закалиться так сказать!!!</p>',
      description:
        "Часто клиенты, которые обращаются за разработкой IT-продуктов либо решений не имеют даже 'салфетки в кармане'. Про себя мы внутри компании называем термином 'салфетка' - документ, концептуально отвечающий на вопросы: что делаем, зачем и кому это нужно как результат, и, главное, какова монетизация или вся затея ради благотворительности? (Ау, меценаты, энтузиасты - много ли вас, кто разрабатывает просто так, бескорыстно)))",
      image: '/backgrounds/article-4.png',
      imageRight: '/backgrounds/article-4.1.png',
      preview: '/backgrounds/article-4.png',
      alias: 'zachem-razrabatyvat-tz',
      tags: [{ name: 'Статьи', isSelected: false }],
      published: '28/04/2022',
    },
    {
      title:
        'Сколько стоит разработать маркетплейс, и каких ошибок важно избегать',
      text:
        '<h3>Сколько стоит разработать маркетплейс, и каких ошибок важно избегать</h3>' +
        '<p>Весь ecommerce делится на интернет-магазины, сервисы (SaaS, PaaS, IaaS) и торговые площадки (маркетплейсы). Интернет-магазины предназначены для продажи вещей или услуг. Обычно принадлежат одному владельцу. Например: M.Видео или Юлмарт.</p>' +
        '<p>Сервисы предназначены для предоставления различных услуг, облегчают работу и экономят время пользователя. Пример такого сайта — проект LegalNature, где пользователи могут быстро создавать и нотариально заверять различные юридические документы.</p>' +
        '<p>Маркетплейсы — это торговые интернет-площадки, которые выступают посредником между покупателем и продавцом. Они могут делиться: по типу участников (B2B, B2C, C2C), по продуктам, по услугам, по монетизации. Но основное предназначение маркетплейса — упростить взаимодействие между поставщиками услуг/товаров и их потребителями, помочь найти друг друга и увеличить количество сделок. Для посетителя маркетплейс — это просто витрина с товаром, но на самом деле маркетплейс — площадка с продуманной бизнес-логикой и процессами.' +
        '<p> Лучшими маркетплейсами сегодня можно считать: Alibaba, Airbnb, eBay, Uber.</p></p>',
      textImage:
        '<h3>Основные ошибки при создании маркетплейса</h3>' +
        '<p>Маркетплейсами сегодня никого не удивишь, они продолжают набирать популярность. Они вытесняют интернет-магазины и централизуют вокруг себя торговлю в интернете, особенно это выгодно малому и среднему бизнесу. Ведь куда проще создать аккаунт на уже готовой площадке с большим количеством посетителей, и буквально с первых дней получать клиентов, чем самостоятельно создавать магазин и думать о его продвижении, платить разработчиками и поддерживать всю инфраструктуру.</p>',
      text2:
        '<p>Но каким бы прибыльным делом не казалось создание своего маркетплейса, с блекджеком и плюшками, тут нужно подумать трижды, так как это высокорискованный и требующий больших вложений бизнес.</p>' +
        '<p>За 14 лет мы сделали около десятка маркетплейсов: по перевозке грузов, по аренде жилья, по доставке еды, по обучению иностранным языкам. И каждый из них был интересен и обладал своей изюминкой, но, к сожалению, выжили из них единицы.</p>' +
        '<p>Мы создаём приложения и следим за их жизнью, мы видим, какие решения пользуются популярностью, а какие нет, что живёт, а что умирает. Весь этот опыт мы стараемся передать своим клиентам, но, к сожалению, не все прислушиваются.</p>' +
        '<p>Давайте рассмотрим наиболее часто встречающиеся ошибки построения маркетплейсов.</p>' +
        '<h4>Неправильно выбранные инструменты и команда</h4>' +
        '<p>Стоимость разработки маркетплейса сильно зависит от конкретного бизнеса: можно создать маркетплейс на базе «коробочных» решений или заказать индивидуальную (кастомную) разработку. Часто владельцы, желая сэкономить, на начальных этапах выбирают коробочные решения — это вполне логично если у вас недостаточно денег и времени, или вы хотите проверить какую-то гипотезу. Но у коробочных решений ограничена гибкость, и порой кастомизировать коробочную версию оказывается намного дольше и дороже, чем разработать похожую функциональность с нуля. Поэтому тут важно до начала работ провести аналитику и выбрать правильные инструменты. И не стоит выбирать человека-оркестра или команду, которая соглашается делать все ваши прихоти и желания беспрекословно. Скорее всего, они заинтересованы в том, чтобы выполнить больше задач и получить больше денег, а не заинтересованы в успехе вашего бизнеса. Профессионалы обязательно помогут, подскажут лучшее решение, поэтому прислушивайтесь к их мнению.</p>' +
        '<p>У нас был случай, когда пришёл к нам клиент у которого, по его словам, уже было сделано 80% всего приложения. Но предыдущий разработчик перестал устраивать клиента: тот делал всё очень медленно, был большой процент брака, приходилось возвращать задачи на доработки и исправления по несколько раз.</p>' +
        '<p>Мы провели аудит кода и выяснили, что предыдущий разработчик сильно усложнил архитектуру, применял овер-инженерные решения, а в силу ограниченного бюджета и сроков не писал автоматизированные тесты. В итоге исправление одних проблем порождало большое количество других, которые обнаружить было не так просто. По написанному коду прослеживался явный перекос умений в сторону бэкенд-разработки, поэтому решения на фронтэнде плохо соответствовали требованиям.</p>' +
        '<p>В результате нам пришлось полностью переписать некоторые части приложения.</p>' +
        '<h4>Придумывают фичи в ходе разработки</h4>' +
        '<p>Часто в процессе разработки клиенты начинают придумывать новые фичи, которые с трудом можно внедрить в уже созданную архитектуру. Хорошо, если на это есть время и деньги, но бывает, что клиент увлекается процессом и может потратить большую часть бюджета на вещи, которые не очень нужны для запуска. А потом ему может просто не хватить денег на маркетинг и привлечение клиентов, а ведь это тоже важная часть бизнеса. Чтобы избежать такой ситуации, нужно вовремя остановиться и заняться только приоритетными задачами. Неприоритетные задачи можно спокойно добавлять уже после запуска маркетплейса.</p>' +
        '<p>Несколько лет назад один из наших клиентов хотел создать самую лучшую, самую быструю, самую отказоустойчивую соцсеть. От наших советов начать с MVP, сделать версию как можно быстрей, привлечь людей и проверять гипотезы на пользователях — отказывался наотрез. Он хотел сразу выпустить идеальный продукт. Он нанял лишь команду программистов для разработки, а весь процесс взял в свои руки. Почти два года он делал с нашими ребятами приложение, мог по несколько месяцев делать какую-нибудь «фишку», пока она не станет идеальной. В итоге у него закончились деньги, он так и не выпустил продукт, а позже у него были проблемы с инвесторами.</p>' +
        '<h3>Сколько будет стоит разработка</h3>' +
        '<p>Стоимость создания маркетплейса может сильно варьироваться в зависимости от направления бизнеса и необходимой функциональности, поэтому назвать точную цифру разработки нельзя.</p>' +
        '<p>Помните рекламу про то, как женщина хотела купить колбасы грамм двести–триста, а продавец ей довольно грубо отвечал: «Сколько вешать в граммах?!» Так вот, при разработке сложных продуктов, таких как маркетплейс, нельзя сказать, сколько будет стоит финальный продукт.</p>' +
        '<h3>Но можно рассчитать примерный бюджет.</h3>' +
        '<p>Как я упоминал выше, разработка маркетплейса может быть коробочной или кастомной. На рынке существует большое количество всевозможных коробочных вариантов маркетплейсов. Разработка базового варианта может стоить до миллиона рублей, а срок выполнения — 1,5–2 месяца.</p>' +
        '<p>При кастомной или индивидуальной разработке стоимость создания торговой площадки обойдётся от 3 миллионов рублей, а срок разработки — от 3 месяцев.</p>' +
        '<p>В зависимости от бизнес-логики, дизайна и дополнительной функциональности стоимость разработки может меняться в разы.</p>' +
        '<p>Давайте разберёмся с трудозатратами, и какую минимальную функциональность необходимо будет реализовать. Ниже представлен основной список частей или модулей любого маркетплейса:</p>' +
        '<ul><li>модуль авторизации и регистрации,</li><li>модуль управления данными и ролями,</li><li>модуль администрирования,</li><li>модуль поиска,</li><li>модуль управления товарами или услугами,</li><li>модуль социального взаимодействия,</li><li>модуль нотификации,</li><li>модуль оплаты.</li></ul>' +
        '<h4>Модуль авторизации и регистрации</h4>' +
        '<p>Обычно имеет стандартную авторизацию через email и пароль, может быть расширен авторизацией через социальные сети или двухфакторной авторизацией через смс или другие источники. При регистрации обычно подтверждается почта с помощью проверочного кода на указанный email. Соответственно, есть страница, где проверяется высланный код. Страница и функциональность восстановления пароля. Разработка этого модуля занимает от 40 до 80 часов.</p>' +
        '<h4>Модуль управления данными и ролями</h4>' +
        '<p>Создание и редактирование личных данных и данных компании, валидация введённых данных, загрузка файлов и изображений, создание ролей и ограничение прав доступа. Разработка этого модуля занимает от 60 до 120 часов.</p>' +
        '<h4>Модуль администрирования</h4>' +
        '<p>Просмотр и управление всеми данными в системе, просмотр транзакций, разрешение споров, аналитика и статистика. Разработка этого модуля занимает от 150 до 300 часов.</p>' +
        '<h4>Модуль поиска</h4>' +
        '<p>Поиск по полям, фильтрация и сортировка данных, ограничения выдачи данных, оптимизация и ускорение. Разработка этого модуля занимает от 80 до 200 часов.</p>' +
        '<h4>Модуль управления товарами или услугами</h4>' +
        '<p>Добавление, редактирование и удаление товаров и услуг с различным набором полей. Категоризация данных. Управление состоянием товаров и услуг. Разработка этого модуля занимает от 80 до 200 часов.</p>' +
        '<h4>Модуль социального взаимодействия</h4>' +
        '<p>Создание чатов для общения между поставщиками и потребителями, возможность лайкать или дизлайкать, возможность оставлять отзывы и комментарии, создание рейтингов. Разработка этого модуля занимает от 150 до 300 часов.</p>' +
        '<h4>Модуль нотификации</h4>' +
        '<p>Создание как моментальных уведомлений, так и рассылки email или смс, создание push-уведомлений. Разработка этого модуля занимает от 80 до 160 часов.</p>' +
        '<h4>Модуль оплаты</h4>' +
        '<p>Привязка карт и интеграция с платёжными системами, просмотр и управление транзакциями, история платежей, отмена оплаты. Разработка этого модуля занимает от 80 до 200 часов.</p>' +
        '<p>Первым делом: аналитика и сильная команда</p>' +
        '<p>Если у вас есть классная идея, и вы решили создать маркетплейс, то первое, что нужно сделать и на что не стоит жалеть денег, — это провести аналитику и выбрать правильные инструменты, выбрать команду, заинтересованную в улучшении вашего бизнеса. Это поможет сэкономить приличную сумму, ведь не придётся переделывать проект по несколько раз. Цена разработки маркетплейса достаточно высока, но нужно знать, что минимум столько же, и, скорее всего, даже больше, вам нужно будет потратить на маркетинг, рекламу и привлечение клиентов. Какую бы классную и удобную торговую площадку вы не сделали, без клиентов она не имеет смысла!</p>' +
        '<p>Если вам нужна профессиональная консультация -  напишите нам</p>',
      description:
        'Весь ecommerce делится на интернет-магазины, сервисы (SaaS, PaaS, IaaS) и торговые площадки (маркетплейсы). Интернет-магазины предназначены для продажи вещей или услуг. Обычно принадлежат одному владельцу. Например: M.Видео или Юлмарт.',
      image: '/backgrounds/article-3.png',
      imageRight: '/backgrounds/article-3.1.png',
      preview: '/backgrounds/article-3.png',
      alias: 'skolko-stoit-razrabotat-marketplace',
      tags: [{ name: 'Статьи', isSelected: false }],
      published: '28/04/2022',
    },
    {
      title: 'Как НRM-система помогает бизнесу',
      text:
        '<p>Вопрос, который волнует всех СЕО, в чьих интересах не только развить бизнес на новых рынках, но и максимально укрепить свои позиции, повысить рейтинг своей деловой репутации – это КАДРЫ.</p>' +
        '<p>Я много раз наблюдала, как в Российских так и зарубежных компаниях, один и тот же сценарий, который приводил обычно к тому, что продажи падали - и это явление вполне естественное. Ведь при перенастройках требуется время, чтобы перейти на новую рабочую модель, но прежде чем появится такая модель - требуется кадровая аналитика. Как все происходит на практике у ряда компаний?</p>' +
        "<ol><li>СЕО что-то прочитал, или поучаствовал в тренинге роста и принял решение, что пора уже раскачать отдел продаж и маркетинг, т.к. финансовые показатели перестали расти, нужна 'свежая кровь'.</li><li>Далее СЕО оценивая качество действующего персонала со своей новой стратегией приходит к выводу, что нужны не просто новые люди, а профессионалы более высокого уровня и развитых компетенций.</li><li>У HR начинается квест, как не промахнуться с выбором кандидата.</li><li>Допустим правильные люди найдены, поставлены на ключевые позиции в структуре организации, СЕО горит надеждой, что сейчас картинка на дашборде начнет меняться но не все так просто...</li><li>Да, новые люди - профессионалы, готовы начать преобразование, у них даже есть план и практика, как все правильно сделать, но они сталкиваются с тем, что СЕО просит их сделать немного по-другому, чтобы не потерять то, что есть.</li></ol>" +
        '<p>Переживания эти владельца бизнеса понятны, только вот когда отступаешь от проверенной годами практики - получаешь обратный эффект. Время, силы на поиск, адаптацию кадров потрачены, а результат заставляет себя ждать.</p>' +
        '<p>Что же не так? Как минимизировать риски? Как и новых людей встроить и старых не распугать, удержать? А вот еще бывает так, что HR в компании еще не стал кузницей кадров и работает как может, а порой эту роль вынужден выполнять и сам CEO... Точно могу сказать, что еще не было истории, в которой бы не случилось потерь. Бизнес – это всегда игра команд, а внутри команд всегда есть свои лидеры, к которым прислушиваются остальные сослуживцы. И задача нового человека, который нанят для качественных и количественных изменений – вычислить таких сотрудников, заслужить их доверие и объединить лучших для реализации стратегии. С теми же, кто не лоялен, агрессивен, конфликтен – без промедления расстаться. С первых дней вычислить таких вредителей не просто, многие присматриваются к друг другу, стараются показать себя лучше, чем обычно. Чтобы новому руководителю понять, кто готов стать союзником, а кто саботирует коллектив - должно пройти время, если это небольшой коллектив - все становится ясно в первые две недели, в холдинговых структурах - можно прояснить картину в течение 2 месяцев.</p>' +
        '<p>Как вопрос с кадрами решался раньше (а где-то еще и сейчас существует такая практика)? Кадровые эксперты в зависимости от системы менеджмента, управленческого стиля, корпоративной культуры использовали инструменты оценки и аудита, такие как интервьюирование, анкетирование, тесты Белбина, соционические и пр.тесты, чтобы выдать в результате СЕО отчет - кто перспективен, а кто попадает под раздачу билетов на открытый рынок труда. Ведь прежде, чем стартовать перемены - нужно разобраться кто их готов принять, иначе движение вперед будет буксовать.</p>' +
        '<p>К счастью сейчас уже есть технологии, позволяющие сделать работу быстрее и получить отчетные рекомендации для более решительных действий. Для управления персоналом и карьерного развития есть как готовые IT-продукты по тарифной подписке, так и возможности для создания HRM-системы под заказ. Английская аббревиатура «HRM» обозначает «Human Resource Management», что дословно переводится как «управление человеческим ресурсом»</p>' +
        '<p>Инновациями в HRM стало расширение функционала, за счет внедрения голосовых и текстовых ботов для первичной обработки резюме, тестирования соискателей, а также действующих сотрудников. Возможно Вы слышали про  ботов-рекрутеров, которые могут проводить интервью с 1000 кандидатами за 3 часа. Это задача, на которую у HR-службы ушло бы 7-10 недель. Автоматизированные системы с эффективно работающими бизнес-процессами освобождают HR от рутины, скучной работы, и они могут уделять больше времени работе с базами, оценке и развитию карьерной карты сотрудников, разрабатывать программы обучения и проводить больше тренингов для персонала, сосредоточиться на стратегии через повышение качества специалистов. Продвинутая HRM система позволяет работать с целом рядом важных показателей.</p>' +
        '<p>Основная задача HRM — привлекать и удерживать самых ценных для компании людей, помогать лидерам сориентироваться в текущей ситуации и подобрать на должности наиболее подходящих сотрудников из кадрового резерва, или получив набор необходимых профессиональных качеств разработать соответствующую задачам компании вакансию.</p>',
      textImage:
        '<h3>Кто мы:</h3>' +
        '<p>Компания “Текмэн” была создана в 2007 году и задумана для решения задач в области разработки ПАК. В течении 15 лет мы выращивали ключевые компетенции по решению нестандартных задач, часто повышенной сложности, и работе с удаленными подрядчиками. Среди клиентов – Альфа Банк, Газпром, Армо, Протек, Росатом, Легранд, НТВ, Спар и стартапы - Pay-me, Hamstersoft,Radiometer</p>' +
        '<p> Как правило, наши клиенты - те, кто уже сделал одну-две попытки разработать свой продукт или получили проблемы с настройкой готовых коробочных решений. К нам обращаются потому, что не могут рисковать репутацией, терпеть простои, терять деньги или их задача слишком сложна, чтобы поручать её “средним” специалистам.</p>' +
        "Подробнее о компании и нашем портфолио <a href='https://tecman.ru/'>www.tecman.ru</a> ",
      text2:
        '<h3>Что дальше?</h3>' +
        '<p>Если то, что написано выше - про вас и вашу ситуацию, и есть принципиальный интерес обсудить условия сотрудничества свяжитесь с нашими менеджерами удобным для вас способом:</p>' +
        "<p>по телефону <a href='tel:84957984808'> +7 495 798 48 08</a></p>" +
        "<p>по е-mail<a href='mailto:tm@tecman.ru'> tm@tecman.ru</a></p>" +
        '<p>Пожалуйста, если Вы пишите нам по е-mail – укажите свой телефон – мы стараемся экономить время, и поэтому общаемся голосом.</p>',
      description:
        'Вопрос, который волнует всех СЕО, в чьих интересах не только развить бизнес на новых рынках, но и максимально укрепить свои позиции, повысить рейтинг своей деловой репутации – это КАДРЫ.',
      image: '/backgrounds/article-2.jpg',
      imageRight: '/backgrounds/article-2.1.png',
      preview: '/backgrounds/article-2.jpg',
      alias: 'kak-nrm-sistema-pomogaet-biznesu',
      tags: [{ name: 'Статьи', isSelected: false }],
      published: '28/04/2022',
    },
    {
      title: 'Адекватная оценка разработки мобильных приложений',
      text:
        '<p>Тема, которую хочется разобрать вдоль и поперек сегодня - МОБИЛЬНОЕ ПРИЛОЖЕНИЕ.</p> ' +
        " <p>Как понять адекватность оценки на этапе разработки? С чего начать тем, у кого нет опыта разработки мобильных приложений, особенно если Вы не имеете отношение к программированию, а занимаетесь приоритетно развитием своего бизнеса. Изменились ли цены в 2022 году? От каких компонентов и факторов зависит ценообразование. На чем можно сэкономить, но нужно ли? Какие риски необходимо предусмотреть 'на берегу' до того, как начать сотрудничество. Давайте разбираться.</p>" +
        '<h3>Как мы делаем оценку в Текмэн?</h3>' +
        '<p>Стандартная ситуация - запрос на разработку приложения по аналогу. ВНИМАНИЕ - подробного технического задания нет, макетов уникальных экранов нет, НО - есть идея, есть бизнес-процесс со слов заказчика и большое желание реализовать проект.</p>' +
        '<p>Если Вы хотите получить смету по проекту под  Ios/Android - пишите нам на е-mail  или звоните - и мы уделим Вам время, чтобы сориентировать по вилке цен или проделать работу по составлению сметы.</p>' +
        '<p>На рейтинговых сайтах большой выбор проверенных разработчиков, кто возьмется за разработку от 1, 5 млн до 10 млн рублей. Если мобильное приложение стоит меньше млн - то вряд ли это разработка под заказ, скорее всего речь идет о мобильном сайте - адаптации или сборка на конструкторе мобильных приложений, часть из них вообще бесплатные, платформ много. И они больше нужны начинающим программистам. Самостоятельно собрать приложение и даже с нанятым фрилансером не всегда простая задача, более того - она сложная, так как результат может быть не таким, как требует бизнес.Поэтому наши менеджеры на этапе пресейла выясняют - что у вас за бизнес и какую задачу вы хотите решить за счет технологий, ведь не всегда нужно делать именно мобильное приложение, может достаточно адаптировать сайт?</p>' +
        "<p>Если же мы слышим 'боль' бизнеса и задача поставлена серьезная, что конструктор с ней не справится и нужна именно разработка, и бюджет на проект такого уровня обсуждался, т.е. клиент уже изучил книги или статьи по теме, и понял, что удовольствие - заказать ПО, стать владельцем собственного онлайн-продукта - мы предлагаем бесплатную консультацию с проджектом и предварительную смету проекта.</p>",
      text2:
        '<p>У нас есть три категории оценки - бюджетный вариант от 160 до 300 часов, средний - от 350 до 700 часов, и сложный от 800 часов</p>' +
        '<p>Какие проекты мы считаем простыми и бюджетными:<br/> Мобильные приложения с шаблонным дизайном, с простым функционалом и понятной бизнес-логикой, около 5-8 уникальных экранов, можно будет добавлять модерацию контента, но относительно юзабилити - будут некоторые ограничения, которые мы заранее проговариваем с заказчиком. Сюда относятся разработки чат-боты, системы бронирования услуг, калькуляторы, или промо-визитка компании - такую разработку можно сделать быстро - 1 месяц пишем техническое задание, и еще 2 месяца на разработку</p>' +
        '<p>Какие проекты мы считаем средней сложности: <br/> Мобильные интернет-магазины, которые охватывают несколько процессов, интеграции с е-mail, СRM, IP-телефонией, системой учета и доставки, онлайн-кассой и т.п. Так же к этому уровню относятся сервисы, такие как услуги или заказ еды на дом. По количеству уникальных экранов - их может быть около 18-25, 1,5-2 месяца пишем техническое задание, и еще 5-7 месяцев на разработку</p>' +
        '<p>Какие проекты мы считаем сложными и масштабными: <br/>Это так называемая кастомная разработка с большим функционалом, полностью настраиваемый пользовательский интерфейс, графический дизайн, мультиязычность, куча интеграций с различными базами данных, внедрение AI и систем повышенной безопасности, экранов более 30 и больше. Что это может быть: маркетплейсы, электронно торговые площадки, социальные сети, IoT - приложения, и, конечно же - игры,</p>' +
        '<p>1,5-2 месяца пишем техническое задание, от 6 месяцев на разработку. <br/> Совершенствовать проекты можно в течение нескольких лет, если Ваш бизнес это потребует - поэтому важно заранее продумать архитектуру и нагрузку, которую должно выдерживать Ваше мобильное приложения</p>',
      textImage:
        '<p>Цифры в смете как правило имеют содержание, а именно:</p>' +
        '<ol><li>количество часов, которые потребуются для реализации;</li><li>технологии и язык - предпочтения заказчика Flutter, Swift, Java, Java Script, Kotlin, PHP и др.;</li><li>релевантный опыт разработчиков;</li><li>оценка сложности - часть или под ключ;</li><li>какие компоненты разработки будут на стороне заказчика.</li></ol>' +
        '<p></p>' +
        '<p>Поэтому есть средняя ставка специалиста за час, для своих клиентов - есть система скидок</p>' +
        '<ul><li>за скорость подписания договора со дня обращения</li><li>за количество проектов от компании</li><li>за долгосрочность сотрудничества</li><li>за рекомендации и пр.</li></ul>',
      description:
        "Как понять адекватность оценки на этапе разработки? С чего начать тем, у кого нет опыта разработки мобильных приложений, особенно если Вы не имеете отношение к программированию, а занимаетесь приоритетно развитием своего бизнеса. Изменились ли цены в 2022 году? От каких компонентов и факторов зависит ценообразование. На чем можно сэкономить, но нужно ли? Какие риски необходимо предусмотреть 'на берегу' до того, как начать сотрудничество. Давайте разбираться.",
      image: '/backgrounds/article-1.png',
      imageRight: '/backgrounds/article-1.1.png',
      preview: '/backgrounds/article-1.png',
      alias: 'adekvatnaya-otsenka-razrabotki-mobilnyh-prilozhenij',
      tags: [{ name: 'Статьи', isSelected: false }],
      published: '28/04/2022',
    },
    {
      title: 'Клиент: —  стоит копия FACEBOOK?',
      text:
        '<p>«Сколько стоит сделать копию Facebook (Avito, Яндекс.Такси, fl.ru...)?» — один из самых популярных вопросов клиентов, на который мы сегодня дадим развернутый ответ и расскажем, как это выглядит со стороны людей, которым предстоит это делать.</p>' +
        '<h2>«Черный ящик»</h2>' +
        '<p>Когда нам дают задачу скопировать какой-то сервис, то для нас он представляет собой некий «черный ящик». Абсолютно неважно, что это за программа: сайт, мобильное приложение или драйвер. В любом случае, мы сможем увидеть, как он выглядит снаружи, но у нас нет доступа к тому, что находится внутри.</p>' +
        '<p>Это примерно, как если бы нам показали автомобиль и попросили сделать точную копию, но не дали возможности заглянуть под капот: мы можем ограничиться лишь внешним осмотром и посидеть за рулем. А залезть в багажник — уже нельзя!</p>' +
        '<p>Соответственно, мы будем вынуждены решать следующие задачи:<br>' +
        'Предположить и придумать — а как же внутри устроен этот «автомобиль», у которого мы видим только кузов?</p>' +
        '<p>Представить, из каких деталей он состоит. Для понимания: любой современный автомобиль — это примерно 18 000 деталей…</p>' +
        '<p>Прикинуть, какие специалисты нужны для создания этих 18 000 деталей и сколько времени займет создание каждой.</p>' +
        '<p>В разработке программного обеспечения — похожий процесс: систему, которую мы создаем, нужно разбить на кучу мелких составляющих. Придумать, как и кем их создать, и как они будут взаимодействовать друг с другом. Именно поэтому «просто скопировать» — непростая и объемная задача.</p>' +
        '<h2>«Верхушка айсберга»</h2>' +
        '<p>Avito, Facebook, Яндекс.Такси… Если бы клиент знал изнутри бизнес, на который ссылается, то он бы обнаружил, что в нем трудятся десятки, а то и сотни программистов, которые создавали сервис в течение нескольких лет.</p>' +
        '<p>Оплачены тысячи часов специалистов, которые ушли на производство продукта.</p>' +
        '<p>Считая, “сколько стоит скопировать Facebook” мы увидим все результаты их труда. И, когда мы сделаем список этих результатов, клиент всегда обнаруживает, что видел, от силы, 10% “Facebook”.</p>' +
        '<p>Остальные 90% ему становятся видны лишь после того, как мы проделали довольно большую работу. Вы ведь не видите двигатель, рулевые рейки, топливопровод, когда садитесь за руль автомобиля?</p>' +
        '<p>Что будет потом?</p>' +
        '<p>Клиент понимает, что 90% возможностей сервиса ему вообще не нужны. Это трудозатраты, которые не дадут ему никакой пользы. Тысячи человеко-часов, потраченные на возможности, которые ему никогда не пригодятся. Дорогие и бесполезные.</p>' +
        '<h2>«Скопируйте дочку соседа, да подешевле!»</h2>' +
        '<p>Почему вообще клиент приходит с таким запросом? Ему кажется, что раз эта работа уже проделана, то нет ничего проще, чем взять и скопировать. Экономит кучу денег!</p>' +
        '<p>Но есть небольшая проблема — мы ничего не можем взять из Facebook, потому что:</p>' +
        '<ol>' +
        '<li>У нас (и ни у одного другого подрядчика) нет доступа к исходному коду. Да даже если бы и был — он собственность другой компании.</li>' +
        '<li>У нас нет исходников дизайна, то есть дизайн тоже нужно будет пересоздавать.</li>' +
        '<li>У нас нет знаний об архитектуре продукта. Мы можем только предположить, как он устроен внутри. Даже если прочтем кучу статей на хабре — там будет лишь приблизительное описание.</li>' +
        '</ol>' +
        '<p>Увы, запрос “сделайте как у соседа” — не делает работу дешевле :(</p>' +
        '<h2>«Дайте мне кочергу!»</h2>' +
        '<p>Программный продукт — не самоцель: с его помощью клиент хочет решить свою бизнес-задачу. Например, заработать или сэкономить денег, захватить аудиторию, создать удобный инструмент для сотрудников.</p>' +
        '<p>Только вот парадокс: клиент не приходит к нам с вопросом о бизнес-задаче. Он приходит с вопросом о техническом решении. То есть, с запросом типа «мне нужна кочерга». А для чего она ему? Может, он собирается дрова колоть, а там топор нужен?</p>' +
        '<p>Клиент не является специалистом по решениям (обычно он решает такую задачу первый раз в жизни), но, увидев кочергу, ему кажется, что ВОТ ОНА, волшебная палочка!</p>' +
        '<p>Но, когда мы зададим вопрос «а какую бизнес-задачу вы решаете?» и подумаем, какое решение было бы действительно оптимальным, окажется, что оно не имеет вообще никакого отношения ни к Facebook, ни к кочерге. Ну, вот вообще ничего общего.</p>' +
        '<h2>Резюме</h2>' +
        '<p>По всему выходит, что запрос «сколько стоит копия…?» — бессмысленный. Для того, чтобы ответить на него буквально, нужно проделать колоссальную работу, которая никак не пригодится ни нам, ни клиенту. Почему так уверены? Да мы делали эту работу, много раз =)</p>' +
        '<p>Что же делать? Имеем мнение — писать техническое задание.</p>' +
        '<p>Любой нормальный читатель на этом месте подумал “вы говорите это потому, что хотите нам продать!!!”</p>' +
        '<p>И да, и нет. Попробуйте найти хорошего строителя, который начнет строительство дома без проектно-сметной документации. Или автомеханика, создающего автомобиль без чертежей. Или опытного предпринимателя, создающего новый бизнес без финмодели.</p>' +
        '<p>Даже если мы делаем программу для себя — мы начнем с техзадания. Нам так же, как и вам, не хочется тратить на это “лишние” деньги. Но мы знаем, что без этого не обойтись. Иначе небоскреб — завалится, бизнес будет больше забирать, чем приносить, а с автомобилем — неизвестно, кто на ком будет ездить.</p>' +
        '<p>У данной статьи лишь одна цель: избежать бесполезной работы, и делать полезную работу для вас. Поговорим, зачем вам “кочерга”?</p>',
      image: '/backgrounds/creditCard.png',
      alias: 'klient-ckolko-stoit-kopija-facebook',
      preview: '/backgrounds/creditCard.png',
      description:
        '«Сколько стоит сделать копию Facebook (Avito, Яндекс.Такси, fl.ru...)?» — один из самых популярных вопросов клиентов, на который мы сегодня дадим развернутый ответ и расскажем, как это выглядит со стороны людей, которым предстоит это делать.',
      tags: [{ name: 'Частые вопросы', isSelected: false }],
      published: '1/02/2021',
    },
    {
      title: 'AMO, БИТРИКС, 1C и другие: как выбрать и с чего начать?',
      text:
        '<p>Вы ― собственник компании. Вы поняли, что пора посмотреть на свой бизнес в цифрах. Найти узкие места, утечки денег и информации, все упорядочить и посчитать.</p>' +
        '<p>Или же вы ― миддл-менеджер компании, и наконец-то донесли до Биг Босса, что учет и автоматизация бизнеса ― не только круто, а жизненно необходимо.</p>' +
        '<p>И вот вы идете в IT-компанию, чтобы специалисты помогли вам внедрить учетную систему. Идете и боитесь: «А вдруг мне зарядят сейчас 100500 мильонов денег, а я даже не буду понимать ― за что? А вдруг меня разведут, как на техосмотре, впарят вместо плановой замены масла и тормозной жидкости новые колодки и перебор двигателя?»</p>' +
        '<p>Специально чтобы собственники и миддл-менеджеры понимали, чего ждать, мы начнем с азов — расскажем, из чего исходят IT-компании, когда предлагают разные учетные решения.</p>' +
        '<h2>Три уровня задач для учетных систем</h2>' +
        '<p>Основные задачи, которые решают системы учета для бизнеса, делятся на три уровня. Каждый последующий уровень более продвинут по отношению к предыдущему.</p>' +
        '<p><strong>Уровень 1</strong>. Собрать все данные в одну большую кучу, создав единое информационное пространство. Чаще всего эта задача решается без учета удобства для пользователей. Сотрудники компаний плюются, ругаются матом, и кое-как осваивают новые функции ― хотя и считают их ненужными и излишними. Но все-таки осваивают, и единое информационное пространство начинает наполняться смыслом и цифрами.</p>' +
        '<p><strong>Уровень 2.</strong> Задача второго уровня ― сделать учетную систему «с человеческим лицом», дружественным интерфейсом и понятным функционалом. С такой системой должно быть легко работать каждому пользователю. Эффект от решения задач второго уровня может быть разным ― от уменьшения трудозатрат и количества персонала, до начала работы с задачами, к которым в принципе невозможно было подступиться до автоматизации.</p>' +
        '<p><strong>Уровень 3.</strong> На этом уровне начинают решаться задачи собственника бизнеса — повышение прозрачности и управляемости бизнеса. Системы учета помогают формировать срезы данных, на основе которых собственник или руководитель может принимать конкретные управленческие решения. Для этого в цифровом выражении должны быть представлены затраты и польза от множества конкретных операций. Это поможет увидеть утечки, неэффективное расходование средств или воровство, либо наоборот — выявить наиболее эффективные направления, и усилить их.</p>' +
        '<p>Специалисты, работающие с учетными системами, ориентируются на ваши задачи. Если вам нужно просто единое информационное пространство, ответственный профи не будет впаривать крутые инструменты, позволяющие принимать управленческие решения. Потому, что это дольше, дороже, сложнее, и скорее всего вы не сможете этим пользоваться — это требует квалификации. Если вы хотите получить удобный инструмент, например, для «осуществления быстрых продаж», а управленческие решения ― не ваша епархия, специалисты это тоже учтут.</p>' +
        '<p><strong>Промежуточный итог:</strong> система учета в бизнесе решает три основные задачи.</p>' +
        '<ol>' +
        '<li>Создание единого информационного пространства.</li>' +
        '<li>Выявление узких мест, точек роста.</li>' +
        '<li>Помощь в принятии управленческих решений.</li>' +
        '</ol>' +
        '<p><br>' +
        '<strong>Совет первый:</strong> посмотрите вокруг, трезво содрогнитесь (с) и определите те задачи, которые должна решить система учета. Как изменится ваша компания в результате того, что будет внедрена система? И если вы — не опытный пользователь учетных систем, возможно, вам стоит такого найти и спросить — «А что вообще может дать система? Покажи свою?»</p>' +
        '<h2>Три товарища</h2>' +
        '<p>Представим, что живут в одном городе три товарища ― Митя, Геннадий и Олег.</p>' +
        '<p>Митя замутил бизнес по продаже надувных шариков и оформлению праздников и мероприятий. Геннадий открыл службу такси, а Олег начал выдавать страждущим микрозаймы.</p>' +
        '<p>Митя ведет учет по-простому, в Google Docs и частично в Excel, а кое-что вообще считает на пальцах (профи иногда называют это системой учета из дерьма и палок). Когда (и если) объемы его продаж возрастут, такое ведение учета может стать проблематичным.</p>' +
        '<p>Геннадий выделил те зоны бизнеса, где важнее всего автоматизация, и купил коробочное решение, специально разработанное для сферы такси. В нем ограниченный набор модулей ― рабочее место водителя, рабочее место диспетчера и мобильное приложение для пользователей. Для налогового и кадрового учета Геннадий использует традиционные продукты 1С. Такой набор хорошо подходит именно для такси, но если Геннадий займется грузоперевозками, то эту сову на глобус ему уже не натянуть ― придется искать другие инструменты.</p>' +
        '<p>Работа хваткого Олега требует жесточайшего учета и контроля. Если он будет плохо управлять финансовыми потоками ― его бизнес скончается так же громко и быстро, как «Древпром», «Слон-финанс» и «Домашние деньги». Олег встраивал в свой бизнес несколько готовых систем учета, но быстро перерос коробочные решения, и задумался о чем-то более тонко настроенном, менее типовом ― о системе, на 100% соответствующей именно его специфике.</p>' +
        '<p>Ответа на вопрос «Вот в этих трех бизнесах — какое из решений правильное, идеальное?» у меня нет. Все варианты имеют право на существование, все они работают и помогают решать бизнес-задачи их владельцев. Разница между ними лишь в стоимости и количестве комфорта для пользователей. Поэтому когда три соседа-бизнесмена придут к IT-разработчикам за инструментами для ведения учета, им предложат разные варианты решений ― и это будет правильно.</p>' +
        '<p><strong>Совет второй:</strong> Подготовьте для IT-спецов информацию о сфере деятельности компании; о том, кто будет пользоваться системой и как; о том, как вы ведете учет сейчас; о том, что не нравится сейчас, и что захотите от системы в будущем.</p>' +
        "<p><a href='https://docs.google.com/document/d/1FPzjISVajiv_wMr1i7eHRXYq1YxqrDcgQp7kvo3Ukj0/edit'>Вот бриф</a>, который я отправляю заказчикам. С одной стороны, он облегчит поиск общего языка с IT-специалистами, которые будут разрабатывать продукт, с другой — поможет вам лучше понимать назначение будущей системы.</p>" +
        '<h2>Деньги и ограничения «сверху» и «снизу»</h2>' +
        '<p>Два ключевых параметра, которые скажутся на выборе учетных систем для компании ― бюджет и компетенции лица, принимающего решение.</p>' +
        '<p>Сегодня на рынке найдется куча инструментов, которые помогут наладить учет, от условно-бесплатных, до стоящих несколько миллионов рублей в месяц. Чем больше ваш бюджет на разработку/внедрение учетной системы для бизнеса, тем большее количество решений вам доступно. Но и бюджет, и количество подходящих вариантов зависят не только от наличия у вашей компании денег ― еще и от вашего понимания задач, которые должна решать ваша система.</p>' +
        '<p>Если отчасти наивному в бизнесе Мите предложить разработку навороченной, крутой, суперудобной и обсчитывающей вообще все бизнес-процессы учетной системы, он не согласится. Во-первых, у него нет на это денег. А во-вторых, если деньги у него есть, то все равно ему это не нужно ― система «из дерьма и палок» на его уровне вполне работает! И ему предложат ― что? ― да просто доработку и улучшение этой работающей системы. Это можно назвать ограничением «сверху»: есть решения, которые для Мити слишком хороши, явно too much.</p>' +
        '<p>Если Геннадий не собирается, помимо такси, запускать каршеринг или грузоперевозки — ему вряд ли понадобится разработка индивидуальной системы учета. Ему нужна помощь в подборе более удобных, современных и информативных коробочных решений, созданных специально для его сферы бизнеса. И, конечно, в их внедрении и интеграции между собой. Именно это хорошие специалисты ему и предложат.</p>' +
        '<p>Но когда к IT-разработчикам придет Олег, ситуация будет принципиально другой. Олег бизнесмен прошаренный, он отлично понимает, что учет и контроль ― добрая половина успеха его микрозаймового дела. Без отслеживания и оценки эффективности всех бизнес-процессов он работу своей компании не представляет. Ему нужна разработка системы учета с нуля и конкретно под его нужды. Поэтому часть решений, которые ему точно по карману, Олег отметет, как недостаточно гибкие и информативные ― и это ограничение количества вариантов учетных решений «снизу».</p>' +
        '<p><strong>Совет третий</strong>: Определитесь с тем, какую сумму, исходя из финансовой ситуации и своего понимания бизнес-задач, вы готовы потратить на инструменты учета. Часто именно с обсуждения бюджета начинают общение с вами опытные IT-специалисты (они знают десятки решений вашей задачи, и выбирают из тех, которые вам по карману).</p>' +
        '<h2>Зачем так заморачиваться? Excel — самое крутое решение!</h2>' +
        '<p>Имею мнение:<strong> автоматизация и учетные системы в бизнесе ― не просто мода</strong>. Это необходимость и возможность для бизнеса выйти на новый уровень.</p>' +
        '<p>Приведу пример: я знаком с собственником компании X, которая занималась интернет-торговлей детскими товарами. Всего за 4 года работы эта компания стала лидером рынка. Как им это удалось? Они изначально сделали очень амбициозное заявление: «Доставим ваш заказ в любую точку России за 24 часа». За сутки в любую точку огромной страны ― это очень круто! И требует большой точности и эффективности каждого бизнес-процесса. Отладив, стандартизировав и автоматизировав в своей работе все, что можно, они справились ― и, как следствие, вышли в топ-3 своей отрасли.</p>' +
        '<p>Есть и сотни других интернет-магазинов детских товаров ― которые вполне довольны своей долей рынка и своей прибылью: они вкладывают в учет и контроль бизнес-процессов минимум, получая такое же минимальное органическое развитие.</p>' +
        '<p>Могут ли «другие» функционировать и приносить прибыль без навороченной системы, разработанной компанией X? Конечно да! Но могут ли стать топовой, пользуясь Excel? Полагаю, вы сами знаете ответ.</p>' +
        '<h2>Подведем итоги</h2>' +
        '<p><strong>Чтобы понимать, что и почему предлагают вам IT- специалисты,</strong> воспользуйтесь нашими тремя советами. Определитесь со своими задачами, подумайте о том, куда и как вы будете расти, и решите, какие суммы вы готовы вкладывать в развитие ваших личных систем учета.</p>' +
        '<h2>С чего начать?</h2>' +
        '<p>У опытных бизнес-аналитиков есть поговорка: автоматизация хаоса порождает автоматизированный хаос. Если вы задаетесь вопросом «с чего начать» ― возможно, эта поговорка про вас. В таких случаях первым этапом часто становится постановка управленческого учета. Это больше про процедуры и процессы, нежели про программирование. Делает эту работу бизнес-аналитик либо финдиректор, она помогает собственнику понять, какие задачи в принципе могут решаться системой учета. И даже увидеть ее возможности работы до начала программирования и вложений в приобретение системы. Попробуйте ― и, возможно, следующим в топ-3 мы увидим вас. Удачи!</p>' +
        "<div id='gtx-trans' style='position: absolute; left: -26px; top: 2128.72px;'>" +
        "<div class='gtx-trans-icon'>&nbsp;</div>",
      image: '/backgrounds/creditCard.png',
      alias: 'amo-bitriks-1c-i-drugie-kak-vybrat-s-chego-nachat',
      preview: '/backgrounds/articleB.png',
      description:
        '<p>Вы ― собственник компании. Вы поняли, что пора посмотреть на свой бизнес в цифрах. Найти узкие места, утечки денег и информации, все упорядочить и посчитать.</p>' +
        '<p>Или же вы ― миддл-менеджер компании, и наконец-то донесли до Биг Босса, что учет и автоматизация бизнеса ― не только круто, а жизненно необходимо.</p>',
      tags: [
        { name: 'Частые вопросы', isSelected: false },
        { name: 'Разработка CRM-систем', isSelected: false },
        { name: 'Автоматизация', isSelected: false },
      ],
      published: '01/02/2021',
    },
    {
      title: 'Как с EXCEL пересесть на CRM?',
      text:
        '<p>Практически любой бизнес начинается с Excel. В нем ведется учет клиентов, финансов и всего, на что хватит фантазии. По мере роста бизнеса данных становится больше, и наступает полная неразбериха: информация путается, теряется, используется неэффективно. Из этого следует, что Excel в условиях современных реалий стоит применять как вспомогательный инструмент в работе, но не как полноценную систему учета для бизнеса. В такой ситуации стоит задуматься о переходе на CRM. Ниже мы расскажем, какие проблемы могут возникать, когда в компании отсутствует эффективная система учета и о том, как осуществить переход к CRM.</p> ' +
        '<h2>CRM — оперативность взаимодействия с клиентами</h2>' +
        '<p>Случается, что менеджеры забывают сделать повторный звонок клиенту, проинформировать о новой услуге или напомнить постоянному покупателю о давно забытой бонусной карте. Стикеры, приклеенные на монитор, не спасут. Отсутствие оперативного взаимодействия с клиентами неминуемо приведет к ухудшению взаимоотношений с ними, что равняется потере прибыли для компании. Решением этой проблемы становится внедрение CRM-системы. Она возьмет на себя работу по отправке уведомлений сотруднику, предоставит исчерпывающую информацию о клиентах, чтобы ни один из них не остался в стороне. Определиться с оптимальным вариантом системы учета для вашего бизнеса поможет консультация бизнес-аналитика.</p>' +
        '<h2>Единая база данных</h2>' +
        '<p>Большой проблемой является разрозненность информации и отсутствие единого поля для взаимодействия между сотрудниками. Это влечет за собой искажение данных, их потерю и даже кражу. CRM-система представляет из себя единую базу данных: сотрудники используют в работе актуальную и однозначную для всех информацию, а строгое разграничение прав доступа обеспечивает ее безопасность. CRM повышает прозрачность работы сотрудников, позволяет контролировать ситуацию на каждом этапе взаимодействия с клиентом, дает возможность быстрого реагирования на возникающие проблемы для устранения последних или предотвращения развития негативных последствий для бизнеса.</p>' +
        '<h2>Комплексный инструмент для автоматизации</h2>' +
        '<p>“Зоопарк ПО” - возможно, вы слышали это выражение, которое означает, что в компании используется большое количество разного программного обеспечения под определенные задачи. Это приводит к увеличению издержек в компании: ПО поглощает ресурсы и стоит немалых денег. CRM помогает решить эту проблему и являет собой комплексный инструмент для автоматизации учета. Консультация бизнес-аналитика поможет сформулировать требования к будущей CRM на предмет соответствия вашим бизнес-процессам.</p>' +
        '<h2>CRM для аналитики</h2>' +
        '<p>Сбор аналитических данных о работе компании - важная составляющая для руководителя. Статистика позволяет оценить эффективность принятой стратегии развития бизнеса и спланировать дальнейшие шаги. В Excel можно столкнуться с трудностью: ценное время уходит на сбор, обработку и анализ информации. В условиях отсутствия единой информационной базы, результат может оказаться ошибочным и неактуальным. CRM-система возьмет на себя работу над рутинными задачами, существенно снизит влияние человеческого фактора и риск возникновения ошибки. С легкостью сформирует отчет в нужном разрезе и периоде времени на основе имеющихся данных. Таким образом, руководитель сможет в режиме онлайн увидеть и оценить текущее состояние дел в компании.</p> ' +
        '<h2>7 шагов к переходу на CRM</h2>' +
        '<h3>Вы уже сталкивались с проблемами, описанными выше? Тогда переходим на CRM.</h3>' +
        "<div class='step'>01.</div>" +
        '<h3>Начните с себя</h3>' +
        '<p>Определите, какие задачи хотите решить с помощью CRM. В качестве советчиков можно привлечь менеджеров, они хорошо знакомы с тонкостями работы. Составьте список, сделайте его подробным наглядным, например, в виде ментальной карты или таблицы; расставьте приоритеты.</p>' +
        '<h4>Примерами таких задач могут быть:</h4>' +
        '<ul>' +
        '<li>обеспечение прозрачности работы сотрудников;</li>' +
        '<li>повышение эффективности труда;</li>' +
        '<li>автоматизация рутинных операций;</li>' +
        '<li>минимизация влияния человеческого фактора;</li>' +
        '<li>создание единой базы данных;</li>' +
        '<li>интеграция с сервисами;</li>' +
        '<li>автоматическое создание отчетов;</li>' +
        '<li>планирование встреч, звонков;</li>' +
        '<li>контроль продаж на каждом этапе;</li>' +
        '<li>оптимизация затрат на маркетинг.</li>' +
        '<li>продуманный список в дальнейшем поможет вам определиться, какого типа CRM вам нужна для решения сформулированных задач.</li>' +
        '</ul>' +
        "<div class='step'>02.</div>" +
        '<h3>Определение требований к CRM</h3>' +
        '<p>В процессе подготовки к выбору CRM нужно учитывать, для решения какого типа задач она вам потребуется. C распространением CRM и развитием ее внутренних инструментов, стало возможным использовать систему под разные цели и задачи.</p>' +
        '<h4>Мы выделили 3 основных типа систем учета по их назначению:</h4>' +
        '<ul>' +
        '<li>операционная CRM — самая распространенная разновидность, которая призвана упростить, автоматизировать процесс взаимодействия с клиентом;</li>' +
        '<li>аналитическая CRM представляет собой инструмент для анализа накопленной информации и планирования стратегии развития;</li>' +
        '<li>коллаборационная CRM (англ. collaboration — сотрудничество) существует для совместной работы компании и клиента.</li>' +
        '</ul>' +
        "<div class='step'>03.</div>" +
        '<h3>Варианты решений</h3>' +
        '<p>По степени готовности к внедрению CRM системы бывают трёх типов:</p>' +
        '<ul>' +
        '<li>SaaS-сервисы (представляют собой программное обеспечение, которым сотрудник пользуется через web-браузер или мобильное приложение; провайдер предоставляет вам ПО в аренду);</li>' +
        '<li>коробочные решения (универсальная система со стандартным набором функций); </li>' +
        '<li>индивидуально разработанные CRM (решение, полностью разработанное под ваш бизнес).</li>' +
        '</ul>' +
        "<div class='step'>04.</div>" +
        '<h3>Пробный период</h3>' +
        '<p>Практически для всех готовых решений производители предоставляют бесплатный тестовый период в целях знакомства с системой. Конечно, такая версия далеко не всегда сможет показать весь спектр возможностей CRM, но представление составить можно. Не пренебрегайте этой возможностью: это отличный вариант опробовать решение и лучше понять, что вам нужно от работы с ней.</p>' +
        "<div class='step'>05.</div>" +
        '<h3>Выбор CRM</h3>' +
        '<h4>Резюмируем:</h4>' +
        '<ul>' +
        '<li>Saas-сервис может быть интересен малому бизнесу со стандартными бизнес-процессами, если в будущем не планируются доработки.</li>' +
        '<li>“Коробочный” вариант CRM может подойти малому и среднему бизнесу с наиболее стандартным набором бизнес-процессов.</li>' +
        '<li>собственная CRM-система подойдет среднему и крупному бизнесу, поскольку является масштабируемым, готовым к высоким нагрузкам, решением; подойдет компаниям со сложными, нетипичными, уникальными бизнес-процессами. Такая CRM может использоваться для продажи другим компаниям.</li>' +
        '</ul>' +
        '<p>Мы советуем обращаться за разработкой CRM в компании, у которых есть опыт в реализации таких проектов. Вы будете иметь дело со специалистами, знающими свое дело, и хорошим сервисом. К слову, нашей компанией реализована CRM для парка высотных развлечений SkyPark, бухгалтерской компании “Avisto”, CRM для микрофинансовой организации “ДеньгиМигом”.</p>' +
        "<div class='step'>06.</div>" +
        '<h3>Перенос данных</h3>' +
        '<p>Вы приобрели CRM, но на этом процесс не заканчивается: стоит подумать о переносе данных в CRM. Для начала, определитесь:</p>' +
        '<ul>' +
        '<li>нужны ли старые данные в новой системе? Полностью или частично?</li>' +
        '<li>каким образом будет осуществляться их перенос: вручную (здесь возможны ошибки, потеря данных), с помощью специалиста или с помощью поставщика выбранной CRM (если такая возможность существует).</li>' +
        '</ul>' +
        '<p>Мы советуем обращаться к поставщику CRM: возможно, в новой системе присутствуют встроенные механизмы переноса данных. В таком случае вы не потеряете ценные данные и сэкономите кучу времени и нервов :)</br>' +
        'В случае с индивидуально разработанной CRM, миграция данных происходит плавно, по мере внедрения модулей в работу, под контролем специалистов.</p>' +
        "<div class='step'>07.</div>" +
        '<h3>Обучение сотрудников</h3>' +
        '<p>Обучение сотрудников для работы в CRM – очень важный шаг, от которого зависит успех внедрения. Для этого вы должны:</p>' +
        '<ul>' +
        '<li>быть заинтересованным. Руководитель — вот кто, в первую очередь, должен быть заинтересован в том, чтобы CRM-система работала эффективно и деньги не были потрачены впустую, быть нацеленным на результат и верить во внедряемый продукт. Сотрудники это чувствуют;</li>' +
        '<li>правильно донести суть CRM. Рассказать, для чего она нужна, как может упростить работу, как поможет достичь больших успехов в работе каждого сотрудника, и какие плоды это принесет компании в будущем;</li>' +
        '<li>подготовить обучающие материалы: это может быть текстовый файл-инструкция или видео; по окончании обучения можно провести тестирование на понимание того, как пользоваться системой;</li> ' +
        '<li>сформировать экспертную группу сотрудников, которые впоследствии будут заниматься обучением внутри компании;</li>' +
        '<li>создать базу знаний — полезный обучающий ресурс внутри CRM; пополняйте ее новыми материалами и находками, которые помогут пользователям эффективно работать с CRM, а также быстро адаптироваться новичкам.</li>' +
        '</ul>' +
        '<h2>CRM-система – важный инструмент управления бизнесом</h2>' +
        '<p>Это помощник, который упростит работу сотрудников, сделает ее прозрачной и повысит эффективность. Во время консультации с бизнес-аналитиком вы узнаете, сколько времени и средств потребуется для внедрения CRM. Средняя стоимость разработки индивидуальной CRM-системы начинается от 400 000 руб. Решив внедрить CRM в работу, вы обрекаете себя на возможность увеличить продажи, повысить лояльность клиентов и продуктивно управлять компанией.</p>',
      image: '/backgrounds/creditCard.png',
      alias: 'kak-s-excel-peresest-na-crm',
      preview: '/backgrounds/creditCard.png',
      description:
        'Практически любой бизнес начинается с Excel. В нем ведется учет клиентов, финансов и всего, на что хватит фантазии. По мере роста бизнеса данных становится больше, и наступает полная неразбериха: информация путается, теряется, используется неэффективно. Из этого следует, что Excel в условиях современных реалий стоит применять как вспомогательный инструмент в работе, но не как полноценную систему учета для бизнеса.',
      tags: [
        { name: 'Частые вопросы', isSelected: false },
        { name: 'Автоматизация', isSelected: false },
      ],
      published: '01/02/2021',
    },
  ],
  cases: [
    {
      path: 'analiz-yuridicheskih-dokumentov-s-pomoschyu-nejronnoj-seti',
      img: '/backgrounds/analiz.svg',
      preview: '/previews/tag-preview.png',
      color: '#f0870e',
      background: 'white',
      projectType: 'CRM/ERP',
      published: true,
      topHeader: false,
      titleDevelop: false,
      title: 'Анализ юридических документов с помощью нейронной сети',
      name: 'T.A.G.',
      descriptionHeader:
        '<p class="text-bold">Заказчик T.A.G. Consulting Russia</p>' +
        '<p>T.A.G. Consulting – транснациональная компания с головным офисом в Дубай (Объединенные Арабские Эмираты). Мы выполняли заказ российского подразделения, T.A.G. Consulting Russia.</p>' +
        '<p>Наш заказчик специализируется на сопровождении крупных финансовых сделок, слияниях и поглощениях компаний, защите корпоративных активов. Обслуживание комплексное – юридическое, финансовое, налоговое. За каждым словом в таком описании скрываются тонны сложных документов.</p>',
      description: 'Анализ юридических документов с помощью нейронной сети',
      blockImgBottom: [
        {
          name: 'block-1',
          text:
            '<h3>Задачи проекта</h3>' +
            '<p>Заказчик регулярно тратил большие ресурсы на предварительный анализ документации. Это неудобные задачи. Они достаточно сложны и конфиденциальны, их нельзя поручить фрилансерам или отдать на аутсорс случайному подрядчику. В то же время, проверка данных не окупает содержание в штате квалифицированных юристов. Прибыльность бизнеса обеспечивается на других участках работы.</p>' +
            '<p>Документы, необходимые для проведения сделок, содержат обманчиво простые сведения – контактную информацию, имена, должности и паспортные данные лиц с правом подписи, даты собраний, сроки полномочий, названия нормативных документов и т.д.</p>' +
            '<p>Проблема в том, что все эти данные могут упоминаться в разных форматах, вариациях, а также содержать ошибки. Например, в «шапке» указано одно лицо с правом подписи, а блок с реквизитами содержит другое. Или дата подписания документа раньше, чем дата события, которое в нем зафиксировано. С точки зрения предварительной проверки неважно, откуда в документах нестыковки, по злому умыслу или случайно. Главное их обнаружить.</p>' +
            '<p>Кроме того, заказчик справедливо настаивал на возможности контролировать выводы, к которым пришла автоматическая система. Слишком рискованно просто загрузить пачку документов, получить на выходе готовый массив информации и никак его не перепроверять, хотя бы выборочно. Значит, нужно разработать монитор для быстрой оценки качества обработки.</p>' +
            '<p>Итак, сложная задача с нюансами – все, как мы любим.</p>' +
            '<h3>Архитектура решения</h3>' +
            '<p>Для анализа юридического текста нельзя использовать набор фиксированных фраз типа «генеральным директором является». Действуя таким образом, легко пропустить информацию, которая встречается в другой формулировке.</p>' +
            '<p>Кроме того, механический перебор заранее приготовленных выражений потребует значительных усилий для настройки системы в дальнейшем. Например, если вместо «гендира» понадобится искать «акционера», нужно составить список со всеми вариациями нового поиска. Это неудобно, особенно для самостоятельных действий заказчика после сдачи проекта.</p>' +
            '<p>Поэтому мы решили поручить анализ документов самообучающейся нейронной сети. Их часто называют одной из разновидностей искусственного интеллекта (ИИ). После стартовой разметки «нейронка» самостоятельно определяет новые, нестандартные формулировки нужных данных – и запоминает их на будущее.</p>' +
            '<p>Оставалось решить, как быть с наглядной проверкой выводов, к которым пришла нейронная сеть. Мы разработали монитор, который показывает рядом с формализованной информацией «сырой» фрагмент распознанного текста и соответствующий кусочек скана исходного документа. Таким образом, заказчик может просто использовать все финальные данные – либо моментально оценить, верно ли они получены.</p>' +
            '<h3>Резюме</h3>' +
            '<p>Искусственный интеллект, машинное обучение – модные термины. Про них много говорят, но редко появляются полезные продукты, которые действительно основаны на этих технологиях.</p>' +
            '<p>Мы разработали систему, которая автоматизировала рутинные операции с помощью ИИ. Наше решение сократило анализ документов с месяцев до часов, а также повысило точность обработки данных. Система уже прошла проверку на Протоколах общего собрания (акционеров или участников общества для назначения генерального директора) и Уставах предприятий. Но красота разработки в том, что она универсальна.</p>' +
            '<p>Нейронная сеть буквально читает и понимает тексты. Распознает смыслы, выделяет нужные фрагменты, сопоставляет их друг с другом. Решений такого уровня, работающих с русскоязычными текстами, пока что очень мало. Поэтому, несмотря на очевидно прикладной и коммерческий характер проекта, мы рассматриваем его, в том числе, как исследовательский.</p>',
        },
      ],
    },
    {
      path: 'kejs-s-otdeleniem-spar-iz-nizhnego-novgoroda',
      color: '#33d468',
      background: 'white',
      topHeader: false,
      published: true,
      projectType: 'Мобильные приложения',
      titleDevelop: false,
      preview: '/previews/spar-preview.png',
      img: '/backgrounds/spar.png',
      title:
        'Как мы сделали приложение, которое полюбил заказчик и возненавидели клиенты: кейс с отделением SPAR из Нижнего Новгорода',
      name: 'Спар',
      description: 'Кейс с отделением SPAR из Нижнего Новгорода',
      descriptionHeader:
        '<p>Заказчик — SPAR, голландская сеть супермаркетов с доставкой, присутствующая в 48 странах. В 2018 году в России было открыто 466 магазинов — мы сотрудничали с представительством из Нижнего Новгорода, которое отвечало за 89 магазинов. До обращения к нам у них был сайт, на котором пользователи смотрели витрину магазина, заказывали доставку продуктов и копили бонусы в программе лояльности. Сайтом пользовались реже, чем хотелось бы. Люди привыкли заказывать доставку через мобильные приложения — и заказчику нужно было встроиться в привычный для пользователей сценарий, чтобы получать больше заказов. За созданием мобильного приложения обратились к нам.</p>',
      blockImgBottom: [
        {
          name: 'block-1',
          text:
            '<h3>Мы взяли информацию по будущему функционалу, согласовали постановку задачи и приступили к реализации</h3>' +
            '<p>Изначально у заказчика не было технического видения и чёткого документа для разработки приложения. Для нас это ненормально — мы в такой ситуации помогаем клиенту разобраться с его задачей и пишем с его слов полноценное ТЗ и по итогу утверждаем готовый документ.</p>' +
            '<p>Выше описан привычный для нас процесс. Он помогает нам экономить бюджет и время клиента — с таким ТЗ мы сразу приходим к единому видению и вносим меньше изменений.</p>' +
            '<p>В случае со SPAR мы отошли от него и писали не глобальное ТЗ, а маленькие ЧТЗ для разработчиков. У клиента не было желания прописывать глобальные ТЗ из-за невозможности провести для этого несколько встреч. Мы подстроились под этот формат, потому что нам было интересно поработать со SPAR, а наши опасения в духе «может быть больше платных переделок» были встречены со спокойствием.</p>' +
            '<p>В ходе обсуждения выяснилось, что клиенту хотелось бы не усложнять разработку и само приложение. Оно задумано только как дубликат сайта. Подключать новые функции не собирались. Когда клиент узнал, что можно даже не разрабатывать бэкенд — он решил, что ему нужен этот вариант.</p>' +
            '<p><span class="text-bold">Сайт и программу лояльности делали на Битриксе.</span> Мы могли сделать фронтенд и подключить приложение к Битриксу, как и хотел клиент. Но у такого приложение чаще возникают технические проблемы. Если у приложения нет своего бэкенда — оно несамостоятельное и зависит от того, как хорошо работает всё остальное. Если у Битрикса сбой — приложение не работает. Отключается программа лояльности, электронные карты и само приложение.</p>' +
            '<p>Мы со своей стороны предупредили заказчика о риске, после чего взяли задачу в работу.</p>' +
            '<p>После обсуждения и согласования будущего функционала приложения мы составили точный список работ. Он выглядел так (даём перевод с «айтишного» на русский, документ для разработчиков прилагаем):</p>' +
            '<p class="text-bold">«Классическое» приложение для ритейлерской сети. Основные функции — программа лояльности, покупка, доставка.</p>' +
            '<p class="text-bold">Что доступно пользователю:</p>' +
            '<ul><li>самостоятельная регистрация и авторизация;</li><li>привязка бонусных и платёжных карт;</li><li>работающая программа лояльности;</li><li>просмотр сети магазинов;</li><li>просмотр каталогов;</li><li>просмотр карточек товаров;</li><li>просмотр новостей и акций;</li><li>подключение, просмотр и отключение push-уведомлений;</li><li>покупка продуктов;</li><li>заказ доставки;</li><li>возможность оставить отзыв.</li></ul>' +
            '<p class="text-bold">Помимо этого, пользователю должно быть доступно подключение к сети магазина — и при подключении должны быть доступны дополнительные функции:</p>' +
            '<ul><li>добавление товаров в корзину вручную;</li><li>добавление товара в корзину по системе Scan&go;</li><li>подключение к сети магазина;</li><li>просмотр ассортимента конкретного магазина;</li><li>формирование, сохранение и отображение чека.</li></ul>' +
            '<p class="text-bold">Как это должно быть реализовано:</p>' +
            '<p class="text-bold">Техническая часть уже создана. От нас требуется:</p>' +
            '<ul><li>спроектировать дизайн и интерфейс мобильного приложения;</li><li>сверстать мобильное приложение;</li><li>интегрировать приложение со Сбербанком, Битриксом и Яндекс.Картами;</li><li>публикация приложения в Play Market и Appstore.</li></ul>' +
            '<p class="text-bold">Заказчик согласовал фронт работ, и мы начали разработку.</p>' +
            '<h3>Задизайнили приложение: свели видение заказчика и рабочую модель мобильного приложения в единую точку</h3>' +
            '<p>Изначально заказчику казалось, что мобильное приложение не очень отличается от сайта, и между ними должно быть мало отличий по дизайну.</p>' +
            '<p>Этот момент был упущен с обеих сторон. Мы со своей стороны связали спокойствие клиента с высокой квалификацией и переоценили её, в связи с чем не стали глубоко прорабатывать вопрос дизайна. Клиент же не знал, что этот момент необходимо проработать: до этого он не работал с диджиталом, поэтому даже не знал, что это стоит проговорить. Из-за этого изначальные концепты у нас не приняли — они не попали в видение.Дизайн «чтобы совсем как в веб-версии, до мельчайших нюансов» получиться не мог.</p>' +
            '<p>Чтение текста на компьютере отличается от чтения текста на телефоне. На компьютере удобно расположить кнопки в одном порядке, на телефоне — в другом. Из-за этого дизайн в мобильных приложениях и веб-версиях отличаются, даже когда всё создано по брендбуку и адаптировано.</p>' +
            '<p class="text-bold">Концепты неплохие, но не соответствуют тому, что хотел получить заказчик</p>' +
            '<p>Но мы и не ждём, что заказчику всё сразу понравится. Пробелы в коммуникациях возможны всегда — даже когда мы пишем 100-страничные общие ТЗ, после работы клиент даёт нам замечания.</p>' +
            '<p>На корректировки и сдачу дизайна заказчику изначально заложено столько же, сколько на сам дизайн — 32 рабочих часа. Несмотря на нештатность ситуации и замечаний, мы сохранили изначальный рабочий темп.</p>' +
            '<p>С нашей стороны поступило несколько встречных предложений. Заказчик конструктивно воспринял их, поэтому глобальных изменений не вносили. Последующая работа уложила видение заказчика и наши предложения в единый дизайн, подходящий для мобильных устройств.</p>' +
            '<p>Ситуация могла стать конфликтной — но переговоры, проведённые с холодной головой, спасли её. Мы провели пару итераций. В результате дизайнеры отдали в работу макеты, которые подходили заказчику и при этом хорошо выглядели бы на мобильных телефонах.</p>' +
            '<p class="text-bold">А вот этот дизайн заказчик уже согласовал</p>',
          design: [
            { img: '/temp/design-1.jpg' },
            { img: '/temp/design-2.jpg' },
            { img: '/temp/design-3.jpg' },
            { img: '/temp/design-4.jpg' },
          ],
        },
        {
          name: 'block-2',
          text:
            '<h3>Сделали приложение, провели дополнительные работы по требованию заказчика и согласовали итог</h3>' +
            '<p>За проект мы взяли в ноябре 2019-го года.</p>' +
            '<p>Работы выполнялись штатно, без выпадения из сроков — на согласование других элементов мы тоже заложили время. Никаких экстренных ситуаций также не происходило. Заказчик своевременно комментировал сделанное, и мы согласовывали результат.</p>' +
            '<p>В процессе возникали дополнительные работы — но там тоже всё шло стандартно, без форс-мажоров. Заказчик запрашивал изменения, добавлял функционал, мы обсуждали сроки и цены, а после реализовывали пожелания.</p>' +
            '<p class="text-bold">Что мы сделали по запросам, чтобы приложение стало лучше:</p>' +
            '<p>К апрелю 2020-го мы закончили работу над приложением, протестировали его и опубликовали в Play Market и Appstore.</p>' +
            '<p>Заказчик получил работающее приложение, которое в итоге стало одним из каналов продаж и помогло оперативно сообщать пользователям о новостях и акциях.</p>' +
            '<h3>Вели техническую поддержку</h3>' +
            '<p>С апреля до июля 2020-го мы поддерживали проект. В это время мы работали над удобством приложения и решали технические проблемы — в том числе те, которые появлялись из-за отсутствия собственного бэкенда. После у заказчика сменился менеджмент, и для поддержки проекта подключили другую команду. Расстались без конфликтов и претензий.</p>' +
            '<h3>Сколько человек мы подключили для реализации и что они делали</h3>' +
            '<p>Всего на проекте было <span class="text-bold">5 человек.</span></p>' +
            '<p><span class="text-bold">Руководитель проекта</span> отвечал за проект и коммуникацию с заказчиком.</p>' +
            '<p><span class="text-bold">Дизайнер</span> проработал пути пользователей, создал удобный интерфейс и нарисовал дизайн.</p>' +
            '<p><span class="text-bold">Android и iOS-программисты </span>писали приложения для платформ и отвечали за публикацию приложения на них.</p>' +
            '<p><span class="text-bold">Тестировщик</span> проверял приложения перед публикацией.</p>',
        },
      ],
    },
    {
      path: 'medteh-dlya-mladentsev-roditelej-i-vrachej',
      color: '#6f52e3',
      background: 'white',
      preview: '/previews/belimer-preview.png',
      img: '/backgrounds/belimor.png',
      published: true,
      topHeader: false,
      projectType: 'Мобильные приложения',
      name: 'Билимер',
      title: 'МедТех для младенцев, родителей и врачей',
      description:
        'IT-компания Текмэн разработала мобильное приложение, которое помогает диагностировать желтуху по фото со смартфона',
      descriptionHeader:
        'IT-компания Текмэн разработала мобильное приложение, которое помогает диагностировать желтуху по фото со смартфона',
      titleDevelop: false,

      blockImgBottom: [
        {
          name: 'block-1',
          text:
            '<h3>Что такое билирубин и почему он опасен</h3>' +
            '<p>Это негативные сценарии запущенной желтухи — самого частого заболевания в младенчестве, которому подвержены 8 из 10 детей.</p>' +
            '<p>Вот почему так важно следить за уровнем билирубина, особенно с первой недели до первого месяца жизни. Только как раз через неделю подавляющее большинство детей уже выписаны из роддома, и родители меньше всего хотят возвращать их в больницу для анализов.</p>' +
            '<h4>Как правило, проверяться на желтуху начинают после появления явных и тревожных симптомов:</h4>' +
            '<ul><li>Заметное изменение цвета кожи, в том числе на ладонях и стопах</li><li>Долгий нездоровый сон</li><li>Вялое сосание груди</li><li>Судороги</li></ul>' +
            '<p>Доводить до такого крайне нежелательно. Слишком большой риск для здоровья малыша — что тем более обидно, ведь своевременно замеченная желтуха легко и безболезненно лечится с помощью фототерапии.</p>',
          blockIcons: [
            { title: 'Отставание в развитии', img: '/temp/med-icon-1.svg' },
            { title: 'Психические расстройства', img: '/temp/med-icon-2.svg' },
            { title: 'Паралич', img: '/temp/med-icon-3.svg' },
            { title: 'Слепота', img: '/temp/med-icon-4.svg' },
            { title: 'Глухота', img: '/temp/med-icon-5.svg' },
          ],
          img: '/backgrounds/med-1.png',
        },
        {
          name: 'block-2',
          text:
            '<h3>Ранняя диагностика желтухи на дому</h3>' +
            '<h4>В настоящее время существует два основных способа замера уровня билирубина, в том числе у новорожденных:</h4>' +
            '<ul><li>По анализу крови. Это самый точный метод, но результата придется подождать. Кроме того, маленького пациента могут нечаянно травмировать или даже инфицировать.</li><li>Оптико-электронный метод с помощью билирубинометра — дорогого устройства, которое тем не менее высокой точности диагноза гарантировать не может.</li></ul>' +
            '<p>И в любом случае, ребенка придется везти в больницу. Что означает лишний стресс, потерю времени, потенциальный риск подхватить какую-то инфекцию. Как правило родители просто не проверяли своих детей без крайней необходимости. То есть дожидались обострений, когда подозрения на желтуху становятся слишком очевидными.</p>' +
            '<p>В качестве инновационного решения врачи «Армед-03» предложили выполнять диагностику желтухи по фотографиям детей — на обычный смартфон, что родителям будет несложно сделать дома.</p>' +
            '<p>Так в «Текмэн» стартовала разработка IT-решения для определения уровня билирубина на основании анализа цифровых изображений.</p>' +
            '<p>Прежде всего нужна была теоретическая база для подобной диагностики, поэтому проект начался с научно-исследовательской работы. Она велась в лучших традициях МедТеха — в тесном сотрудничестве IT-специалистов с врачами.</p>' +
            '<p>Неонатологи, которые «ведут» малышей с первых дней жизни, рассказали о методологии определения опасного уровня билирубина по изменению оттенка кожи.</p>' +
            '<p>Программисты проанализировали техническую сложность и риски предлагаемого решения. В частности, на качество анализа может повлиять модель смартфона, качество оптики, уровень освещенности помещения, даже поза ребенка.</p>',
          img: '/backgrounds/med-2.png',
        },
        {
          name: 'block-3',
          text:
            '<h4>Совместно был сформулирован подробный список требований к терапевтической фотосессии:</h4>' +
            '<ul><li>Естественное освещение (через окна в солнечный день) или яркий, но не точечный источник искусственного света (несколько ламп или люстра)</li><li>Ребенок спокоен, он не плачет, не после ванны, массажа (все это может повлиять на цвет кожи)</li><li>Ребенок лежит на белой пеленке (для хорошего контраста с фоном)</li><li>Никакой яркой и цветной одежды, лишних предметов в поле зрения (для защиты от отсветов и отблесков)</li><li>Ракурс подобран таким образом, чтобы фотография ребенка занимала не менее 80% кадра</li><li>Фокус выставлен на область с кожей, по которой проверяется цветность</li></ul>' +
            '<p>Врачи «Армед-03» с разрешения родителей своих маленьких пациентов начали собирать фотобанк контрольных снимков. В ходе их анализа выяснилось, что наиболее удачны для проверки является фото груди младенца.</p>' +
            '<p>Программисты Текмэн разработали техническое задание на разработку мобильного приложения и серверной части. А после получения принципиального подтверждения о медицинской допустимости запуска проекта в реализацию — начали обучать нейросети по собранным фотографиям.</p>' +
            '<p>Оказалось, что даже несколько дней разницы в возрасте оказывают существенное влияние на достоверность выводов, которые делает система на базе алгоритмов машинного обучения и искусственного интеллекта. Поэтому детей с недели до месяца ранжировали на несколько возрастных групп, измеряемых в днях. Для каждой такой группы велась разработка собственного уникального алгоритма , что в конечном счете привело к появлению целого набора интеллектуальных методов.</p>' +
            '<p>Все они в среднем дают достоверность выводов о подозрениях на желтуху с точностью до 84%, что является высоким показателем. Эти выводы были сделаны после проверки более чем 38 000 фотографий малышей.</p>',
          img: '/backgrounds/med-3.png',
        },
        {
          name: 'block-4',
          text:
            '<h3>Методология решения</h3>' +
            '<p>Математика диагностики в итоге получилась достаточно сложной, поэтому основную нагрузку по анализу фотографий вынесли на серверную часть решения.</p>' +
            '<p>Для повышения качества исходных данных, помимо соблюдения требований к фотографиям, родителям дали возможность сделать произвольное количество кадров, а затем отобрать 3 наиболее четких и удачных из них для отправки на проверку.</p>' +
            '<h5>Система, получившая название «Bilimer», анализирует фотографии, и делает одно из заключений:</h5>' +
            '<ul><li>Зеленый маркер — уровень билирубина в норме.</li><li>Желтый маркер — уровень билирубина повышен. Можно заказать ультрафиолетовую лампу для фототерапии, а также получить консультацию врача-неонатолога.</li><li>Красный маркер — уровень билирубина критический! Рекомендуется срочно обратиться к врачу, получить консультацию, заказать лампу для фототерапии.</li></ul>' +
            '<p>Таким образом, родители получили возможность самостоятельно и без лишних визитов в больницу проверять уровень билирубина у своих детей. В тех случаях, когда нужна медицинская помощь — ее можно получить быстро, с помощью свайпа и нескольких кликов в мобильном приложении.</p>',
          img: '/backgrounds/med-4.png',
        },
        {
          name: 'block-5',
          text:
            '<h3>Технические аспекты</h3>' +
            '<h4>Формальное описание технологического стека разработки, выполненной Текмэн для «Армед-03»:</h4>' +
            '<ul><li>Мобильное приложение для Android: Kotlin</li><li>Мобильное приложение для iOS: Swift</li><li>Серверная часть: Fast Api, Postgres</li><li>Алгоритмы машинного обучения и нейронные сети: TensorFlow, Keras, Scikit-learn</li><li>Интеграция с CRM: Битрикс24</li></ul>' +
            '<p>Интеграция с CRM позволяет «Армед-03» контролировать прогресс по всем пациентам, отслеживать заявки на заказ оборудования, а также заявки на консультации специалистов в привычной для себя CRM-системе. То есть с сохранением действующих и привычных бизнес-процессов. Все фотографии привязываются к профилю конкретного ребенка в аккаунте родителя. Это работает в том числе как электронная медицинская карта.</p>' +
            '<h4>Отдельное внимание уделено безопасности данных:</h4>' +
            '<ul><li>Физической, в виде регулярного резервного копирования, шифрования при передаче, использовании защищенных каналов связи</li><li>Юридической, что заключается в авторизации для получения доступа, а также возможности запросить удаление данных</li></ul>' +
            '<p>Наконец, в системе есть панель администратора для управления аккаунтами пользователей. Тажке в её состав входит CMS, упрощающая обновление контента для пользователей мобильного приложения: полезных тематических статей, вопросов и ответов.</p>' +
            '<p>К этому нужно добавить, что задействование сложных алгоритмов для анализа фотографий для первичного обучения нейросетей стало возможным благодаря технической базе Текмэн. Использовался пул скоростных графических процессоров, без которых такой объем данных просто не получилось бы «переварить» для получения финальных версий алгоритмов.</p>',
          img: '/backgrounds/med-5.png',
        },
      ],
      result:
        '<p>Научные исследования в начале этого проекта потребовали полгода с привлечением лучших специалистов по обе стороны МедТех.</p>' +
        '<p>Медицинскую часть разработки курировали врачи заказчика, компании «Армед-03», под руководством Эрдыни Балданова — эксперта по новорожденным, педиатра и врача-неонатолога с многолетним опытом работы. . Кроме того, привлекались дополнительные консультанты из числа практикующих неонатологов, которые помогли со сбором первичных данных.</p>' +
        '<p>Алгоритмическую часть разработки от Текмэн вел Антон Стефаниди — эксперт с 10-летним опытом в сфере компьютерного зрения, биометрической идентификации, а также различных систем, основанных на алгоритмах машинного обучения и искусственного интеллекта. Специалист публикуется в российских и зарубежных научно-исследовательских изданиях, выступает на отраслевых конференциях и обменивается опытом с коллегами из Google, Facebook и других крупных IT-компаний.</p>' +
        '<p>Симбиоз IT и медицины дал отличный результат.</p>' +
        '<p>Мобильным приложением уже пользуются 2 500 человек. Значит, несколько тысяч ребятишек получают регулярный присмотр без лишнего стресса, у них будет необходимая медицинская помощь и не будет осложнений от желтухи.</p>' +
        '<p>Компания «Армед-03» увеличила охват среди своей целевой аудитории, повысила конверсию по оказываемым услугам. В результате инвестиции в методологию и разработку окупаются уже сейчас.</p>' +
        '<p>Уже составлен список доработок для развития «Bilimer». Он довольно объемный, потому что в процессе создания первой версии решения появилось много идей и гипотез о том, как повысить точность диагностики, сделать мобильное приложение еще удобнее, а бизнес-интеграции еще продуктивнее.</p>',
    },
    {
      preview: '/previews/skypark.png',
      projectType: 'CRM/ERP',
      name: 'Система продажи онлайн-билетов для парка SkyPark (г. Сочи)',
      path: 'skypark',
      color: '#F12F04',
      background: 'white',
      titleDevelop: true,
      topHeader: true,

      tags: [
        {
          name: 'разработка',
          isSelected: false,
        },
      ],
      selects: [
        {
          title: 'Отрасль',
          select: 'Развлечения',
        },
        {
          title: 'Технология',
          select: 'React JS, Yii2, MySQL, JAVA, PHP',
        },
        {
          title: 'Решение',
          select: 'Сервис по продаже билетов',
        },
        {
          title: 'Платформа',
          select: 'Web',
        },
      ],
      image: {
        src: '/backgrounds/skypark.svg',
        coords: {
          lg: {
            top: '320px',
            left: '700px',
          },
          md: {
            top: '344px',
            left: '395px',
          },
          sm: {
            top: '180px',
            left: '175px',
          },
        },
      },
      title: 'Система продажи онлайн-билетов для парка SkyPark (г. Сочи)',
      description: 'Разработка CRM-системы для парка высотных аттракционов',
      summary:
        '<p>ООО “Скайпарк” (SkyPark) – парк развлечений для активного и семейного отдыха. Расположен он в Адлерском районе Сочи, на живописном участке Ахштырского ущелья по дороге из Адлера в Красную поляну. Открыт в 2014 году. Специализируется на высотных аттракционах.</p>',
      logo: '/logos/skypark_logo.svg',
      fullDescription:
        '<p>Парк активных развлечений Скайпарк – место уникальное, красивое и интересное. Наша CRM-система автоматизирует процессы продажи и бронирования билетов, ускоряет и упрощает их для покупателей.</p>',
      goals:
        '<p>Основная цель проекта – автоматизировать процессы продажи и бронирования билетов, ускорить их, сделать удобными для покупателей. За счет этого снизится нагрузка на сотрудников, будет меньше ошибок из-за №человеческого фактора”, оптимизируется расписание аттракционов.</p>',
      objectives:
        '<p>Чтобы достичь этой цели, нужна комплексная CRM-система, которая автоматизирует продажу билетов через интернет-магазин и другие каналы (кассиров, агентов, распространителей, инструкторов аттракционов), а также позволит регистрировать посетителей в самом парке.</p>' +
        '<p>Дополнительные требования к системе:</p>' +
        "<ol class='bold-counter'>" +
        '<li><p>Интерграция с 1С заказчика, с турникетами SkiData, кассовыми аппаратами Атолл, платежной системой  (Сбербанк+Атолл с отправкой фискальных чеков на почту, базами ЕГРЮЛ)</p></li>' +
        '<li><p>Наличие шаблонизаторов для отчетов и договоров</p></li>' +
        '<li><p>Наличие систем ручной и автоматической настройки скидок программ лояльности</p></li>' +
        '<li><p>Масштабируемость (возможность добавления других функциональных модулей, подключения новых пользователей)</p></li>' +
        '</ol>',
      developmentProcess: null,
      haveDone:
        "<div class='text--body base-color'>" +
        '<h2>Бэкграунд</h2>' +
        '<p>Система разрабатывается с нуля.</p>' +
        '<p>Начало проекта (разработка технического задания) март 2017 года.</p>' +
        '<h2>Что сделали</h2>' +
        "<p style='margin-bottom: 30px'>Мы разработали для Скайпарк модульную CRM-систему, включающую в себя:</p>" +
        "<ol style='margin-bottom: 100px' class='ol--numbers ol-two-cols'>" +
        '<li><p>Модуль “Клиент”. Это удобный современны интернет-магазин для продажи входных билетов в парк, билетов на аттракционы, трансферов, дополнительных услуг. </p></li>' +
        '<li><p>Модуль “Саморегистрация”. С его помощью посетители самостоятельно регистрируютсяпо прибытии в парк (это обязательная процедура в целях безопасности).</p></li>' +
        '<li><p>Модуль “Кассир”. Для продажи билетов на различных площадках (в самом парке, в офисах и т.д.)</p></li>' +
        '<li><p>Модуль “Терминал”. Для инструкторов высотных аттракционов.</p></li>' +
        '<li><p>Модуль “Агент”. Для агентов и распространителей билетов.</p></li>' +
        '<li><p>Модуль “Администратор”. Управление всеми компонентами системы.</p></li>' +
        '</ol>' +
        '<p>Наша CRM-система не требует от пользователей установки дополнительного программного обеспечения. Для работы с ней нужен только Web-браузер и индивидуальный логин и пароль для входа, причем пользоваться ею одинаково удобно как с ПК, так и с мобильных устройств с любой операционной системой. Интерфейс простой и интуитивно понятный, так что сотрудники парка быстро с ним освоились. Новым коллегам тоже не требуется много времени, чтобы подключиться к работе.</p>' +
        '<p>Уже с первых недель использования системы — даже с ограниченным функционалом — стало очевидно, что за счет автоматизации процессы продажи и бронирования билетов значительно упростились, ускорились и стали удобнее как для покупателей, так и для самих сотрудников.</p>' +
        '<p>Особое внимание мы уделили дизайну и юзабилити клиентской части системы — интернет-магазина, ведь это одна из визитных карточек СкайПарка. Мы постарались сделать интерфейс магазина лаконичным и элегантным по оформлению, но при этом содержащим всю важную для покупателя информацию по стоимости билета, по срокам его действия, по скидкам и т.д.</p>' +
        '<p>Кроме основной своей функции — автоматизации продажи услуг парка — система предоставляет богатые возможности для сбора и анализа статистической информации, которую можно использовать при составлении бизнес-планов, отчетов, презентаций. Функционал ее в дальнейшем может быть расширен с помощью других специализированных модулей.</p>' +
        '<p>Внедрение производится поэтапно. Модули системы относительно автономны, поэтому их можно вводить в работу по мере готовности, не дожидаясь разработки всего комплекса инструментов.</p>' +
        "<p style=' margin-top: 100px'><b>Технологии, применяемые в ходе разработки, настройки и администрирования системы:</b></p>" +
        "<ol style='margin-top: 60px; max-width: 60%' class='ol--numbers ol-tree-cols'>" +
        '<li><p>ЯП – PHP 7.1, node.js</p></li>' +
        '<li><p>Фреймворк – Yii2, React Native</p></li>' +
        '<li><p>СУБД – MySQL</p></li>' +
        '</ol>' +
        '<h2>Особенности проекта</h2>' +
        '<p>Проект для ООО “Скайпарк” находится в стадии поэтапного внедрения. По состоянию на ноябрь 2018 года работают в штатном режиме основные модули системы — “Администрирование”, “Клиент” и “Кассир”. Через них продается большая часть билетов и услуг парка. Остальные модули готовятся к запуску. Техническое сопровождение системы осуществляется нашими специалистами и штатным администратором на стороне заказчика.</p>' +
        '</div>',
      slider: [
        '/temp/skypark1.png',
        '/temp/skypark2.png',
        '/temp/skypark3.png',
        '/temp/skypark4.png',
        '/temp/skypark5.png',
      ],
      result:
        '<p>Готовая система упрощает и ускоряет работу сотрудников и делает процесс покупки билетов для посетителей быстрым и удобным.</p>',
      published: true,
    },
    {
      preview: '/previews/avisto.png',
      projectType: 'CRM/ERP',
      path: 'avisto',
      color: '#63AEFC',
      background: 'white',
      titleDevelop: true,
      topHeader: true,
      selects: [
        {
          title: 'Отрасль',
          select: 'Бухгалтерские услуги',
        },
        {
          title: 'Технология',
          select: 'CodeIgniter, PHP, HTML5, CSS3, Postgresql ',
        },
        {
          title: 'Решение',
          select: 'CRM-система',
        },
        {
          title: 'Платформа',
          select: 'web',
        },
      ],

            image: {
                src: "/backgrounds/avisto_view_big_web.png",
                coords: {
                    lg: {
                        top: "230px",
                        left: "600px"
                    },
                    md: {
                        top: "320px",
                        left: "360px"
                    },
                    sm: {
                        top: "180px",
                        left: "175px"
                    }
                }
            },
            title: "CRM-система для бухгалтерских услуг Ависто",
            name: "CRM-система для бухгалтерских услуг Ависто",
            description:
                "Разработка CRM-системы, объеденившей в себе множество сервисов и справочников, для сокращения временных затрат на налоговые и бухгалтерские процессы.",
            summary:
                "Основной деятельностью консалтинговой фирмы «Ависто» является бухгалтерский и налоговый аутсорсинг.<br /> К «Ависто» обращаются юридические лица, желающие сократить финансовые и временные затраты на содержание собственной бухгалтерии. ",
            fullDescription:
                "<p>Созданная система позволяет осуществлять контроль деятельности сотрудников, проводить анкетирование в удобной форме, планирование мероприятий и задач, включает в себя базу клиентов и налоговых органов, а также внутреннюю систему обмена сообщениями и письмами.</p>",
            logo: "/logos/Avisto.svg",
            goals:
                "<ol style='font-weight: bold'>" +
                "<li class='mb-2'><p style='font-weight: normal'>Автоматизация бизнес-процессов компании.</p></li>" +
                "<li><p style='font-weight: normal'>Последующий запуск продукта для использования его сторонними компаниями на коммерческой основе.</p></li>" +
                "</ol>",
            objectives:
                "<p>Наиболее важными задачами при разработке проекта являлись:</p>" +
                "<ol style='font-weight: bold'>" +
                "<li class='mb-2'><p style='font-weight: normal'>Замещение таких программ, как почтовые и текстовые мессенджеры, электронные календари и доски, а также пакеты офисных программ;</p></li>" +
                "<li><p style='font-weight: normal'>Разработка функционала для планирования дел и постановки внутренних задач;</p></li>" +
                "<li><p style='font-weight: normal'>Создание единой базы клиентов и налоговых органов;</p></li>" +
                "<li><p style='font-weight: normal'>Работа с корпоративной почтой, входящие и исходящие письма;</p></li>" +
                "<li><p style='font-weight: normal'>Проведение опросов и анкетирования сотрудников;</p></li>" +
                "<li><p style='font-weight: normal'>Автоматизация расчета стоимости услуг и создания коммерческих предложений;</p></li>" +
                "<li><p style='font-weight: normal'>Контроль деятельности сотрудников.</p></li>" +
                "</ol>",
            developmentProcess: [
                {
                    imgSRC: "design.svg",
                    title: "Дизайн",
                    txt:
                        "Используя популярный в последние годы флэт-дизайн, был реализован дашборд, отображающий сотруднику актуальную информацию:  назначеные дела и задачи, события, опросы, а также последние полученые письма."
                },
                {
                    imgSRC: "mail.svg",
                    title: "Почта",
                    txt:
                        "Одной из задач стало создание уникального почтового клиента, способного обьеденить в себе как корпоративную почту заказчика, так и функционал наиболее популярных почтовых сервисов. Реализовав подключение любого почтового ящика к CRM, мы предоставили заказчику возможность общаться с клиентами и налоговыми органами через необходимую в конкретной ситуации учетную запись, на лету выбирая подпись для письма, заготовленую заранее. Функционал текстового редактора предоставляет те же возможности, что и почтовые клиенты от Google, Yandex и т.д."
                },
                {
                    imgSRC: "lead.svg",
                    title: "Для руководителей отделов",
                    txt:
                        "Была реализована возможность контроля сотрудников, а именно просмотр всех входящих и исходящих писем всех подключеных к CRM-системе почтовых аккаунтов."
                },
                {
                    imgSRC: "chat.svg",
                    title: "Чат",
                    txt:
                        "Также для замены текстовых мессенджеров был реализован внутренний чат, дотсупный для сотрудников с любого экрана CRM-системы. Функционал чата схож с популярной социальной сетью VK: возможность создания приватных или общих бесед, их переименование, глобальный чат для всех сотрудников, а также всплывающие соощения в браузере."
                },
                {
                    imgSRC: "addressbook.svg",
                    title: "Адресная книга клиентов",
                    txt:
                        "Адресная книга клиентов. В связке с почтовым клиентом был реализован функционал базы контактов, доступной всем сотрудникам. Адресная книга предоставляет возможность как ручного добавления контактов, так и автоматического анализа почтового ящика с последующим сохранением новых контактов. Также был заложен функционал автоматических почтовых рассылок по указанным заранее пользовательским группам"
                },
                {
                    imgSRC: "reports.svg",
                    title: "Отчеты",
                    txt:
                        "Для автоматизации работы отдела бухгалтерии были разработаны графики и сводные таблицы для контроля финансового оборота и бюджета в целом, почтовых отправлений и внутренней деятельности сотрудников компании, а также удобный календарь отпусков с возможностью согласования ежегодного отпуска."
                }
            ],
            slider: ["/temp/1.png", "/temp/2.png"],
            result: [
                "Консоль управления бизнес-процессами (дашборд)",
                "Почтовый клиент для корпоративной почты",
                "Контроль сотрудников и их почтовых ящиков",
                "Внутренний чат для сотрудников компании",
                "Адресная книга для всех клиентов компании",
                "Графики и свобдные таблицы для контроля финансового оборота",
                "Онлайн-библиотека с возможностью постановки на ознакомление электронных и бумажных изданий с дальнейшим контролем ознакомления",
                "Общее хранилище логинов и паролей",
                "Модуль планирования бюджета и работ по существующим клиентам",
                "Контроль почтовых отправлений через API “Почты России” с возможностью печати конвертов  и многое другое",
                "На данном этапе проект полностью реализован, запущен в эксплуатацию и переведен в режим ежемесячной технической поддержки"
            ],
            published: true
        },
        {
            reviews: [
                {
                    author:
                        "<p>Константин,</p>" +
                        "<p>заместитель директора по развитию компании Деньгимигом</p>",
                    createdAt: 1612171800,
                    files: ["/temp/dm-rev.png"],
                    project: {
                        preview: "/previews/dengimigom.png",
                        projectType: "CRM/ERP",
                        path: "деньгимигом",
                        color: "#65BB2F",
                        background: "white",

                        selects: [
                            {
                                title: 'Отрасль',
                                select: 'Автомобильные сигнализации',
                            },
                            {
                                title: 'Технология',
                                select: 'Adobe Air',
                            },
                            {
                                title: 'Решение',
                                select: 'Мобильная разработка',
                            },
                            {
                                title: 'Платформа',
                                select: 'iOS, Android',
                            },
                        ],

            image: {
              src: '/backgrounds/Dengimigom.svg',
              coords: {
                lg: {
                  top: '170px',
                  left: '700px',
                },
                md: {
                  top: '320px',
                  left: '420px',
                },
                sm: {
                  top: '180px',
                  left: '175px',
                },
              },
            },
            title: 'Новая система учета для Деньгимигом',
            name: 'Новая система учета для Деньгимигом',
            description:
              'Разработка специализированного программного обеспечения и мобильного приложения для службы такси',
            summary:
              'Деньгимигом – российская микрофинансовая компания (МФК), предоставляющая быстрые и краткосрочные займы физическим лицам. Начала свою работу в 2010 году. Сегодня у Деньгимигом 200+ представительств в 100 населенных пунктах РФ, а число сотрудников достигло 800 человек.',
            fullDescription:
              '<p>от клиента: Приложение упрощает и ускоряет бизнес-процессы</p>' +
              '<p>Россетей. Помогает контролерам фиксировать показания</p>' +
              '<p>электросчетчиков и подготавливать отчеты. Приложение упрощает</p>' +
              '<p>и ускоряет бизнес-процессы Россетей.</p>',
            logo: '/logos/dengimigom.svg',
            goals:
              '<p>Деньгимигом выбрала ТЕКМЭН в качестве делового партнера и поставила задачу – достигнуть целей, связанных с автоматизацией бизнес-процессов. Деньгимигом планирует максимально переходить в онлайн и сокращать количество микроофисов.</p>' +
              "<p>В силу закона № 407-ФЗ, который предполагает существенные изменения в профильном законе 'О микрофинансовой деятельности и микрофинансовых организациях' рынок микрофинансирования кардинально изменится. В текущих условиях рынка требуется разработка принципиально новой системы. Она ускорит масштабирование бизнеса за счёт сбора онлайн-аудитории, упростит работу сотрудников на местах, улучшит контроль за работой в регионах, увеличит прозрачность и управляемость бизнеса для собственников.</p>",
            objectives:
              '<p>После детального рассмотрения и анализа целей заказчика с разработчиками были сформированы следующие задачи:</p>' +
              "<ol style='font-weight: bold'>" +
              "<li class='mb-2'><p style='font-weight: normal'>Создание новой системы учета, позволяющей оперативно вносить изменения в бизнес-процессы, а также оптимизировать выдачу займов, работу с просроченной задолженностью, взаимодействие сотрудников по любым вопросам. Микрофинансовая деятельность - непростой процесс, а имеющаяся IT-система не отвечала растущим требованиям бизнеса.</p></li>" +
              "<li><p style='font-weight: normal'>Внедрение разработанной нами системы и выход на рынок онлайн кредитования.</p></li>" +
              '</ol>',
            slider: [
              '/temp/dengimigom1.png',
              '/temp/dengimigom2.png',
              '/temp/dengimigom3.png',
              '/temp/dengimigom4.png',
              '/temp/dengimigom5.png',
            ],
            haveDone:
              "<div class='col col-12 base-color'>" +
              "<img style='margin-bottom: 200px' alt='dengimigiom_ill' src='/temp/dengimigom_ill.png' />" +
              "<h2 style='margin-bottom: 30px'>Что было</h2>" +
              "<p class='base-color'>Заказчик обратился к нам с такими исходными данными:</p>" +
              "<p class='base-color'>– Старая система автоматизации была написана на Delphi 7 + MSSQL и позволяла удовлетворительно справляться с задачами Деньгимигом. Одни разработчики создавали программу в течение нескольких лет, а другие поддерживали и дорабатывали систему под нужды заказчика. За последнее время в МФК накопилось много задач, с которыми не мог справиться штатный программист. Используемые технологии устарели, и как следствие, на рынке труда резко снизилось количество специалистов по ним. </p>" +
              "<p class='base-color'>Разработка новой системы гораздо проще, чем отладка ошибок у имеющейся. В текущих условиях требовалось решение следующих задач:</p>" +
              "<ol class='bold-counter'>" +
              '<li><p>Старая система учета нуждалась в постоянных обновлениях, поддержке и сопровождении. На каждом ПК была установлена программа «Деньги+». В каждом городе, где присутствовал МФК, был 1+ офис, а IT-отдел обслуживал до 400 рабочих станций и решал проблемы пользователей с программой.</p></li>' +
              '<li><p>Система выдачи займов. Деньги предоставлялись только оффлайн, при личном визите заемщика в офис.</p></li>' +
              '</ol>' +
              "<h2 style='margin-top: 200px; margin-bottom: 30px'>Что сделали</h2>" +
              "<p class='base-color'>Специалисты из ТЕКМЭН начали с аудита действующей системы учёта и бизнес-процессов, требующих оцифровки. По итогу была составлена пользовательская документация и краткое описание технической части.</p>" +
              "<p class='base-color'>В процессе подготовки и разработки новой системы учёта был разработан список приоритетных направлений работы. На первом месте была работа с клиентской частью, которая важна для сотрудников и мидл-менеджеров." +
              '<br>Первые результаты, в отличие от апгрейда базы данных и действующей системы, были заметны и понятны сразу. Они помогли быстро повысить лояльность со стороны руководства Деньгимигом.</p>' +
              "<h5 style='margin-bottom: 30px'>Список работ по проекту за 2 года:</h5>" +
              "<div class='text--body'>" +
              "<ol class='ol--numbers ol-two-cols height--300'>" +
              '<li><p>Поддержка и доработка существующей системы оперативного учета на базе Delphi 7 + MSSQL.</p></li>' +
              '<li><p>Разработка системы выдачи займов через Интернет (Онлайн Займы) Vue.js + PHP 7 + Laravel 5.4 + NodeJS + MSSQL. </p></li>' +
              '<li><p>Интеграция с сервисами: Equifax, Scorista, ФССП, ХLombard, MandarinBank, РНКО, смс-шлюзы. </p></li>' +
              '<li><div>' +
              '<p>Разработка новой системы оперативного учета. Vue.js + PHP7 + Laravel 5.4 + NodeJS + MSSQL:</p>' +
              "<ul style='list-style-type: disc'>" +
              "<li style='list-style-position: initial'><p>Модуль автоматизации выдачи займов.</p></li>" +
              "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу юридических служб и служб взыскания задолженности.</p></li>" +
              "<li style='list-style-position: initial'><p>Модуль документооборота компании.</p></li>" +
              "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу единого контакт-центра компании.</p></li>" +
              "<li style='list-style-position: initial'><p>Глобальное дополнение существующего функционала, которое позволяет выдавать займы на карты, перечислять займы и осуществлять прием платежей на сайте.</p></li>" +
              '</ul>' +
              '</div></li>' +
              '</ol>' +
              '</div>' +
              '</div>',
            result:
              "<p class='base-color'>Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.</p>" +
              "<div class='row no-gutters mt-11'>" +
              "<div class='col col-12 col-md-4 base-color px-5'><h5>Новая система учета</h5><p>Новая система учета – локальное решение на серверах компании, реализованное как WEB-интерфейс. Это разгрузило IT-отдел. Теперь он занимается решением локальных вопросов пользователей, возникающих во время работы с единой системой. </p></div>" +
              "<div class='col col-12 col-md-4 base-color px-5'><h5>Полноценный личный кабинет</h5><p>На сайте Деньгимигом теперь есть полноценный личный кабинет. Клиент может оформить, получить и погасить займ всего за 15 минут. В ближайших планах компании – максимальное сокращение оффлайн отделений и увеличение объемов онлайн-займов. </p></div>" +
              "<div class='col col-12 col-md-4 base-color px-5'><h5>Увеличение интернет-аудитории</h5><p>Сейчас микрофинансовая компания выдает займы сразу на банковскую карту. Количество офисов уменьшается с каждым днем, а это многократно сокращает издержки и расширяет географию клиентской базы за счёт интернет-аудитории. В ТЕКМЭН удалось разработать и внедрить скидочную систему, которая мотивирует клиентов брать займы на свою карту, не выходя из дома.</p></div>" +
              '</div>',
            published: true,
          },
          rates: [
            {
              rate: 5,
              title: 'Удовлетворенность',
            },
            {
              rate: 5,
              title: 'Профессионализм',
            },
            {
              rate: 5,
              title: 'Сроки',
            },
            {
              rate: 5,
              title: 'Качество',
            },
          ],
          tags: [
            { name: 'desktop', isSelected: false },
            { name: 'МФО', isSelected: false },
            { name: 'хэштег', isSelected: false },
            { name: 'разработка', isSelected: false },
          ],
          text:
            '“Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.”',
          link: 'Деньгимигом',
        },
      ],
      preview: '/previews/dengimigom.png',
      projectType: 'CRM/ERP',
      path: 'dengimigom',
      color: '#65BB2F',
      background: 'white',
      titleDevelop: true,
      topHeader: true,
      selects: [
        {
          title: 'Отрасль',
          select: 'Кредиты, Займы, МФО, МФК',
        },
        {
          title: 'Технология',
          select: 'Delphi7, MSSQL, Vue.js, PHP, Laravel, NodeJS',
        },
        {
          title: 'Решение',
          select: 'ТЗ, аудит, интеграция, ТП',
        },
        {
          title: 'Платформа',
          select: 'Web, API',
        },
      ],

      image: {
        src: '/backgrounds/Dengimigom.svg',
        coords: {
          lg: {
            top: '170px',
            left: '740px',
          },
          md: {
            top: '320px',
            left: '420px',
          },
          sm: {
            top: '230px',
            left: '175px',
          },
        },
      },
      title: 'Новая система учета для Деньгимигом',
      name: 'Новая система учета для Деньгимигом',
      description:
        'Разработка CRM системы для микрофинансовой компании Деньгимигом, обеспечивающую высокую скорость обработки данных, информационную прозрачность и оперативность работы сотрудников.',
      summary:
        'Деньгимигом – российская микрофинансовая компания (МФК), предоставляющая быстрые и краткосрочные займы физическим лицам. Начала свою работу в 2010 году. Сегодня у Деньгимигом 200+ представительств в 100 населенных пунктах РФ, а число сотрудников достигло 800 человек.',
      fullDescription:
        '<p>“Деньгимигом – одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.”</p>',
      logo: '/logos/dengimigom.svg',
      goals:
        '<p>Деньгимигом выбрала ТЕКМЭН в качестве делового партнера и поставила задачу – достигнуть целей, связанных с автоматизацией бизнес-процессов. Деньгимигом планирует максимально переходить в онлайн и сокращать количество микроофисов.</p>' +
        "<p>В силу закона № 407-ФЗ, который предполагает существенные изменения в профильном законе 'О микрофинансовой деятельности и микрофинансовых организациях' рынок микрофинансирования кардинально изменится. В текущих условиях рынка требуется разработка принципиально новой системы. Она ускорит масштабирование бизнеса за счёт сбора онлайн-аудитории, упростит работу сотрудников на местах, улучшит контроль за работой в регионах, увеличит прозрачность и управляемость бизнеса для собственников.</p>",
      objectives:
        '<p>После детального рассмотрения и анализа целей заказчика с разработчиками были сформированы следующие задачи:</p>' +
        "<ol style='font-weight: bold'>" +
        "<li class='mb-2'><p style='font-weight: normal'>Создание новой системы учета, позволяющей оперативно вносить изменения в бизнес-процессы, а также оптимизировать выдачу займов, работу с просроченной задолженностью, взаимодействие сотрудников по любым вопросам. Микрофинансовая деятельность - непростой процесс, а имеющаяся IT-система не отвечала растущим требованиям бизнеса.</p></li>" +
        "<li><p style='font-weight: normal'>Внедрение разработанной нами системы и выход на рынок онлайн кредитования.</p></li>" +
        '</ol>',
      quotesdm:
        '<p>Специалисты ТЕКМЭН тесно сотрудничали с отделом IT и обучения. Они делились первоначальной информацией с персоналом.<br>' +
        'Это позволило ускорить процесс внедрения новой системы в действующие бизнес-процессы.<br>' +
        'Отдел обучения, в свою очередь, тиражировал накопленный опыт, подготавливая специальные курсы, инструкции и видео-обзоры для сотрудников.</p>',
      problems:
        "<div class='col col-12 base-color'>" +
        "<h3 style='margin-bottom: 30px'>Какие проблемы решили</h3>" +
        "<h4 style='margin-bottom: 30px'>Сомнения клиента</h4>" +
        "<p style='margin-bottom: 30px'>Заказчик имел разный опыт работы с подрядчиками, а потому очень внимательно смотрел на новых исполнителей. Для налаживания плодотворного сотрудничества специалисты из ТЕКМЭН следуют таким правилам:</p>" +
        "<ul style='font-weight: bold; margin-bottom: 30px'>" +
        "<li class='mb-2'><p style='font-weight: 500'>четкое соблюдение дедлайнов и ТЗ;</p></li>" +
        "<li class='mb-2'><p style='font-weight: 500'>качественное выполнение каждого этапа работы;</p></li>" +
        "<li class='mb-2'><p style='font-weight: 500'>демонстрация промежуточных результатов и составление отчетности;</p></li>" +
        "<li class='mb-2'><p style='font-weight: 500'>выезд на переговоры с заказчиком при первой необходимости.</p></li>" +
        '</ul>' +
        "<p style='margin-bottom: 30px'>Большая часть работы делается удаленно. Раз в месяц руководитель проекта назначает встречу, едет к клиенту и решает все вопросы.</p>" +
        "<h4 style='margin-bottom: 30px'>Отсутствие исходных данных</h4>" +
        "<p style='margin-bottom: 30px'>В ТЕКМЭН передали систему, в которой велась работа на тот момент. У неё не было технической документации, а это осложняло работу наших программистов. Им пришлось “перебирать” код для внесения каких-либо изменений и дальнейшего сопровождения продукта.</p>" +
        "<p style='margin-bottom: 30px'>Каким бы хорошим штатный программист не был, ему сложно учитывать все нюансы и в клиентской, и в серверной части. Проблемы возникают в любой системе и не важно, сколько человек работал над ее созданием и оптимизацией.</p>" +
        "<p style='margin-bottom: 30px'>Команда программистов, специализирующаяся на разных аспектах и технологиях, эффективнее одного специалиста. Она соблюдает единые стандарты качества и правила функционирования системы, действует строго по регламенту, а работа принимается с контролем качества. Штатный же программист, без построенной инфраструктуры, может превратить исходный код в клубок противоречий.</p>" +
        '</div>',
      wdone:
        "<div class='col col-12 base-color'>" +
        "<h3 style='margin-bottom: 30px'>Что стало</h3>" +
        "<ol style='font-weight: bold; margin-bottom: 30px'>" +
        "<li class='mb-2'><p style='font-weight: 500'>ТЕКМЭН достиг приоритетной цели Деньгимигом и быстро решил первоочередные задачи для роста бизнеса. В работе большое значение уделяется индивидуальному подходу к замечаниям и рекомендациям заказчика. Демонстрируя промежуточные результаты, мы стараемся повышать качество до максимума. ТЕКМЭН – подрядчик, который работает строго по ТЗ,  а любые коррективы в действующую систему автоматизации МФК сейчас вносит наша техническая поддержка.</p></li>" +
        "<li class='mb-2'><p style='font-weight: 500'>Система выдачи онлайн-займов работает. Теперь клиенту не придется идти в отделение, достаточно просто открыть сайт Деньгимигом. После оформления займа деньги поступят на банковскую карту. Их можно использовать в любых целях.</p></li>" +
        "<li class='mb-2'><p style='font-weight: 500'>Старая система учета и программа «Деньги+» больше не используется. Сейчас работа ведется на новом онлайн продукте, который постоянно изменяется и совершенствуется. В него добавляют новые опции. Одни модули переводятся с решения стратегических задач на текущие, а потом внедряются в систему.</p></li>" +
        '</ol>' +
        '</div>',
      slider: [
        '/temp/dengimigom1.png',
        '/temp/dengimigom2.png',
        '/temp/dengimigom3.png',
        '/temp/dengimigom4.png',
      ],
      haveDone:
        "<div class='col col-12 base-color'>" +
        "<img style='margin-bottom: 200px; max-width: 100%' alt='dengimigiom_ill' src='/temp/dengimigom_ill.png' />" +
        "<h2 style='margin-bottom: 30px'>Что было</h2>" +
        "<p class='base-color'>Заказчик обратился к нам с такими исходными данными:</p>" +
        "<p class='base-color'>– Старая система автоматизации была написана на Delphi 7 + MSSQL и позволяла удовлетворительно справляться с задачами Деньгимигом. Одни разработчики создавали программу в течение нескольких лет, а другие поддерживали и дорабатывали систему под нужды заказчика. За последнее время в МФК накопилось много задач, с которыми не мог справиться штатный программист. Используемые технологии устарели, и как следствие, на рынке труда резко снизилось количество специалистов по ним. </p>" +
        "<p class='base-color'>Разработка новой системы гораздо проще, чем отладка ошибок у имеющейся. В текущих условиях требовалось решение следующих задач:</p>" +
        "<ol class='bold-counter'>" +
        '<li><p>Старая система учета нуждалась в постоянных обновлениях, поддержке и сопровождении. На каждом ПК была установлена программа «Деньги+». В каждом городе, где присутствовал МФК, был 1+ офис, а IT-отдел обслуживал до 400 рабочих станций и решал проблемы пользователей с программой.</p></li>' +
        '<li><p>Система выдачи займов. Деньги предоставлялись только оффлайн, при личном визите заемщика в офис.</p></li>' +
        '</ol>' +
        "<h2 style='margin-top: 200px; margin-bottom: 30px'>Что сделали</h2>" +
        "<p class='base-color'>Специалисты из ТЕКМЭН начали с аудита действующей системы учёта и бизнес-процессов, требующих оцифровки. По итогу была составлена пользовательская документация и краткое описание технической части.</p>" +
        "<p class='base-color'>В процессе подготовки и разработки новой системы учёта был разработан список приоритетных направлений работы. На первом месте была работа с клиентской частью, которая важна для сотрудников и мидл-менеджеров." +
        '<br>Первые результаты, в отличие от апгрейда базы данных и действующей системы, были заметны и понятны сразу. Они помогли быстро повысить лояльность со стороны руководства Деньгимигом.</p>' +
        "<h5 style='margin-bottom: 30px'>Список работ по проекту за 2 года:</h5>" +
        "<div class='text--body'>" +
        "<ol class='ol--numbers ol-two-cols height--300'>" +
        '<li><p>Поддержка и доработка существующей системы оперативного учета на базе Delphi 7 + MSSQL.</p></li>' +
        '<li><p>Разработка системы выдачи займов через Интернет (Онлайн Займы) Vue.js + PHP 7 + Laravel 5.4 + NodeJS + MSSQL. </p></li>' +
        '<li><p>Интеграция с сервисами: Equifax, Scorista, ФССП, ХLombard, MandarinBank, РНКО, смс-шлюзы. </p></li>' +
        '<li><div>' +
        "<p style='margin-bottom: 18px'>Разработка новой системы оперативного учета. Vue.js + PHP7 + Laravel 5.4 + NodeJS + MSSQL:</p>" +
        "<ul style='list-style-type: disc; margin-top: 0'>" +
        "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>Модуль автоматизации выдачи займов.</p></li>" +
        "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>Модуль, автоматизирующий работу юридических служб и служб взыскания задолженности.</p></li>" +
        "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>Модуль документооборота компании.</p></li>" +
        "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>Модуль, автоматизирующий работу единого контакт-центра компании.</p></li>" +
        "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>Глобальное дополнение существующего функционала, которое позволяет выдавать займы на карты, перечислять займы и осуществлять прием платежей на сайте.</p></li>" +
        '</ul>' +
        '</div></li>' +
        '</ol>' +
        '</div>' +
        '</div>',
      result:
        "<p class='base-color'>Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.</p>" +
        "<div class='row no-gutters mt-11'>" +
        "<div class='col col-12 col-md-4 base-color px-5'><div class='numbers'>01.</div><h5>Новая система учета</h5><p>Новая система учета – локальное решение на серверах компании, реализованное как WEB-интерфейс. Это разгрузило IT-отдел. Теперь он занимается решением локальных вопросов пользователей, возникающих во время работы с единой системой. </p></div>" +
        "<div class='col col-12 col-md-4 base-color px-5'><div class='numbers'>02.</div><h5>Полноценный личный кабинет</h5><p>На сайте Деньгимигом теперь есть полноценный личный кабинет. Клиент может оформить, получить и погасить займ всего за 15 минут. В ближайших планах компании – максимальное сокращение оффлайн отделений и увеличение объемов онлайн-займов. </p></div>" +
        "<div class='col col-12 col-md-4 base-color px-5'><div class='numbers'>03.</div><h5>Увеличение интернет-аудитории</h5><p>Сейчас микрофинансовая компания выдает займы сразу на банковскую карту. Количество офисов уменьшается с каждым днем, а это многократно сокращает издержки и расширяет географию клиентской базы за счёт интернет-аудитории. В ТЕКМЭН удалось разработать и внедрить скидочную систему, которая мотивирует клиентов брать займы на свою карту, не выходя из дома.</p></div>" +
        '</div>',
      published: true,
    },
    {
      preview: '/previews/hamstersoft.png',
      projectType: 'Windows программа',
      path: 'hamster-soft',
      color: '#E59C29',
      background: 'white',
      titleDevelop: true,
      topHeader: true,
      selects: [
        {
          title: 'Отрасль',
          select: 'Разработка ПО',
        },
        {
          title: 'Технология',
          select: 'NET. C/C++, QT',
        },
        {
          title: 'Решение',
          select: 'ПРОГРАММЫ ДЛЯ ПК',
        },
        {
          title: 'Платформа',
          select: 'windows',
        },
      ],

            image: {
                src: "/backgrounds/hamster_big_web.png",
                coords: {
                    lg: {
                        top: "170px",
                        left: "700px"
                    },
                    md: {
                        top: "400px",
                        left: "450px"
                    },
                    sm: {
                        top: "300px",
                        left: "175px"
                    }
                }
            },
            title: "Разработка 10 готовых продуктов для компании HamsterSoft",
            name: "Hamstersoft",
            description:
                "На сегодняшний день суммарный объем пользователей всех разработанных нами для компании Hamstersoft приложений составляет более 20 миллионов человек, созданные нами программы ежедневно запускают более 1 миллиона человек.",
            summary:
                "<p>С момента появления на рынке в 2010 году компания HamsterSoft переживает настоящий взлет. Бизнес HamsterSoft построен на создании полностью бесплатных программных продуктов. Бесплатное распространение, простота управления, функциональность и красивый дизайн продуктов – вот идеология компании. </p>" +
                "<p>В 2010 году компания HamsterSoft объявила конкурс среди подрядчиков, и мы оказались единственной командой, которая смогла выдержать требуемое качество и сжатые сроки.</p>",
            fullDescription:
                "<p>Сегодня десятки миллионов пользователей по всему миру могут использовать программы, переведенные на более чем 40 языков, в свободном доступе.</p>",
            logo: "/logos/hamstersoftLogo.png",
            goals:
                "<p>Особое внимание уделялось детальной проработке удобства использования (usability), интерфейсы всех программ должны были быть простыми и интуитивно-понятными, где все задачи решаются минимальным количеством действий.</p>" +
                "<p>Всё это должно было сочетаться с обновленным, ярким, запоминающимся дизайном.</p>",
            objectives:
                "<p>Мы смогли добиться высокой производительности каждого приложения, адаптировали программы к различным устройствам.</p>" +
                "<p>Благодаря нашим усилия в 2010 году компания HamsterSoft стала официальным партнером компании Intel.</p>",
            slider: [
                "/temp/hamster1.png",
                "/temp/hamster2.png",
                "/temp/hamster3.png",
                "/temp/hamster4.png",
                "/temp/hamster5.png",
                "/temp/hamster6.png",
                "/temp/hamster7.png",
                "/temp/hamster8.png"
            ],
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Что сделали</h2>" +
                "<div class='row no-gutters'>" +
                "<div class='col col-12 col-md-6 pr-md-10 base-color'>" +
                "<h5 class='mb-6' style='text-transform: none;'>За несколько лет продуктивной работы с компанией HamsterSoft нами были разработаны и запущены в эксплуатацию следующие программные продукты:</h5>" +
                "<ul>" +
                "<li>PDF Reader</li>" +
                "<li>ZIP Archiver + Mac Archiver</li>" +
                "<li>Video Converter</li>" +
                "<li>Audio Converter</li>" +
                "<li>eBook Converter</li>" +
                "<li>YouTube Loader</li>" +
                "<li>Burner Studio</li>" +
                "<li>Installer</li>" +
                "<li>Агент</li>" +
                "<li>Браузер на базе Chromium</li>" +
                "</ul>" +
                "</div>" +
                "<div class='col col-12 col-md-6 pl-md-10 base-color'>" +
                "<h5 class='mb-6' style='text-transform: none;'>Все приложения объединялись общими принципами:</h5>" +
                "<ul>" +
                "<li>Простота использования;</li>" +
                "<li>Интуитивно понятный интерфейс;</li>" +
                "<li>Портативность;</li>" +
                "<li>Только необходимые для работы инструменты;</li>" +
                "<li>Яркий дизайн;</li>" +
                "<li>Возможность менять цветовое оформление программы;</li>" +
                "<li>Автообновление программ;</li>" +
                "<li>Конвертация множества файлов за один раз;</li>" +
                "<li>Компактный режим для удобной работы;</li>" +
                "<li>Выполнение самых популярных операций;</li>" +
                "<li>Использование библиотек с открытым исходным кодом.</li>" +
                "</ul>" +
                "</div>" +
                "</div>" +
                "</div>",
            result:
                "<p>Работа над этими проектами непрерывно длилась в течении 5 лет, всё это время мы разрабатывали, улучшали и расширяли всю линейку программных продуктов компании HamsterSoft.</p>" +
                "<p>На сегодняшний день суммарный объем пользователей всех описанных приложений составляет более 20 миллионов человек, созданные нами программы ежедневно запускают более 1 миллиона человек.</p>",
            published: true
        },
        {
            preview: "/previews/asap.png",
            projectType: "CRM/ERP",
            name: "ASAP",
            description: "Краудфандинговая платформа в Казахстане",
            path: "asap",
            color: "#A8BA74",
            background: "white",

            selects: [
                {
                    title: "Отрасль",
                    select: "Краудфандинг"
                },
                {
                    title: "Технология",
                    select: "PHP, HTML5, CSS3, JQuery"
                },
                {
                    title: "Решение",
                    select: "Платежные системы, Web-сайт"
                },
                {
                    title: "Платформа",
                    select: "Web"
                }
            ],
            image: {
                src: "/backgrounds/asap_ipad_big_web.png",
                coords: {
                    lg: {
                        top: "300px",
                        left: "470px"
                    },
                    md: {
                        top: "260px",
                        left: "350px"
                    },
                    sm: {
                        top: "160px",
                        left: "150px"
                    }
                }
            },
            title: "Разработка крауд&shy;фандинговой платформы ASAP",
            summary:
                "<p>ASAP – это краудфандинговая платформа в Республике Казахстан. По функционалу схожа с boomstarter.ru или kickstarter.com.</p>" +
                "<p>Основная идея платформы – это привлечение инвестиций в новый или уже созданный бизнес Казахстана. Сделать процесс инвестирования системным и безопасным для обеих сторон.</p>",
            fullDescription:
                "<p>Основная идея платформы – это привлечение инвестиций в новый или уже созданный бизнес Казахстана. Сделать процесс инвестирования системным и безопасным для обеих сторон.</p>",
            logo: "/logos/platforma_ASAP.svg",
            goals: null,
            objectives: null,
            slider: ["/temp/asap1.svg"],
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Особенности реализации проекта:</h2>" +
                "<ul class='base-color'>" +
                "<li>Объединить несколько возможностей на одном сайте: благотворительность, краудфандинг, кредитование и акционерный капитал.</li>" +
                "<li>Защитить учетные записи пользователей не только используя номер телефона и e-mail, но и проверяя документы физических и юридических лиц.</li>" +
                "<li>Реализовать автоматический учет внутреннего рейтинга надежности пользователя, а также персонального кредитного рейтинга и FICO-скоринга, предоставляемых Первым Кредитным Бюро.</li>" +
                "<li>Обеспечить защиту всех участников сделки, используя Электронную цифровую подпись (ЭЦП) при заключении договоров.</li>" +
                "<li>Используя защитные системы электронных платежных систем и ведущих банков Республики Казахстан, гарантировать безопасность всех финансовых операций.</li>" +
                "</ul>" +
                "<h2 class='base-color'>Этапы:</h2>" +
                "<ul class='base-color no-markers'>" +
                "<li><div class='numbers'>01.</div>" +
                "<p>Проектирование и технические задания.</p></li>" +
                "<li><div class='numbers'>02.</div>" +
                "<p>Разработка дизайна и интерфейса.</p></li>" +
                "<li><div class='numbers'>03.</div>" +
                "<p>Переговоры с платежными системами и заключение договоров.</p></li>" +
                "<li><div class='numbers'>04.</div>" +
                "<p>Разработка серверной части и веб-интерфейса.</p></li>" +
                "<li><div class='numbers'>05.</div>" +
                "<p>Тестирование и отладка.</p></li>" +
                "</ul>" +
                "<p class='base-color'><b>Дизайн интерфейса.</b> При разработке интерфейса мы опирались на стандарты, созданные крупными игроками на рынке, Kickstarter и Boomstarter. Это сделало платформу легкой в обучении и понятной даже начинающим инвесторам.</p>" +
                "<h2 class='base-color'>ЭЦП</h2>" +
                "<p class='base-color'>Одним из важных аспектов разработки являлась обязательная регистрация всех участников платформы с помощью ЭЦП (Электронной цифровой подписи). На территории Казахстана местные жители обязаны иметь ЭЦП, фактически это паспорт, то есть электронный документ, в котором содержится вся необходимая информация для идентификации человека. Мы разработали механизм безопасной передачи данных и выполнили все требования государственного уровня для работы с ЭЦП.</p>" +
                "</div>",
            result:
                "<p class='base-color'>Работы по проекту ASAP длились с 2015 года (2 года). За это время была привлечена команда из 7 человек и разработано более 1000 страниц технической документации, выполнены все нормативы и требования к электронному документообороту государственного уровня. Создана краудфандинговая платформа под ключ.</p>" +
                "<ul class='base-color'>" +
                "<li>Мультиязычный интерфейс: KZ, RU, EN.</li>" +
                "<li>Автоматическое формирование пакета документов для заключения сделки.</li>" +
                "<li>Наполнение сайта контентом.</li>" +
                "<li>Интеграцию уполномоченным банком-эквайрером.</li>" +
                "<li>Безопасную передачу данных по зашифрованным каналам.</li>" +
                "<li>Полный аудит безопасности серверов и программной части.</li>" +
                "<li>Инструкции и обучение персонала.</li>" +
                "</ul>",
            published: true
        },
        {
            preview: "/previews/rosseti.png",
            projectType: "мобильные приложения",
            name: "россети",
            path: "rosseti",
            color: "#2D8FFF",
            background: "white",

            selects: [
                {
                    title: "Отрасль",
                    select: "Торговля"
                },
                {
                    title: "Технология",
                    select:
                        "Java, PHP, Ruby ,C/C++, MS SQL, Titanium, Oracle Database, Apache"
                },
                {
                    title: "Решение",
                    select: "Платежные системы, Мобильные приложения"
                },
                {
                    title: "Платформа",
                    select: " iOS, Web"
                }
            ],
            image: {
                src: "/backgrounds/rosseti.svg",
                coords: {
                    lg: {
                        top: "380px",
                        left: "570px"
                    },
                    md: {
                        top: "500px",
                        left: "300px"
                    },
                    sm: {
                        top: "250px",
                        left: "150px"
                    }
                }
            },
            title: "Мобильное приложение для проверки показаний электросчетчиков",
            description:
                "Мобильное решение, позволяющее собирать данные<br>с электросчетчиков дистанционно",
            summary:
                "<p>Суммарная выручка компании за последние годы приблизилась к отметке в 100 млрд рублей. Впечатляющая сумма – но она может и должна быть еще больше, благодаря оперативному и тщательному учету потребления электроэнергии. Сделать это непросто, потому что помимо крупных городов с хорошей инфраструктурой МРСК обслуживает также удаленные и труднодоступные точки.</br> В некоторых из них даже нет сотовой связи. Поэтому собирать данные электросчетчиков автоматически невозможно, а вручную слишком трудоемко.</p>" +
                "<p>Так было раньше, до разработки нашего решения.</p>",
            logo: "/logos/rosseti_logo.svg",
            fullDescription:
                "<p>Межрегиональные распределительные сетевые компании (МРСК) – подразделение организации «Россети Центр», которая контролирует почти 90% подключений к электроэнергии в России. Речь обо всех подключениях: бытовых, промышленных, на транспорте, в сельском хозяйстве, на любых объектах.</p>",
            goals: null,
            objectives:
                "<p>Сбор данных с электросчетчиков осуществляют проверяющие контролеры. Это сотрудники МРСК, которые приезжают на место, осматривают пломбы на приборе, снимают показания. В некоторых случаях может потребоваться замена или дополнительная проверка. Контролеры также проверяют, актуальны ли данные владельца.</p>" +
                "<p>Работа рутинная, звучит довольно просто. Однако, учитывая масштаб деятельности МРСК и количество подключений (а их миллионы) – даже незначительная экономия времени на каждой проверке в сумме даст ощутимый экономический эффект. Кроме того, автоматизация позволяет быстрее и точнее сопоставлять ожидаемый (по опыту прошлых лет) расход электроэнергии с фактическим. Так можно выявлять нарушения, и пресекать хищения.</p>" +
                "<p><b>Именно это стало задачами проекта – ускорить проверки, повысить их точность и информативность.</b></p>",
            slider: [
                "/temp/rosseti1.svg",
                "/temp/rosseti2.svg",
                "/temp/rosseti3.svg"
            ],
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Техническая сложность</h2>" +
                "<p class='base-color'>Программное обеспечение, хранилища информации и протоколы работы МРСК формировались пошагово, в течение многих лет. Поэтому извлечение исходных данных и запись отчетов оказалось довольно трудоемким делом:</p>" +
                "<ul class='base-color'>" +
                "<li>Для получения доступа к учетным записям контролеров необходима интеграция с MS Active Directory.</li>" +
                "<li>Выгрузка заданий осуществляется из SAP.</li>" +
                "<li>Формат выгрузки заданий – очень большой файл в формате MS Excel, который нужно обработать перед использованием.</li>" +
                "<li>Маршруты обхода следует формировать на карте, с указанием точек нахождения каждого счетчика.</li>" +
                "<li>Далее, координаты съема показаний определяются по геолокациям фотографий электросчетчиков.</li>" +
                "<li>И в обратной последовательности – Excel, SAP – сохраняются отчеты.</li>" +
                "</ul>" +
                "<p class='base-color'><b>Таким образом, разработка мобильного приложения под Андроид стала чуть ли не самой легкой задачей проекта.</b></p>" +
                "</div>",
            result:
                "<p>В настоящее время завершена и сдана пилотная версия – действующий прототип, который эксплуатируется в одном из региональных подразделений МРСК, а также изучается и проходит согласования в головном, московском офисе.</p>" +
                "<p>На очереди разработка серверной части с доменной аутентификацией пользователей, аналитической сверкой показаний с прогнозируемыми значениями и другие функции.</p>" +
                "<p>Решение уже выполняет первоначальные задачи. Автоматически строятся маршруты обходов, контролеры тратят меньше времени на дорогу, быстрее проводят каждую проверку, все результаты унифицированы, они сохраняются для обработки и формируют отчеты.</p>" +
                "<p>В будущем этот проект, возможно, станет полноценной частью энергетической системы России. Если масштабирование на федеральную сеть будет одобрено, разработка продолжится, и экономический эффект от ее внедрения отразится в финансовых отчетах МРСК / «Россети Центр» ближайших лет.</p>",
            published: true
        },
        {
            preview: "/previews/basecam.png",
            projectType: "мобильные приложения",
            name: "basecam",
            path: "basecam",
            color: "#31B742",
            background: "white",

            selects: [
                {
                    title: "Отрасль",
                    select: "Электронные стабилизаторы"
                },
                {
                    title: "Технология",
                    select: "Java, PHP"
                },
                {
                    title: "Решение",
                    select: "Мобильные приложения"
                },
                {
                    title: "Платформа",
                    select: "iOS, Android"
                }
            ],
            image: {
                src: "/backgrounds/basecam.svg",
                coords: {
                    lg: {
                        top: "380px",
                        left: "570px"
                    },
                    md: {
                        top: "450px",
                        left: "450px"
                    },
                    sm: {
                        top: "280px",
                        left: "150px"
                    }
                }
            },
            title: "Приложение для управления подвесом экшен-камер",
            description:
                "BaseCam Electronics предоставляет решения для стабилизации камер<br>на базе бесколлекторных моторов с прямым приводом.",
            summary: null,
            logo: "/logos/basecam_logo.svg",
            fullDescription:
                "<p>BaseCam Electronics имеет сеть партнеров из США, Европы, России, Китая и Японии. Компания востребована по всему миру, за счет гибкости в подключаемых камерах, от GoPro Hero до Red Epic, и возможности установки на квадрокоптеры и другие БПЛА. </p>",
            goals: null,
            objectives:
                "<p>Разработать программу по управлению подвесом для видеокамеры с системой стабилизации. Ранее заказчиком испытывался подобный продукт для устройств на платформе Android. Новое техническое задание предполагало разработку приложения для смартфонов и планшетов на iOS (iPhone, iPad любых версий). </p>",
            developmentProcess:
                "<p>Для начала мы задокументировали уже имеющиеся наработки, это позволило нам быстро включиться в работу, а также понять сложные расчеты, которые используются в управлении подвесом.</p>",
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Проблемы с Bluetooth</h2>" +
                "<p class='base-color'>Связь с оборудованием осуществляется посредством беспроводного интерфейса Bluetooth с поддержкой последних версий протокола. На момент разработки на рынке производства ПО под iOS существовала проблематика взаимодействия по Bluetooth-каналу. Соединение между устройством и телефоном были не стабильны, случались обрывы связи в процессе работы. Нашей команде потребовалось несколько недель на изучение проблемы и поиск программного решения. Итоговое тестирование продукта показало стабильное Bluetooth-соединение.</p>" +
                "</div>",
            slider: [
                "/temp/basecam1.svg",
                "/temp/basecam2.svg",
                "/temp/basecam3.svg"
            ],
            result:
                "<p>Поставленная нам задача выполнена всего за 4 месяца небольшим коллективом из двух программистов, дизайнера и руководителя проекта.</p>",
            published: true
        },
        {
            preview: "/previews/rompetrol.png",
            projectType: "мобильные приложения",
            name: "rompetrol",
            path: "rompetrol",
            color: "#ED4330",
            background: "white",

            selects: [
                {
                    title: "Отрасль",
                    select: "Торговля"
                },
                {
                    title: "Технология",
                    select: "laravel, react native , postgresql"
                },
                {
                    title: "Решение",
                    select: "программа лояльности, Мобильные приложения"
                },
                {
                    title: "Платформа",
                    select: "iOS, Android"
                }
            ],
            image: {
                src: "/backgrounds/rompetrol.svg",
                coords: {
                    lg: {
                        top: "540px",
                        left: "570px"
                    },
                    md: {
                        top: "560px",
                        left: "400px"
                    },
                    sm: {
                        top: "320px",
                        left: "150px"
                    }
                }
            },
            title:
                "Мобильное приложение для программы лояльности заправочных станций",
            description:
                "Мобильное решение для программы лояльности на платформах iOS и Android,<br>отвечающее бизнес-логике “Fill&Go”",
            summary:
                "<p>Группа компаний Rompetrol занимается добычей сырой нефти, нефтепереработкой и нефтехимией. Объем производства – миллионы тонн нефтепродуктов в год. В штате более 6 тысяч сотрудников. Это заметный игрок на рынках 11 стран Черноморского и Средиземноморского регионов. </p>",
            logo: "/logos/rompetrol_logo.svg",
            fullDescription:
                "<p>Rompetrol принадлежат более 1100 АЗС в Румынии, Молдове, Болгарии и Грузии. На фирменных автозаправках действует технология “Fill&Go”, которая позволяет платить за топливо один раз в месяц. Благодаря карте лояльности клиенты экономят не только время, но и деньги – бонусы начисляются с учетом каждой заправки.</p>",
            goals:
                "<p>Программа лояльности федерального, даже международного масштаба без мобильного приложения? В наши дни такое звучит странно. Телефон горячей линии, подписка на рассылки и другие каналы связи не дают сопоставимого эффекта. С помощью мобильного приложения можно собирать детальную статистику (вплоть до геотаргетинга), быстро реагировать на запросы, информировать клиентов о новинках и рекламных акциях. Очень много преимуществ, причем некоторые из них незаменимы. </p>",
            objectives:
                "<p>Поэтому руководство Rompetrol заказало у нас разработку приложений для платформ iOS и Android, отвечающих бизнес-логике “Fill&Go”. Конкретно, нужно было учесть следующие требования:</p>" +
                "<ul style='margin-bottom: 30px'>" +
                "<li>Два вида клиентов (физические и юридические лица).</li>" +
                "<li>Контроль расходов на топливо (как для личного автомобиля, так и на весь автопарк бизнес-клиента).</li>" +
                "<li>Географические карты с возможностью выбора АЗС и прокладки маршрутов.</li>" +
                "<li>Показ рекламных акций компании и предложений от партнеров.</li>" +
                "<li>Подробная статистика покупок и скидок пользователей.</li>" +
                "<li>Обратная связь, в том числе оценка качества обслуживания на АЗС для ведения рейтинга.</li>" +
                "<li>Безопасное хранение и передача данных.</li>" +
                "<li>Оптимизированные показатели работы приложения, особенно скорость.</li>" +
                "<li>Режим для офлайн-работы, на время отсутствия доступа к Интернету, с последующей синхронизацией данных после возобновления связи.</li>" +
                "</ul>" +
                "<p>Кроме того, нам доверили разработку дизайна приложения, отвечающего фирменному стилю бренда – максимально понятного и удобного для пользователей.</p>",
            developmentProcess: null,
            haveDone:
                "<div class='text--body base-color'>" +
                "<h2>Этап 1. Дизайн приложения</h2>" +
                "<p>Первое, чем мы занялись после согласования ТЗ – дизайном мобильного приложения. Заказчику было предложено несколько вариантов концепта, один после согласования  проработан детально.</p>" +
                "<p>Структура экранов и управления приложением лаконична, не содержит ничего лишнего, и полностью охватывает все возможные ситуации. Список сценариев взаимодействия с приложением:</p>" +
                "<ul>" +
                "<li>Регистрация и вход</li>" +
                "<li>Карта проезда к АЗС</li>" +
                "<li>Цены и счета</li>" +
                "<li>Отчеты</li>" +
                "<li>Статистика пользователя</li>" +
                "<li>Новости и реклама</li>" +
                "<li>Система оповещений</li>" +
                "<li>Сообщения об ошибках</li>" +
                "<li>Личный профиль</li>" +
                "<li>Обратная связь</li>" +
                "<li>Настройки</li>" +
                "</ul>" +
                "<p>Пользователь видит только необходимую в данный момент информацию и элементы навигации для активного экрана. Всегда очевидно, что можно сделать здесь и сейчас, как выйти в главное меню или перейти в другой раздел.</p>" +
                "<p>Фирменная цветовая гамма Rompetrol, яркие и контрастные сообщения, много «воздуха», наглядная инфографика. Таким приложением хочется пользоваться, оно функционально и эстетично.</p>" +
                "<h2>Этап 2. Сервер бизнес-логики</h2>" +
                "<p>На втором этапе мы выполнили проектирование базы данных приложения, а также интеграцию с информационной системой программы лояльности Rompetrol.</p>" +
                "<p>Согласно требованиям ТЗ, приложение работает как в онлайн-режиме, так и автономно. В случае перебоев в доступе к Интернету, пользователь может просматривать полученную ранее информацию и выполнять различные действия. Синхронизация с общим сервером будет выполнена автоматически после возобновления связи.</p>" +
                "<p>При наличии активного онлайн-соединения (основной, штатный режим работы) доступны все функции. В том числе определение собственного позиционирования на карте и точек ближайших АЗС, к которым можно проложить маршрут.</p>" +
                "<p>Приложение помогает клиенту находить оптимальные места для заправки, информирует о ценах, скидках, специальных предложениях. Все сведения берутся напрямую с сервера, поэтому актуальность данных – здесь и сейчас.</p>" +
                "" +
                "<h2>Этап 3. Мобильные приложения для iOS и Android</h2>" +
                "<p>На третьем, заключительном этапе, мы сверстали экраны по утвержденному дизайну,  разработали сами мобильные приложения для двух платформ (iOS и Android), а также внутренние инструменты для их поддержки.</p>" +
                "<p>Затем протестировали весь функционал, внесли необходимые доработки и снова протестировали. Конечный результат – полностью готовые мобильные приложения, интеграция, серверная часть. Все работает безупречно.</p>" +
                "</div>",
            slider: [
                "/temp/rompetrol1.png",
                "/temp/rompetrol2.png",
                "/temp/rompetrol3.png"
            ],
            result:
                "<p>Проект в общей сложности потребовал больше полугода работы группы из 9 специалистов:</p>" +
                "<ul style='margin-bottom: 30px'>" +
                "<li>Руководитель проекта</li>" +
                "<li>Системный архитектор</li>" +
                "<li>Дизайнер</li>" +
                "<li>3 программиста</li>" +
                "<li>Верстальщик</li>" +
                "<li>Тестировщик</li>" +
                "<li>Технический писатель</li>" +
                "</ul>" +
                "<p>Может показаться, что это – “много народу и долго”. Но такую оценку, как правило, дают люди, далекие от разработки.</p>" +
                "<p>Полный цикл проекта включает пять больших блоков работы, на каждый из которых требуются сотни человеко-часов. Большая часть этих работ выполняется параллельно, и используются  прежние, собственные наработки компании. Строго говоря, фактический срок разработки должен быть больше – просто мы знаем, как «срезать углы» без вреда для качества и надежности системы.<br>Помимо разработки были выполнены интеграции с внешними сервисами.<br>Например, с:</p>" +
                "<ul style='margin-bottom: 30px'>" +
                "<li>системой лояльности на ORPAK</li>" +
                "<li>системой PLURIVA для сервисов системы “Fill&Go”</li>" +
                "<li>SMS-шлюзом</li>" +
                "<li>картографическим сервисом GoogleMaps</li>" +
                "<li>сервисами Rompetrol, парсинг новостной ленты и акций</li>" +
                "<li>механизмом сканирования и распознавания QR-кодов</li>" +
                "<li>сервисом рассылки push-уведомлений</li>" +
                "<li>API социальной сети Facebook</li>" +
                "</ul>" +
                "<p><b>Любая интеграция - это “подводные камни”, делающие проект менее предсказуемым и управляемым.</b></p>" +
                "<p>Полный объем работы очень большой. Его описание занимает сотни страниц проектной документации – и это не считая стартовых и последующих интервью, описаний всех промежуточных эскизов и решений, детального отчета о тестировании, многих других аспектов разработки.<br>Пришлось изрядно потрудиться. Зато мы гордимся результатом. Заправляться по программе “Fill&Go” стало еще проще и удобнее. Потребители получили карманный пульт управления расходами на топливо, а Rompetrol – универсальный инструмент для взаимодействия со своими покупателями.</p>",
            published: true
        },
        {
            preview: "/previews/amigo.png",
            projectType: "мобильные приложения",
            name: "амиго",
            path: "amigo",
            color: "#FED544",
            background: "white",

            selects: [
                {
                    title: "Отрасль",
                    select: "Торговля"
                },
                {
                    title: "Технология",
                    select: "надо заполнить"
                },
                {
                    title: "Решение",
                    select: "программа лояльности, Мобильные приложения"
                },
                {
                    title: "Платформа",
                    select: "iOS, Android"
                }
            ],
            image: {
                src: "/backgrounds/amigo_ipad_big_web.png",
                coords: {
                    lg: {
                        top: "500px",
                        left: "370px"
                    },
                    md: {
                        top: "480px",
                        left: "250px"
                    },
                    sm: {
                        top: "280px",
                        left: "120px"
                    }
                }
            },
            title: "Онлайн-сервис для дилерской сети международного масштаба",
            description:
                "Теперь можно продавать шторы и жалюзи без неудобных тяжелых фолиантов и лишних визитов к покупателям, используя преимущества Интернета – быстрее, нагляднее, выгоднее.",
            summary:
                "<p>У бренда 39 собственных представительств, а также 11 тысяч партнеров. Это огромная сеть. Настолько большая, что ее сотрудников обучают в 56 корпоративных институтах. Кроме того, для наглядного показа ассортимента ежегодно требуется 28 тысяч каталогов. Точнее, так было раньше.</p>" +
                "<p>Наш проект изменил правила игры. Теперь можно продавать шторы и жалюзи без неудобных тяжелых фолиантов и лишних визитов к покупателям, используя преимущества Интернета – быстрее, нагляднее, выгоднее.</p>",
            logo: "/logos/amigo_logo.svg",
            fullDescription:
                "<p>AMIGO GROUP – крупнейший производитель и поставщик жалюзи, рулонных штор и штор-плиссе в России, Украине, Белоруссии и Казахстане. Из комплектующих «Амиго» ежедневно изготавливается 16 тысяч изделий. То есть каждые 6 секунд по новому продукту!</p>",
            goals:
                "<p>Мы должны были автоматизировать все эти операции, причем вместо потери наглядности добиться большей реалистичности. Сложная цель, особенно если учесть разнообразие ассортимента и еще большее число конечных вариантов – ведь конфигурация окон тоже бывает разной.</p>",
            objectives:
                "<p>Шторы и жалюзи могут казаться примитивными изделиями только тем, кто привык на них смотреть глазами пользователя. Отдернул, раздвинул. Действительно, очень просто. Обманчивая легкость базируется на тщательно продуманном дизайне. Это касается как внешнего вида, так и размеров, креплений, всей конструкции в целом. AMIGO GROUP выпускает более 1200 различных материалов. Комбинаторика их использования слишком велика, и на каждой стадии работы нужны специальные знания, на некоторых также инструменты.</p>" +
                "<p>Поэтому первая задача проекта – создание гибкого, удобного онлайн-сервиса для координации работы многочисленных дилеров сети. Причем удобного не только головной компании, а всем участникам, каждому дилеру и мастеру. Ресурс должен демонстрировать все доступные в продаже виды материалов и комплектующих, помогать в них ориентироваться и быстро готовить подробные сметы по заказам.</p>" +
                "<p>Вторая важная задача проекта – ускорение продаж. Это комплексный показатель, он зависит от многих причин. Раньше время уходило на доставку и показ образцов, эскизы новых интерьеров.</p>",
            developmentProcess: null,
            quotes:
                "<p>Как правило, сдача-приемка проекта происходит в относительно узком кругу – между разработчиками и проектной группой заказчика. Только не в этот раз, потому что первая презентация сервиса должна была состояться на корпоративной конференции, перед десятками тысяч дилеров. Каждый из них превосходно знаком со спецификой производства, ведет продажи и способен оценить новый инструмент максимально придирчиво.</p>" +
                "<p>Вишенка на торте – жесткие сроки. Всего несколько месяцев от старта до финиша. Высокий темп без возможности отложить часть ключевого функционала «на потом»... Что же, это был вызов! И мы его приняли.</p>",
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Подготовка</h2>" +
                "<p class='base-color'>Несмотря на цейтнот, мы начали с детального изучения бизнеса AMIGO GROUP. Много общались, посещали офисы и производство. В результате этих встреч комната разработчиков постепенно становилась похожей на мастерскую. Например, одни каталоги образцов жалюзи и штор занимают уже целый шкаф. Все эти материалы идут в работу, они используются для сканирования и оцифровки.</p>" +
                "<p class='base-color'>Интервью наших дизайнеров и специалистов по UX с технологами и продавцами заказчика помогли разработать «пульт управления» продажами. Он не похож на обычный интернет-магазин – потому что адресован дилерам, а не конечным потребителям. Главная задача ресурса в том, чтобы быстро моделировать и обсчитывать заказы с учетом всех технологических нюансов, а также остатков на складах.</p>" +
                "<p class='base-color'>Тем не менее, интересы конечных покупателей тоже были учтены. Архитектура онлайн-сервиса предполагает создание виртуальной витрины, которую удобно просматривать без каких-либо специальных знаний. Каждый может полистать каталоги, оценить внешний вид в интерьере, похожем на свое помещение и зафиксировать выбор в формате привычной «корзины». Ее содержимое, однако, становится реальной покупкой только после ревизии дилера, который может уточнить и дополнить заказ.</p>" +
                "</div>",
            slider: ["/temp/amigo1.png", "/temp/amigo2.png"],
            result:
                "<h3>Внешний вид сервиса</h3>" +
                "<p>Дизайнеры предложили необычное решение – один (1) экран. Не первый, в виде «главной» страницы большого онлайн-ресурса, а единственный. Как же уместить разнообразное управление и обширный ассортимент в таком крошечном объеме?<br>Вот так:</p>" +
                "<img alt='result1' src='/temp/amigo_res_1.png' />" +
                "<p>Обратите внимание на то, что несмотря на большое количество разных опций, навигация не перегружена. Все на виду, потому что на самом деле отображается далеко не все. Это ведь пульт, и действует он классическим образом – двигатель скрыт под капотом, на виду приборная панель, руль и рычаги управления. За каждой кнопкой, списком, переключателем здесь большие массивы данных и сложная аналитика их обработки.<br>Обманчиво простой и «легкий» интерфейс потребовал двух месяцев напряженной работы, за которые были созданы десятки прототипов. Мы заменяли и дорабатывали их по замечаниям специалистов AMIGO GROUP, с каждым шагом постепенно приближаясь к результату.</p>" +
                "<div class='row no-gutters'>" +
                "<div class='col col-12 col-md-6 pr-md-2'><img alt='result2' src='/temp/amigo_res_2.png' /></div>" +
                "<div class='col col-12 col-md-6 pl-md-2'><img alt='result3' src='/temp/amigo_res_3.png' /></div>" +
                "</div>" +
                "<p>Особое внимание было уделено реалистичности показа изделий в интерьере. Это важно не столько из эстетических соображений (хотя они тоже учитывались), сколько для обеспечения полноценной демонстрации и онлайн-«примерки». Дизайнеры по интерьеру весьма чувствительны к переходу от тактильного уровня к визуальному. Им нужна действительно безупречная картинка – с учетом геометрии, освещения, других параметров.<br>" +
                "    Поэтому после непродолжительных размышлений и споров мы отказались от очевидно более простого пути с «плоской» отрисовкой образцов на экране в пользу полноценной 3D-модели. <b>Объем работ вырос нелинейно (мы до сих пор продолжаем оцифровывать каталоги образцов). Зато послойное формирование каждого слайда позволяет добиваться фотореалистичного внешнего вида.</b></p>" +
                "<p>Для зрителя это и есть фото, каждая примерка будто кадр с другими расцветками и фактурой штор или жалюзи. На самом деле все материалы записаны отдельно, конфигурация интерьера (в том числе оконный проем) отдельно, вся фурнитура и остальные детали тоже.<br>Когда вы кликаете по списку, выбирая новый материал для показа, сцена полностью перерисовывается. Благодаря оптимизации «движка под капотом» это происходит так быстро, что кажется будто просто загрузилось следующее фото.<br>" +
                "    <b>Гибкая архитектура полностью устроила всех специалистов заказчика, которые уже попробовали наше решение в своей работе. Можно выбирать форму окон, цвета рамы и фурнитуры. Даже управлять уровнем освещенности, добиваясь точного представления о том, как именно будет выглядеть конкретный вид изделия в определенном интерьере.</b></p>" +
                "<p>Конечным потребителям тщательная прорисовка интерьера тоже сильно помогает. Проще понять, что именно выбрать из сотен и тысяч вариантов расцветки, когда сразу видно, как именно это будет выглядеть после установки. Без трехмерной визуализации покупателям сложнее определиться, и в конечном счете они тратят не только свое лишнее время, но и время специалистов дилера. <b>Таким образом, инвестиция в объемную графику окупится для нашего заказчика дважды – она будет экономить время и на согласованиях, и при выполнении каждого заказа.</b></p>" +
                "<p>Онлайн-сервис также автоматизирует все основные действия по составлению сметы. Она формируется буквально в несколько кликов. Сайт сам подсчитывает стоимость выбранных товаров. Дилеру остается нажать кнопку, чтобы отправить детализированный заказ в производство.</p>" +
                "<p>Надо добавить, что помимо витрины, у системы есть также техническая часть. Другими словами, профи могут заглянуть «под капот», чтобы получить быстрый доступ к большим объемам служебной и коммерческой информации. Например, так выглядит уточнение спецификаций по заказанным позициям:</p>" +
                "<div class='row no-gutters'>" +
                "<div class='col col-12 col-md-6 pr-md-2'><img alt='result4' src='/temp/amigo_res_4.png'></div>" +
                "<div class='col col-12 col-md-6 pl-md-2'><img alt='result5' src='/temp/amigo_res_5.png'></div>" +
                "</div>" +
                "<p>Снаружи этой части сервиса не видно, она для служебного пользования, то есть доступна только дилерам – и управляющей компании бренда, разумеется. <br><b>Теперь администрировать продажи стало гораздо проще.</b></p>" +
                "<p>Что особенно важно, AMIGO GROUP сможет экономить время и средства на донесение своих стандартов качества до каждого регионального представителя, каждого дилера. Достаточно внести новый материал в базу и описать параметры его применения. В определенном смысле мы автоматизировали не только демонстрацию и выбор продукции, но и сам бизнес заказчика.</p>" +
                "<h2>Резюме</h2>" +
                "<p>Работа над проектом продолжается. Мы добавляем новые виды материалов в базу данных сервиса, расширяем библиотеку профилей, фактур, других опций по визуализации. Кроме того, по результатам пилотного тестирования у дилеров приходит полезная обратная связь в виде предложений новых функций, «шлифовке» системы под их бизнес-процессы. Собираем и обрабатываем такие сигналы, они формируют стек доработок на следующем витке развития сервиса.</p>" +
                "<p>Результат, который уже достигнут – разработан необычный и функциональный инструмент для дилеров (b2b), а также красочная и наглядная витрина ассортимента, в том числе для конечных потребителей (b2c). И все это на одном экране!</p>",
            published: true
        },
        {
            preview: "/previews/payme.png",
            projectType: "мобильные приложения",
            name: "Pay-Me",
            path: "pay-me",
            color: "#D3AA17",
            background: "white",

            selects: [
                {
                    title: "Отрасль",
                    select: "Торговля"
                },
                {
                    title: "Технология",
                    select:
                        "Java, PHP, Ruby ,C/C++, MS SQL, Titanium, Oracle Database, Apache"
                },
                {
                    title: "Решение",
                    select: "Платежные системы, Мобильные приложения"
                },
                {
                    title: "Платформа",
                    select: "iOS, Web"
                }
            ],
            image: {
                src: "/backgrounds/payme.svg",
                coords: {
                    lg: {
                        top: "342px",
                        left: "630px"
                    },
                    md: {
                        top: "450px",
                        left: "395px"
                    },
                    sm: {
                        top: "230px",
                        left: "94px"
                    }
                }
            },
            title: "Платёжная система Pay-Me",
            description:
                "Мобильное решение позволяющее принимать оплату карты Visa и MasterCard",
            summary:
                "<p>Обратившийся к нам Заказчик, в партнёрстве с Альфа-банком, VISA и компанией Билайн, решил создать в России сервис по приему платежей через смартфоны, получивший название «Pay-Me». Суть технологии состоит в том, чтобы использовать любой смартфон или планшет в качестве терминала для приема банковских карт. Для этого к смартфону подключается специальный ридер.</p>",
            logo: "/logos/payme_logo.png",
            fullDescription:
                "<p>Каждый, кто путешествовал по Европе, знает, что расплатиться банковской картой там можно практически везде, от маленькой магазинчика до гипермаркета. С супермаркетами и в России дело обстоит неплохо, а вот прием безналичной оплаты в небольших точках продаж далек от совершенства. И, хотя банковские карты сейчас есть у всех, малый бизнес не в силах развивать систему приема безналичных платежей.</p>",
            goals: null,
            objectives: null,
            developmentProcess: null,
            haveDone:
                "<div class='text--body base-color'>" +
                "<h2>Что сделали</h2>" +
                "<p>Обратившийся к нам Заказчик, в партнёрстве с Альфа-банком, VISA и компанией Билайн, решил создать в России сервис по приему платежей через смартфоны, получивший название «Pay-Me». Суть технологии состоит в том, чтобы использовать любой смартфон или планшет в качестве терминала для приема банковских карт. Для этого к смартфону подключается специальный ридер.</p>" +
                "<h5 class='my-8'>За 5 месяцев работ выполнено “под ключ”:</h5>" +
                "<ul class='base-color no-markers two-cols'>" +
                "<li><div class='numbers'>01.</div>" +
                "<p>Подобрано, закуплено, прошито, настроено оборудование</p></li>" +
                "<li><div class='numbers'>02.</div>" +
                "<p>Разработаны мобильные приложения для смартфонов, использующих iOS и Android</p></li>" +
                "<li><div class='numbers'>03.</div>" +
                "<p>Написаны драйвера для работы с кард-ридерами</p></li>" +
                "<li><div class='numbers'>04.</div>" +
                "<p>Осуществлена интеграция с процессинговым банком</p></li>" +
                "<li><div class='numbers'>05.</div>" +
                "<p>Разработаны десятки регламентов и прочих технических документов</p></li>" +
                "<li><div class='numbers'>06.</div>" +
                "<p>Создан сайт компании и продукта</p></li>" +
                "<li><div class='numbers'>07.</div>" +
                "<p>Запущены вторая и третья линии техподдержки, работающие в режиме 24/7</p></li>" +
                "</ul>" +
                "</div>",
            slider: ["/temp/payme1.png", "/temp/payme2.png", "/temp/payme3.png"],
            result:
                "<p>В результате был разработан продукт, который в настоящее время работает в четырех странах (Россия, Казахстан, Украина, Беларусь), используется топовыми банками и мобильными операторами. «Билайн-Бизнес» и Альфа-Банк активно продают устройства корпоративным клиентам (суммарно с 2013 продано более 15 000 устройств и получено более 4 000 заявок на подключение).<br>" +
                "Работа над проектом продолжалась 18 месяцев. Над проектом работала команда из 20 человек, в числе которых были разработчики мобильных приложений, web-программисты, разработчик SDK для модуля чтения банковских карт и команда дизайнеров.<br>" +
                "После окончания основного цикла работ и полугодовой технической поддержки, продукт был передан на баланс заказчика, и сейчас полностью независим от компании-разработчика.</p>",
            published: true
        }
    ],
    reviews: [
        {
            author:
                "<p>Константин,</p>" +
                "<p>заместитель директора по развитию компании Деньгимигом</p>",
            createdAt: 1612171800,
            files: ["/temp/dengiMigomReview.png"],
            project: {
                preview: "/previews/dengimigom.png",
                projectType: "CRM/ERP",
                path: "деньгимигом",
                color: "#65BB2F",
                background: "white",

                selects: [
                    {
                        title: "Industry",
                        select: "Автомобильные сигнализации"
                    },
                    {
                        title: "Technology",
                        select: "Adobe Air"
                    },
                    {
                        title: "Solution",
                        select: "Мобильная разработка"
                    },
                    {
                        title: "Platform",
                        select: "iOS, Android"
                    }
                ],

                image: {
                    src: "/backgrounds/Dengimigom.svg",
                    coords: {
                        lg: {
                            top: "170px",
                            left: "700px"
                        },
                        md: {
                            top: "320px",
                            left: "350px"
                        },
                        sm: {
                            top: "180px",
                            left: "175px"
                        }
                    }
                },
                title: "Новая система учета для Деньгимигом",
                name: "Новая система учета для Деньгимигом",
                description:
                    "Разработка специализированного программного обеспечения и мобильного приложения для службы такси",
                summary:
                    "“Деньгимигом – одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.”",
                fullDescription:
                    "<p>от клиента: Приложение упрощает и ускоряет бизнес-процессы</p>" +
                    "<p>Россетей. Помогает контролерам фиксировать показания</p>" +
                    "<p>электросчетчиков и подготавливать отчеты. Приложение упрощает</p>" +
                    "<p>и ускоряет бизнес-процессы Россетей.</p>",
                logo: "/logos/dengimigom.svg",
                goals:
                    "<p>Деньгимигом выбрала ТЕКМЭН в качестве делового партнера и поставила задачу – достигнуть целей, связанных с автоматизацией бизнес-процессов. Деньгимигом планирует максимально переходить в онлайн и сокращать количество микроофисов.</p>" +
                    "<p>В силу закона № 407-ФЗ, который предполагает существенные изменения в профильном законе 'О микрофинансовой деятельности и микрофинансовых организациях' рынок микрофинансирования кардинально изменится. В текущих условиях рынка требуется разработка принципиально новой системы. Она ускорит масштабирование бизнеса за счёт сбора онлайн-аудитории, упростит работу сотрудников на местах, улучшит контроль за работой в регионах, увеличит прозрачность и управляемость бизнеса для собственников.</p>",
                objectives:
                    "<p>После детального рассмотрения и анализа целей заказчика с разработчиками были сформированы следующие задачи:</p>" +
                    "<ol style='font-weight: bold'>" +
                    "<li class='mb-2'><p style='font-weight: normal'>Создание новой системы учета, позволяющей оперативно вносить изменения в бизнес-процессы, а также оптимизировать выдачу займов, работу с просроченной задолженностью, взаимодействие сотрудников по любым вопросам. Микрофинансовая деятельность - непростой процесс, а имеющаяся IT-система не отвечала растущим требованиям бизнеса.</p></li>" +
                    "<li><p style='font-weight: normal'>Внедрение разработанной нами системы и выход на рынок онлайн кредитования.</p></li>" +
                    "</ol>",
                slider: [
                    "/temp/dengimigom1.png",
                    "/temp/dengimigom2.png",
                    "/temp/dengimigom3.png",
                    "/temp/dengimigom4.png",
                    "/temp/dengimigom5.png"
                ],
                haveDone:
                    "<div class='col col-12 base-color'>" +
                    "<img style='margin-bottom: 200px' alt='dengimigiom_ill' src='/temp/dengimigom_ill.png' />" +
                    "<h2 style='margin-bottom: 30px'>Что было</h2>" +
                    "<p class='base-color'>Заказчик обратился к нам с такими исходными данными:</p>" +
                    "<p class='base-color'>– Старая система автоматизации была написана на Delphi 7 + MSSQL и позволяла удовлетворительно справляться с задачами Деньгимигом. Одни разработчики создавали программу в течение нескольких лет, а другие поддерживали и дорабатывали систему под нужды заказчика. За последнее время в МФК накопилось много задач, с которыми не мог справиться штатный программист. Используемые технологии устарели, и как следствие, на рынке труда резко снизилось количество специалистов по ним. </p>" +
                    "<p class='base-color'>Разработка новой системы гораздо проще, чем отладка ошибок у имеющейся. В текущих условиях требовалось решение следующих задач:</p>" +
                    "<ol class='bold-counter'>" +
                    "<li><p>Старая система учета нуждалась в постоянных обновлениях, поддержке и сопровождении. На каждом ПК была установлена программа «Деньги+». В каждом городе, где присутствовал МФК, был 1+ офис, а IT-отдел обслуживал до 400 рабочих станций и решал проблемы пользователей с программой.</p></li>" +
                    "<li><p>Система выдачи займов. Деньги предоставлялись только оффлайн, при личном визите заемщика в офис.</p></li>" +
                    "</ol>" +
                    "<h2 style='margin-top: 200px; margin-bottom: 30px'>Что сделали</h2>" +
                    "<p class='base-color'>Специалисты из ТЕКМЭН начали с аудита действующей системы учёта и бизнес-процессов, требующих оцифровки. По итогу была составлена пользовательская документация и краткое описание технической части.</p>" +
                    "<p class='base-color'>В процессе подготовки и разработки новой системы учёта был разработан список приоритетных направлений работы. На первом месте была работа с клиентской частью, которая важна для сотрудников и мидл-менеджеров." +
                    "<br>Первые результаты, в отличие от апгрейда базы данных и действующей системы, были заметны и понятны сразу. Они помогли быстро повысить лояльность со стороны руководства Деньгимигом.</p>" +
                    "<h5 style='margin-bottom: 30px'>Список работ по проекту за 2 года:</h5>" +
                    "<div class='text--body'>" +
                    "<ol class='ol--numbers ol-two-cols height--300'>" +
                    "<li><p>Поддержка и доработка существующей системы оперативного учета на базе Delphi 7 + MSSQL.</p></li>" +
                    "<li><p>Разработка системы выдачи займов через Интернет (Онлайн Займы) Vue.js + PHP 7 + Laravel 5.4 + NodeJS + MSSQL. </p></li>" +
                    "<li><p>Интеграция с сервисами: Equifax, Scorista, ФССП, ХLombard, MandarinBank, РНКО, смс-шлюзы. </p></li>" +
                    "<li><div>" +
                    "<p>Разработка новой системы оперативного учета. Vue.js + PHP7 + Laravel 5.4 + NodeJS + MSSQL:</p>" +
                    "<ul style='list-style-type: disc'>" +
                    "<li style='list-style-position: initial'><p>Модуль автоматизации выдачи займов.</p></li>" +
                    "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу юридических служб и служб взыскания задолженности.</p></li>" +
                    "<li style='list-style-position: initial'><p>Модуль документооборота компании.</p></li>" +
                    "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу единого контакт-центра компании.</p></li>" +
                    "<li style='list-style-position: initial'><p>Глобальное дополнение существующего функционала, которое позволяет выдавать займы на карты, перечислять займы и осуществлять прием платежей на сайте.</p></li>" +
                    "</ul>" +
                    "</div></li>" +
                    "</ol>" +
                    "</div>" +
                    "</div>",
                result:
                    "<p class='base-color'>Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.</p>" +
                    "<div class='row no-gutters mt-11'>" +
                    "<div class='col col-12 col-md-4 base-color px-5'><h5>Новая система учета</h5><p>Новая система учета – локальное решение на серверах компании, реализованное как WEB-интерфейс. Это разгрузило IT-отдел. Теперь он занимается решением локальных вопросов пользователей, возникающих во время работы с единой системой. </p></div>" +
                    "<div class='col col-12 col-md-4 base-color px-5'><h5>Полноценный личный кабинет</h5><p>На сайте Деньгимигом теперь есть полноценный личный кабинет. Клиент может оформить, получить и погасить займ всего за 15 минут. В ближайших планах компании – максимальное сокращение оффлайн отделений и увеличение объемов онлайн-займов. </p></div>" +
                    "<div class='col col-12 col-md-4 base-color px-5'><h5>Увеличение интернет-аудитории</h5><p>Сейчас микрофинансовая компания выдает займы сразу на банковскую карту. Количество офисов уменьшается с каждым днем, а это многократно сокращает издержки и расширяет географию клиентской базы за счёт интернет-аудитории. В ТЕКМЭН удалось разработать и внедрить скидочную систему, которая мотивирует клиентов брать займы на свою карту, не выходя из дома.</p></div>" +
                    "</div>",
                published: true
            },
            rates: [
                {
                    rate: 5,
                    title: "Satisfaction"
                },
                {
                    rate: 5,
                    title: "Professionalism"
                },
                {
                    rate: 5,
                    title: "Deadlines"
                },
                {
                    rate: 5,
                    title: "Quality"
                }
            ],
            tags: [
                { name: "desktop", isSelected: false },
                { name: "МФО", isSelected: false },
                { name: "хэштег", isSelected: false },
                { name: "разработка", isSelected: false }
            ],
            text:
                "“Деньгимигом – одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.”",
            link: "Деньгимигом"
        }
    ],
    tags: [{ name: "разработка" }, { name: "мобильный" }, { name: "дизайн" }],
    reviewTags: [
        { name: "desktop", isSelected: false },
        { name: "МФО", isSelected: false },
        { name: "хэштег", isSelected: false },
        { name: "разработка", isSelected: false }
    ],
    selectList: [
        {
            title: "Отрасль",
            isSelected: false,
            select: [{ text: "Автомобильные сигнализации", value: 1 }],
            id: 1
        },
        {
            title: "Технология",
            isSelected: false,
            select: [{ text: "Технология", value: 3 }],
            id: 2
        },
        {
            title: "Решение",
            isSelected: false,
            select: [{ text: "Решение", value: 4 }],
            id: 3
        },
        {
            title: "Платформа",
            isSelected: false,
            select: [
                { text: "IOS", value: 5 },
                { text: "Android", value: 6 },
                { text: "ПК", value: 7 }
            ],
            id: 4
        }
    ]

};

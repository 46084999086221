import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Locales } from "@/i18n/locales";
import { defaultLocale } from "@/i18n";

@Module({ namespaced: true })
class Locale extends VuexModule {
  public lang = localStorage.getItem("locale")
    ? localStorage.getItem("locale")
    : defaultLocale;
  @Mutation
  public SET_LANG_TO_VUEX(payload: Locales): void {
    this.lang = payload;
  }
  @Action
  public SAVE_LANG(payload: Locales): void {
    this.context.commit("SET_LANG_TO_VUEX", payload);
    localStorage.setItem("locale", payload)
  }
}

export default Locale;


































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CasesInterface, TagsInterface, TecArticle } from "@/interfaces";

@Component({})
export default class TCard extends Vue {
  @Prop({ default: undefined }) readonly cards?:
    | Array<CasesInterface>
    | Array<TecArticle>;
  @Prop({ default: true }) readonly bordered?: boolean;
  @Prop({ default: "white" }) readonly color?: string;
  @Prop({ default: undefined }) readonly titleColor?: string;
  @Prop({ default: false }) readonly blog?: boolean;
  @Prop({ default: false }) readonly article?: boolean;
  @Prop({ default: false }) readonly upline?: boolean;

  public tegString(tags: TagsInterface[], check = false) {
    const line = tags?.map((e) => `#${e.name}`).join(", ");
    let short = "";
    if (!this.$vuetify.breakpoint.smAndDown && line.length > 20 && !check) {
      short = line.slice(0, 20);
      return `${short}...`;
    }
    return line;
  }
}




























































import { Component, Prop, Vue } from "vue-property-decorator";
import { CasesInterface } from "@/interfaces";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn },
})
export default class Slider extends Vue {
  @Prop({ default: undefined }) readonly content?: Array<CasesInterface>;

  private onboarding = 0;
}

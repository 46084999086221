















import { Component, Prop, Vue } from "vue-property-decorator";
import { Links } from "@/interfaces";
@Component({})
export default class TecLink extends Vue {
  @Prop({ default: undefined }) readonly links?: Array<Links>;
}

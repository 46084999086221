


































import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class TecBtn extends Vue {
  @Prop({ default: false, type: Boolean }) readonly disabled?: boolean;
  @Prop({ default: false, type: Boolean }) readonly outlined?: boolean;
  @Prop({ default: false, type: Boolean }) readonly big?: boolean;
  @Prop({ default: false, type: Boolean }) readonly noarrow?: boolean;
  @Prop({ default: false, type: Boolean }) readonly loading?: boolean;
}

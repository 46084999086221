





























































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import TecCardMain from "@/components/tecCard/TecCardMain.vue";
import PageHeader from "@/components/pageHeader/PageHeader.vue";
import TecLink from "@/components/tecLink/TecLink.vue";
import TecTabs from "@/components/tecTabs/TecTabs.vue";
import ReviewCard from "@/components/reviewCard/ReviewCard.vue";
import TCard from "@/components/tecCard/TCard.vue";
import ArticlesSlider from "@/components/slider/articlesSlider.vue";
import Map from "@/components/map/Map.vue";

import { namespace } from "vuex-class";
import { TecArticle } from "@/interfaces";
import { i18n } from "@/main";

const Modal = namespace("Modal");

@Component({
  components: {
    ArticlesSlider,
    TCard,
    ReviewCard,
    TecTabs,
    TecLink,
    PageHeader,
    TecCardMain,
    TecBtn,
    Map,
  },
})
export default class Tecman extends Vue {
  @Prop() readonly articles!: TecArticle[][] | TecArticle[];

  @Modal.Action
  public showModal!: (switcher: boolean) => void;

  get offices(): string {
    return `${
      this.$vuetify.breakpoint.smAndUp
        ? `${i18n.t("title.titleOfice1")}<br/>${i18n.t("title.titleOfice2")}`
        : i18n.t("title.titleOfice")
    }`;
  }
  get mainTitle(): object {
    return {
      title: i18n.t("title.homeTitle"),
      description: i18n.t("title.homeDescr"),
    };
  }
  get mapData(): object {
    return {
      title: i18n.t("map.mapTitle"),
      description: i18n.t("map.mapDescr"),
      representations: [
        // {
        //   imgSRC: "geramny.svg",
        //   city: i18n.t("map.mapCityGer"),
        //   coords: {
        //     left: "145px",
        //     top: "660px",
        //     smleft: "80px",
        //     smtop: "350px",
        //   },
        //   info: {
        //     manager: i18n.t("map.mapManager"),
        //     position: i18n.t("map.mapName"),
        //     phone: "+7 (495) 798 48 08",
        //     email: "tm@tecman.ru",
        //   },
        // },
        // {
        //   imgSRC: "kalinin.svg",
        //   city: i18n.t("map.mapCityKalg"),
        //   coords: {
        //     left: "242px",
        //     top: "612px",
        //     smleft: "135px",
        //     smtop: "320px",
        //   },
        //   info: {
        //     manager: i18n.t("map.mapManager"),
        //     position: i18n.t("map.mapName"),
        //     phone: "+7 (495) 798 48 08",
        //     email: "tm@tecman.ru",
        //   },
        // },
        // {
        //   imgSRC: "cheh.svg",
        //   city: i18n.t("map.mapCityCR"),
        //   coords: {
        //     left: "236px",
        //     top: "702px",
        //     smleft: "130px",
        //     smtop: "370px",
        //   },
        //   info: {
        //     manager: i18n.t("map.mapManager"),
        //     position: i18n.t("map.mapName"),
        //     phone: "+7 (495) 798 48 08",
        //     email: "tm@tecman.ru",
        //   },
        // },
        // {
        //   imgSRC: "ispany.svg",
        //   city: i18n.t("map.mapCitySpan"),
        //   coords: {
        //     left: "46px",
        //     top: "810px",
        //     smleft: "20px",
        //     smtop: "440px",
        //   },
        //   info: {
        //     manager: i18n.t("map.mapManager"),
        //     position: i18n.t("map.mapName"),
        //     phone: "+7 (495) 798 48 08",
        //     email: "tm@tecman.ru",
        //   },
        // },
        // {
        //   imgSRC: "ukraina.svg",
        //   city: i18n.t("map.mapCityUkr"),
        //   coords: {
        //     left: "370px",
        //     top: "690px",
        //     smleft: "200px",
        //     smtop: "370px",
        //   },
        //   info: {
        //     manager: i18n.t("map.mapManager"),
        //     position: i18n.t("map.mapName"),
        //     phone: "+7 (495) 798 48 08",
        //     email: "tm@tecman.ru",
        //   },
        // },
        {
          imgSRC: "moscow.svg",
          city: i18n.t("map.mapCityMos"),
          coords: {
            left: "420px",
            top: "595px",
            smleft: "225px",
            smtop: "315px",
          },
          info: {
            manager: i18n.t("map.mapManager"),
            position: i18n.t("map.mapName"),
            phone: "+7 (495) 798 48 08",
            email: "tm@tecman.ru",
          },
        },
        {
          imgSRC: "yarik.svg",
          city: i18n.t("map.mapCityYar"),
          coords: {
            left: "440px",
            top: "505px",
            smleft: "228px",
            smtop: "267px",
          },
          info: {
            manager: i18n.t("map.mapManager"),
            position: i18n.t("map.mapName"),
            phone: "+7 (495) 798 48 08",
            email: "tm@tecman.ru",
          },
        },
      ],
    };
    // public articles = [
    //   {
    //     tag: "#tecman #group",
    //     title: "Клиент: — Сколько стоит копия Facebook?",
    //     description: "«Сколько стоит сделать копию Facebook (Avito, Яндекс.Такси, fl.ru...)?» — один из самых популярных вопросов клиентов, на который мы сегодня дадим развернутый ответ и расскажем, как это выглядит со стороны людей, которым предстоит это делать.",
    //     alias: "article1"
    //   },
    //   {
    //     tag: "#tecman #group",
    //     title: "AMO, Битрикс, 1C и другие: как выбрать, с чего начать?",
    //     description: "Что такое CRM, как ее выбрать и с чего начать. Три совета руководителю/менеджеру по подготовке к автоматизации учета",
    //     alias: "/#"
    //   },
    //   {
    //     tag: "#tecman #group",
    //     title: "С чего начать разработку собственной CRM?",
    //     description: "Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений. Создаем единое информационное пространство в компании. Внедряем систему управления задачами и проектами, CRM, автоматизируем бизнес-процессы с помощью облачных и серверных решений.",
    //     alias: "/#"
    //   },
    // ];
  }
  public blog = [
    {
      imgSRC: "price.svg",
      title: "Почему так дорого?",
      description:
        "Energistically benchmark focused growth strategies via superior supply chains. Compellingly reintermediate mission-critical potentialities whereas cross functional scenarios. Phosfluorescently re-engineer distributed processes without standardized supply chains. Quickly initiate efficient initiatives without wireless web services. Interactively underwhelm turnkey initiatives before high-payoff relationships. Holisticly restore superior interfaces before flexible technology. Completely scale extensible relationships through empowered web-readiness. Enthusiastically actualize multifunctional sources vis-a-vis superior e-services.",
      path: "/article/1",
    },
    {
      imgSRC: "time.svg",
      title: "Почему так долго?",
      description:
        "Energistically benchmark focused growth strategies via. Phosfluorescently re-engineer distributed processes without standardized supply chains. Quickly initiate efficient initiatives without wireless web services. Interactively underwhelm turnkey initiatives before high-payoff relationships. Holisticly restore superior interfaces before flexible technology. Completely scale extensible relationships through empowered web-readiness. Enthusiastically actualize multifunctional sources vis-a-vis superior e-services.",
      path: "/article/2",
    },
  ];
  get advertising(): object {
    return [
      {
        imgSRC: "cup1.svg",
        title: i18n.t("advertising.advertisingOneTitle"),
        description: i18n.t("advertising.advertisingOneDescr"),
      },
      {
        imgSRC: "cup2.svg",
        title: i18n.t("advertising.advertisingTwoTitle"),
        description: i18n.t("advertising.advertisingTwoDescr"),
      },
      {
        imgSRC: "cup3.svg",
        title: i18n.t("advertising.advertisingThreeTitle"),
        description: i18n.t("advertising.advertisingThreeDescr"),
      },
    ];
  }
  get middleCards(): object {
    return [
      {
        imgSRC: "team.svg",
        title: `${i18n.t("advantages.advantageOneTitle1")}<br/>${i18n.t(
          "advantages.advantageOneTitle2"
        )}`,
        description: i18n.t("advantages.advantageOneDescr"),
      },
      {
        imgSRC: "qual.svg",
        title: `${i18n.t("advantages.advantageTwoTitle1")}<br/>${i18n.t(
          "advantages.advantageTwoTitle2"
        )}`,
        description: i18n.t("advantages.advantageTwoDescr"),
      },
      {
        imgSRC: "garanty.svg",
        title: `${i18n.t("advantages.advantageThreeTitle1")}<br/>${i18n.t(
          "advantages.advantageThreeTitle2"
        )}`,
        description: i18n.t("advantages.advantageThreeDescr"),
      },
    ];
  }
  get reviews(): object {
    return [
      {
        path: "/feedback/alfabank",
        rating: 5,
        txt: i18n.t("feedbacks.feedbackOneDescr"),
        user: {
          avatar: "",
          company: i18n.t("feedbacks.feedbackOneCompany"),
          username: i18n.t("feedbacks.feedbackOneName"),
        },
      },
      {
        path: "/feedback/alfabank",
        rating: 5,
        txt: i18n.t("feedbacks.feedbackTwoDescr"),
        user: {
          avatar: "",
          company: i18n.t("feedbacks.feedbackTwoCompany"),
          username: i18n.t("feedbacks.feedbackTwoName"),
        },
      },
      {
        path: "/feedback",
        totalReviews: 56,
      },
    ];
  }
  public logos = [
    { path: "legrand.svg", id: 1 },
    { path: "payme_logo.svg", id: 2 },
    { path: "gazprom.svg", id: 3 },
    { path: "basecam.svg", id: 4 },
    { path: "rompetrol.svg", id: 5 },
  ];
  get cards(): object {
    return [
      {
        path: "https://crm.tecman.ru/",
        img: "CRM.svg",
        title: `${i18n.t("cards.cardOneTitle1")}<br/>${i18n.t(
          "cards.cardOneTitle2"
        )}`,
        description: i18n.t("cards.cardOneDescr"),
        messages: "10",
        targets: "2",
      },
      {
        path: "https://mobile-dev.tecman.ru/",
        img: "mobile.svg",
        title: `${i18n.t("cards.cardTwoTitle1")}<br/>${i18n.t(
          "cards.cardTwoTitle2"
        )}`,
        description: i18n.t("cards.cardTwoDescr"),
        messages: "45",
        targets: "11",
      },
      {
        path: "http://support.tecman.ru/",
        img: "it.svg",
        title: `${i18n.t("cards.cardThreeTitle1")}<br/>${i18n.t(
          "cards.cardThreeTitle2"
        )}`,
        description: i18n.t("cards.cardThreeDescr"),

        messages: "32",
        targets: "19",
      },
    ];
  }
  get links(): object {
    return [
      { txt: i18n.t("links.web"), path: "/" },
      { txt: i18n.t("links.system"), path: "/" },
      { txt: i18n.t("links.boxed"), path: "/" },
      { txt: i18n.t("links.mfis"), path: "/" },
    ];
  }
  get tabs(): object {
    return {
      items: [
        {
          title: i18n.t("tabs.tabOneTitle"),
          path: "crm",
          content: [
            {
              imgSRC: "dengiMigom.svg",
              title: i18n.t("tabs.tabOneContentOneTitle"),
              description: i18n.t("tabs.tabOneContentOneDescr"),
              link: {
                txt: i18n.t("tabs.tabMore"),
                path: "/project/dengimigom",
                tags: [""],
              },
            },
            {
              imgSRC: "Avisto.svg",
              title: i18n.t("tabs.tabOneContentTwoTitle"),
              description: i18n.t("tabs.tabOneContentTwoDescr"),
              link: {
                txt: i18n.t("tabs.tabMore"),
                path: "/project/avisto",
                tags: [""],
              },
            },
          ],
        },
        {
          title: i18n.t("tabs.tabTwoTitle"),
          path: "mobile",
          content: [
            {
              imgSRC: "forSlider.svg",
              title: i18n.t("tabs.tabTwoContentOneTitle"),
              description: `<p>${i18n.t(
                "tabs.tabTwoContentOneDescr1"
              )}<p>${i18n.t("tabs.tabTwoContentOneDescr2")}</p>`,
              link: {
                txt: i18n.t("tabs.tabMore"),
                path: "/projects",
                tags: [""],
              },
            },
            {
              imgSRC: "Elsa.svg",
              title: i18n.t("tabs.tabTwoContentTwoTitle"),
              description: `<p>${i18n.t(
                "tabs.tabTwoContentTwoDescr1"
              )}<p>${i18n.t("tabs.tabTwoContentTwoDescr2")}</p>`,
              link: {
                txt: i18n.t("tabs.tabMore"),
                path: "/projects",
                tags: [""],
              },
            },
            {
              imgSRC: "payMe.svg",
              title: i18n.t("tabs.tabTwoContentThreeTitle"),
              description: i18n.t("tabs.tabTwoContentThreeDescr"),
              link: {
                txt: i18n.t("tabs.tabMore"),
                path: "/project/alfa",
                tags: [""],
              },
            },
          ],
        },
        // {
        //     title: "Разработка по МФО",
        //     path: "mfo",
        //     content: [
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //     ]
        // },
        // {
        //     title: "ПО под заказ",
        //     path: "fororder",
        //     content: [
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //     ]
        // },
        // {
        //     title: "Техническая поддержка",
        //     path: "support",
        //     content: [
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //         {
        //             imgSRC: "forSlider.svg",
        //             title: "Приложение для сотрудников крупнейшей в мире энергетической компании",
        //             description: "Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты. Приложение упрощает и ускоряет бизнес-процессы Россетей. Помогает контролерам фиксировать показания электросчетчиков и подготавливать отчеты.\n",
        //             link: {
        //                 txt: "кейсы по CRM/ERP системам",
        //                 path: ""
        //             }
        //         },
        //     ]
        // },
        {
          title: i18n.t("tabs.tabThreeTitle"),
          path: "solutions",
          content: [
            {
              imgSRC: "Leta.svg",
              title: i18n.t("tabs.tabThreeContentOneTitle"),
              description: i18n.t("tabs.tabThreeContentOneDescr"),
              link: {
                txt: i18n.t("tabs.tabMore"),
                path: "/projects",
                tags: [""],
              },
            },
          ],
        },
      ],
    };
  }
  private body = document.body;

  public cardClicked(path: string) {
    window.open(path);
    // this.$router.push(path)
  }

  public showModalFunc() {
    this.showModal(true);
    this.body.classList.add("noscroll");
  }
}

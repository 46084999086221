



































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Review } from "@/interfaces";
import Slider from "@/components/slider/Slider.vue";
import TecBtn from "@/components/tecBtn/TecBtn.vue";

@Component({
  components: { TecBtn, Slider }
})
export default class ReviewCard extends Vue {
  @Prop({ default: undefined }) readonly reviews?: Array<Review>;
  public onboarding = 0;
}

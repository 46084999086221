import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "@/api";
import { TecArticle, TagsInterface, ArticleType } from "@/interfaces";

const api = new API();
@Module({ namespaced: true })
class Articles extends VuexModule {
  public articles: TecArticle[] = [];
  public tags: TagsInterface[] = [];
  public artTypes: ArticleType[] = [
    { name: "Все статьи", count: this.articles.length, isActive: true },
    { name: "Гайд для клиентов", count: 34, isActive: false }
  ];

  @Mutation
  public setArticles(newValue: TecArticle[]): void {
    this.articles = newValue;
  }
  @Mutation
  public setTags(newValue: TagsInterface[]): void {
    this.tags = newValue;
  }
  @Mutation
  public setTypes(newValue: ArticleType[]): void {
    this.artTypes = newValue;
  }

  @Action
  public getArticles() {
    const filtered = this.tags.filter(item => item.isSelected);
    const list = filtered.map(e => e.name).join(",");
    // recording response to store
    const record = (resp: any) => {
      this.context.commit("setArticles", resp.items);
      if (filtered.length === 0) {
        const tagsIncaps: TagsInterface[] = [];
        resp.tags.forEach((tag: { name: string }) => {
          tagsIncaps.push({ name: tag.name, isSelected: false });
        });
        this.context.commit("setTags", tagsIncaps);
      }
    };
    //
    if (filtered.length > 0) {
      api.getFilteredArticles(list).then(resp => record(resp));
    } else {
      api.getAllArticles().then(resp => record(resp));
    }
  }

  @Action
  public updateTags(payload: TagsInterface[]) {
    this.context.commit("setTags", payload);
  }
  @Action
  public updateTypes(payload: ArticleType) {
    const arr = [...this.artTypes];
    arr.forEach(e => {
      e.isActive = e.name === payload.name;
    });
    this.context.commit("setTypes", arr);
  }

  get mainArticles(): TecArticle[][] {
    const toReturn = [];
    const current = [...this.articles]
      .sort((a, b) =>
        a.published && b.published
          ? parseInt(b.published) - parseInt(a.published)
          : 0
      )
      .slice(0, 9);
    while (current.length > 0) toReturn.push(current.splice(0, 3));
    return toReturn;
  }

  get ifArticlesExist(): boolean {
    return this.articles.length > 0;
  }
}

export default Articles;

import { Locales } from "./locales";

import en from "./en";
import ru from "./ru";

export const messages = {
  [Locales.EN]: en,
  [Locales.RU]: ru
};

export const defaultLocale = Locales.RU;

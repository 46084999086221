









































































































import { Component, Vue } from "vue-property-decorator";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import { namespace } from "vuex-class";
import { formInterface } from "@/interfaces";
import { i18n } from "@/main";

const SendingForm = namespace("FormStore");

const trigger = (el: HTMLElement, type: string) => {
  const e = document.createEvent("HTMLEvents");
  e.initEvent(type, true, true);
  el.dispatchEvent(e);
};

@Component({
  components: { TecBtn },
  directives: {
    phone: {
      bind(el) {
        el.oninput = function (e: Event) {
          if (!e.isTrusted) {
            return;
          }
          const inputEvent = e as InputEvent;
          const target = e.target as HTMLInputElement;
          if (inputEvent.inputType === "deleteContentBackward") {
            return;
          }
          const x = target.value
            .replace(/\D/g, "")
            .match(/(7)(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
          console.log(x);
          target.value =
            x !== null && x[1]
              ? "+" +
                x[1] +
                " (" +
                (x[2] ? x[2] + ") " : "") +
                x[3] +
                (x[4] ? " " + x[4] : "") +
                (x[5] ? " " + x[5] : "")
              : "";
          trigger(target, "input");
        };
      },
    },
  },
})
export default class Request extends Vue {
  get name(): string {
    return `${i18n.t("form.name")}`;
  }
  public loading = false;
  public sheet = false;
  public valid = true;
  get nameRules(): object {
    return [(v: string) => !!v || i18n.t("form.nameRule")];
  }
  get phoneRules(): object {
    return [
      (v: string) => !!v || i18n.t("form.phoneRule"),
      (v: string) =>
        /\+[0-9] \([0-9]{3}\) [0-9]{3} [0-9]{2} [0-9]{2}$/.test(v) ||
        i18n.t("form.phoneRule1"),
    ];
  }
  @SendingForm.State
  public formData!: formInterface;
  @SendingForm.State
  public isSendingSuccess!: boolean;
  @SendingForm.Action
  public sendFormData!: (newValue: formInterface) => void;
  @SendingForm.Action
  public updateFormData!: (newValue: formInterface) => void;

  public closeMenu() {
    this.$emit("closeModal");
  }
  public closeWindow() {
    this.sheet = !this.sheet;
    setTimeout(() => {
      this.closeMenu();
    }, 200);
  }
  public validate(): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  public sendRequest() {
    if (this.validate()) {
      this.loading = true;
      this.updateFormData(this.formData);
      this.sendFormData(this.formData);
      setTimeout(() => {
        this.sheet = true;
        this.loading = false;
        if (this.isSendingSuccess) this.updateFormData({ phone: "", name: "" });
      }, 2000);
    }
  }

  public lookAtPrivatePolicy() {
    this.$router.push("private");
    this.$emit("closeModal");
  }
}

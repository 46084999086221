










import TecAppBar from '@/components/tecAppBar/TecAppBar.vue';
import TecFooter from '@/components/tecFooter/TecFooter.vue';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { i18n } from '@/main';
const Locale = namespace('Locale');
import { Locales } from '@/i18n/locales';

@Component({
  components: { TecFooter, TecAppBar },
})
export default class App extends Vue {
  @Locale.State
  public lang!: Locales;
  mounted() {
    i18n.locale = this.lang;
  }
}

<template>
  <v-btn-toggle
    class="mr-4 pa-0 d-flex justify-center align-center btn-lang-group"
    v-model="selectedLang"
    group
    mandatory
  >
    <v-btn
      small
      class="pa-0 btn-lang"
      value="ru"
      active-class="btn-lang-active"
    >
      RU
    </v-btn>
    <span></span>
    <v-btn
      class="pa-0 btn-lang"
      active-class="btn-lang-active"
      small
      value="en"
    >
      EN
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.btn-lang-group {
  & span {
    background-color: #fff;
    height: 50%;
    width: 2px;
  }
  & .v-btn::before {
    display: none;
  }
  & .v-btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    text-decoration: underline;
  }
  & .btn-lang-active {
    background-color: transparent;
    color: #fff !important;
    font-weight: 700;
    text-decoration: none;
  }
}
</style>

import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { API } from "@/api";
import { MenuList } from "@/interfaces";
import { i18n } from "@/main";

const api = new API();
@Module({ namespaced: true })
class Menu extends VuexModule {
  public isMenuShow = false;
  public menuList: Array<MenuList> = [];

  @Mutation
  public setMenuShow(newValue: boolean): void {
    this.isMenuShow = newValue;
  }

  @Action
  public showMenu(switcher: boolean): void {
    this.context.commit("setMenuShow", switcher);
  }
}

export default Menu;

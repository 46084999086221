import Vue from "vue";
import Vuex from "vuex";
import SwitchHeader from "@/store/modules/SwitchHeader";
import Menu from "@/store/modules/menu";
import Modal from "@/store/modules/modal";
import Filters from "@/store/modules/filters";
import Projects from "@/store/modules/projects";
import Reviews from "@/store/modules/reviews";
import Articles from "@/store/modules/articles";
import FormStore from "@/store/modules/form";
import Locale from "@/store/modules/locale";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    SwitchHeader,
    Menu,
    Modal,
    Filters,
    Projects,
    Reviews,
    Articles,
    FormStore,
    Locale
  }
});

export default store;

export default {
  buttons: {
    consultation: "book a consultation",
    application: "submit an application",
    articlesAll: "all articles",
    tagAll: "all tags",
    feedback: "send feedback",
    feedbackView: "view the review",
    again: "Try again later",
    backHome: "Go back to the main page",
    backHome1: "to the main page",
    projectView: "view the project",
    readArticle: "read the article",
    more: "Download more",
    projectShow: "Show projects by filters",
    cancel: "cancel"
  },
  main: {
    filters: "filters",
    tags: "tags"
  },
  menu: {
    cityMoscow: "Moscow",
    addressMoscow: "Rubtsovskaya naberezhnaya, building 3с1",
    addressMoscow2: "floor 10, office 4А, Moscow",
    cityYaroslavl: "Yaroslavl",
    addressYaroslavl: "3B Pushkina st., Yaroslavl",
    addressYaroslavl1: "3B Pushkina st.",
    europa: "EUROPE/OTHER CITIES",
    cityAnd: "and:",
    Kaliningrad: "• Kaliningrad",
    Spain: "• Spain",
    CzechRepublic: "• Czech Republic",
    Germany: "• Germany",
    Ukraine: "• Ukraine",
    profile: "business profile",
    profileCrm: "crm/erp systems development",
    profileMobile: "mobile apps",
    profileSupport: "technical support",
    projects: "cases",
    feed: "Feedbacks",
    articles: "articles"
  },
  title: {
    homeTitle: "Software development",
    homeDescr:
      "We solve complex, research, highly specialized,  and knowledge-intensive tasks.",
    titleProfileB: "business",
    titleProfileP: "profile",
    titleCases: "Cases",
    titleOfice: "our offices",
    titleOfice1: "our",
    titleOfice2: "offices",
    titleFeedback1: "lots of feedbacks",
    titleFeedback2: "from our customers",
    titleAdvantages: "advantages",
    titleArticles: "articles",
    titleFeedbackClient: "feedback from our customers",
    portfolio: "Portfolio",
    feedback: "Feedbacks",
    feedTitle: "Leave your feedback about cooperation with us",
    development1: "description of",
    development2: "the development",
    development3: " process",
    client: "Client",
    goals: "Goals",
    issues: "Issues",
    projectDev: "Project development",
    resultDev: "development result",
    result: "Result",
    home: "Home",
    articles: "Articles"
  },
  map: {
    mapTitle: "Getting your project started: let’s get to know each other!",
    mapDescr:
      "43 employees in 2 offices (Moscow, Yaroslavl) and more than 100 proven contractors for rare languages and technologies",
    mapCityGer: "Germany",
    mapCityCR: "Czech Rep.",
    mapCitySpan: "Spain",
    mapCityUkr: "Ukraine",
    mapCityKalg: "Kaliningrad",
    mapCityMos: "Moscow",
    mapCityYar: "Yaroslavl",
    mapManager: "Sales Manager",
    mapName: "Alexander Larin",

  },
  cards: {
    cardOneTitle1: "CRM/ERP SYSTEMS",
    cardOneTitle2: "DEVELOPMENT",
    cardOneDescr:
      "We create a unified information space in the company. We implement the Task&Project Management System, CRM, we automate business processes using cloud and server solutions.",
    cardTwoTitle1: "MOBILE",
    cardTwoTitle2: "APPS",
    cardTwoDescr:
      "We develop mobile applications that will provide an additional way of keeping in touch with clients: a sales channel or an internal service for employees.",
    cardThreeTitle1: "TECHNICAL",
    cardThreeTitle2: "SUPPORT",
    cardThreeDescr:
      "We perform all kinds of tasks including  developing and maintaining accounting programs, servers or portals so that you can reduce the financial costs of maintaining the IT personnel."
  },
  links: {
    web: "web development",
    system: "corporate systems",
    boxed: "implementation of boxed software solutions",
    mfis: "solutions for mfis"
  },
  tabs: {
    tabMore: "more about the project",
    tabAllCases: "all cases",
    tabOneTitle: "crm and erp systems",
    tabOneContentOneTitle:
      'Business automation for Microfinance Company "DengiMigom"',
    tabOneContentOneDescr:
      "The online loan taking system we’ve launched allows taking out loans via the Internet with lower fees. Alongside with that, a new operational accounting system has been created to control business processes. This resulted in faster Management decisions, automated work with overdue debts and a more efficient penalty system.",
    tabOneContentTwoTitle:
      'Development of the CRM system for the accounting company "Avisto"',
    tabOneContentTwoDescr:
      "This project resulted in getting rid of such programs as mail and text messengers, electronic calendars and whiteboards, as well as office software packages.  A more functional   way of planning and setting internal tasks  has been developed. A  unified database of clients and tax authorities has been created to facilitate dealing with corporate  incoming and outgoing emails.",
    tabTwoTitle: "mobile development",
    tabTwoContentOneTitle:
      "Application for employees of the world's largest energy company",
    tabTwoContentOneDescr1:
      "This story is about how a timely developed application helps simplify the lives of tens of thousands of employees of the largest Russian energy company.",
    tabTwoContentOneDescr2:
      "The application simplifies and accelerates the business processes of 'Rosseti' company. It helps supervisors to record power meter readings  and prepare reports.",
    tabTwoContentTwoTitle: "Apartment building management system for a startup",
    tabTwoContentTwoDescr1:
      "In the ELSA project, we tackled  the main communication pain points  between the Apartment Management Company and the owners of the apartment buildings. The issue seemed impossible to solve at first   - human communication is way too complex to be facilitated  even when you have  a cool mobile application.",
    tabTwoContentTwoDescr2:
      "Nonetheless, we managed to make The interaction process  easier. For  example, the users  can now enter all meter readings and get a receipt on their  phone, open a discussion with the Apartment Management Company or add  a poll , as well as view the history of all the  changes and operations.",
    tabTwoContentThreeTitle: "Payment service for Beeline and Alfabank",
    tabTwoContentThreeDescr:
      "For better understanding the level of our responsibility for the project, let's us show what we’ve done: the development deadline was a public presentation of the application, which was attended by representatives of VISA, Beeline and Alfabank.",
    tabThreeTitle: "complex solutions",
    tabThreeContentOneTitle: "Electronic queue system for LETA Engineering",
    tabThreeContentOneDescr:
      "The result of the work was a ready-made system that can withstand enormous amounts of data, ensuring the operation of several offices in different cities with a large number of devices: an electronic queue for banks, 1C and a web interface for terminals, and a Smart-TV application. The possibility of access via mobile devices has been also created."
  },
  feedbacks: {
    more: "more",
    feedbacks: "feedbacks",
    feedbackOneDescr:
      "We appreciate the efficiency, the high level of services provided to us, as well as the reliability of the hardware and software complexes developed by you.",
    feedbackOneCompany: "ARMO-GROUP, JSC",
    feedbackOneName: "A.N. Gorbunov",
    feedbackTwoDescr:
      "The developers of Tecman, LLC managed to successfully complete the development and implement the systems all while meeting the three month deadline as it was initially planned.",
    feedbackTwoCompany: "MILMAX, LLC",
    feedbackTwoName: "I.E. Malinovsky"
  },
  advantages: {
    advantageOneTitle1: "dedicated",
    advantageOneTitle2: "team",
    advantageOneDescr:
      "The project team deals only with your project. We do not take parallel tasks.",
    advantageTwoTitle1: "qualification.",
    advantageTwoTitle2: "standards. people.",
    advantageTwoDescr:
      "All the specialists and experts involved in your project are certified.",
    advantageThreeTitle1: "warranty free of",
    advantageThreeTitle2: "limitation period",
    advantageThreeDescr:
      "We ensure quality at every stage of work and confirm this with a warranty."
  },
  advertising: {
    advertisingOneTitle: "1st place",
    advertisingOneDescr:
      "The number one in mobile development according to CMS Magazine and Ratingruneta.",
    advertisingTwoTitle: "1st place",
    advertisingTwoDescr:
      "We are among the hundred best software developers for the largest companies according to Ratingruneta.",
    advertisingThreeTitle: "in 10-TOP",
    advertisingThreeDescr:
      "We are among the top 30 developers of websites for industry according to Ratingruneta."
  },
  projects: {},
  footer: {
    tecman: "OOO Tecman",
    reserved: "All rights reserved",
    info: "Legal information",
    about: "About us",
    services: "Services",
    project: "Projects",
    feedback: 'Feedbacks',
    articles: 'Articles',
    titleOfice: "Our offices",
    stack: 'Tech stack',
    oficeMos:
      "Rubtsovskaya naberezhnaya, building 3с1, floor 10, office 4А, Moscow",
    oficeYar: "3B Pushkina st., Yaroslavl",
    logo: "Business Software Company",
    price: 'Price',
  },
  field: {
    name: "Your name",
    placeholder: "Write your review..."
  },
  chips: {
    satisfaction: "Satisfaction",
    professionalism: "Professionalism",
    deadlines: "Deadlines",
    quality: "Quality"
  },
  form: {
    title: "Leave your contact and",
    title1: "we recall you",
    subtitle: "Are you ready to start the project with a personal meeting?",
    subtitle1: "",
    name: "Surname, name",
    nameRule: "Enter your name",
    phoneRule1: "Number format 7 ### ### ## ##",
    phoneRule: "Start entering the number with the number 7",
    confid: "By clicking the button, you consent to the",
    link: "processing of personal data",
    button: "SUBMIT AN APPLICATION",
    wrong:
      "Something went wrong and your application was not sent, try to send it later",
    thanks:
      "Your review has been sent successfully, it will be added to the site after verification by a moderator. Thanks."
  },
  message: {
    errorMessage:
      "Something went wrong and your application was not sent, try to send it later",
    successMessage:
      "Thank you for your message, our specialist will contact you soon."
  },
  notFound: {
    backText: "or look at some of our cases while we solve the problem:",
    title: "It seems that<br/>something went wrong..."
  },
  dm: {
    projectType: "CRM SYSTEMS FOR MICROFINANCE ORGANIZATIONS",
    name: "Dengimigom",
    descr:
      "The development of the CRM system which provides high data processing speed, information transparency and efficiency of employees' work."
  },
  reviews: [
    {
      author:
        "<p>Konstantin,</p>" +
        "<p>Deputy Director for Development of the company “Dengimigom”</p>",
      createdAt: 1612171800,
      files: ["/temp/dengiMigomReview.png"],
      project: {
        preview: "/previews/dengimigom.png",
        projectType: "CRM/ERP",
        path: "деньгимигом",
        color: "#65BB2F",
        background: "white",

                selects: [
                    {
                        title: "Industry",
                        select: "Автомобильные сигнализации"
                    },
                    {
                        title: "Technology",
                        select: "Adobe Air"
                    },
                    {
                        title: "Solution",
                        select: "Мобильная разработка"
                    },
                    {
                        title: "Platform",
                        select: "iOS, Android"
                    }
                ],

                image: {
                    src: "/backgrounds/Dengimigom.svg",
                    coords: {
                        lg: {
                            top: "170px",
                            left: "700px"
                        },
                        md: {
                            top: "320px",
                            left: "350px"
                        },
                        sm: {
                            top: "180px",
                            left: "175px"
                        }
                    }
                },
                title: "New accounting system for dengimigom",
                name: "New accounting system for dengimigom",
                description:
                    "Разработка специализированного программного обеспечения и мобильного приложения для службы такси",
                summary:
                    "'Dengimigom is one of the leading microfinance companies, which combines the high speed of loan decision-making, the information transparency and prompt loan making to the borrower. We managed to develop a live automation system that requires minimal adjustments and easily solves most of the business related issues.'",
                fullDescription:
                    "<p>от клиента: Приложение упрощает и ускоряет бизнес-процессы</p>" +
                    "<p>Россетей. Помогает контролерам фиксировать показания</p>" +
                    "<p>электросчетчиков и подготавливать отчеты. Приложение упрощает</p>" +
                    "<p>и ускоряет бизнес-процессы Россетей.</p>",
                logo: "/logos/dengimigom.svg",
                goals:
                    "<p>Деньгимигом выбрала ТЕКМЭН в качестве делового партнера и поставила задачу – достигнуть целей, связанных с автоматизацией бизнес-процессов. Деньгимигом планирует максимально переходить в онлайн и сокращать количество микроофисов.</p>" +
                    "<p>В силу закона № 407-ФЗ, который предполагает существенные изменения в профильном законе 'О микрофинансовой деятельности и микрофинансовых организациях' рынок микрофинансирования кардинально изменится. В текущих условиях рынка требуется разработка принципиально новой системы. Она ускорит масштабирование бизнеса за счёт сбора онлайн-аудитории, упростит работу сотрудников на местах, улучшит контроль за работой в регионах, увеличит прозрачность и управляемость бизнеса для собственников.</p>",
                objectives:
                    "<p>После детального рассмотрения и анализа целей заказчика с разработчиками были сформированы следующие задачи:</p>" +
                    "<ol style='font-weight: bold'>" +
                    "<li class='mb-2'><p style='font-weight: normal'>Создание новой системы учета, позволяющей оперативно вносить изменения в бизнес-процессы, а также оптимизировать выдачу займов, работу с просроченной задолженностью, взаимодействие сотрудников по любым вопросам. Микрофинансовая деятельность - непростой процесс, а имеющаяся IT-система не отвечала растущим требованиям бизнеса.</p></li>" +
                    "<li><p style='font-weight: normal'>Внедрение разработанной нами системы и выход на рынок онлайн кредитования.</p></li>" +
                    "</ol>",
                slider: [
                    "/temp/dengimigom1.png",
                    "/temp/dengimigom2.png",
                    "/temp/dengimigom3.png",
                    "/temp/dengimigom4.png",
                    "/temp/dengimigom5.png"
                ],
                haveDone:
                    "<div class='col col-12 base-color'>" +
                    "<img style='margin-bottom: 200px' alt='dengimigiom_ill' src='/temp/dengimigom_ill.png' />" +
                    "<h2 style='margin-bottom: 30px'>Что было</h2>" +
                    "<p class='base-color'>Заказчик обратился к нам с такими исходными данными:</p>" +
                    "<p class='base-color'>– Старая система автоматизации была написана на Delphi 7 + MSSQL и позволяла удовлетворительно справляться с задачами Деньгимигом. Одни разработчики создавали программу в течение нескольких лет, а другие поддерживали и дорабатывали систему под нужды заказчика. За последнее время в МФК накопилось много задач, с которыми не мог справиться штатный программист. Используемые технологии устарели, и как следствие, на рынке труда резко снизилось количество специалистов по ним. </p>" +
                    "<p class='base-color'>Разработка новой системы гораздо проще, чем отладка ошибок у имеющейся. В текущих условиях требовалось решение следующих задач:</p>" +
                    "<ol class='bold-counter'>" +
                    "<li><p>Старая система учета нуждалась в постоянных обновлениях, поддержке и сопровождении. На каждом ПК была установлена программа «Деньги+». В каждом городе, где присутствовал МФК, был 1+ офис, а IT-отдел обслуживал до 400 рабочих станций и решал проблемы пользователей с программой.</p></li>" +
                    "<li><p>Система выдачи займов. Деньги предоставлялись только оффлайн, при личном визите заемщика в офис.</p></li>" +
                    "</ol>" +
                    "<h2 style='margin-top: 200px; margin-bottom: 30px'>Что сделали</h2>" +
                    "<p class='base-color'>Специалисты из ТЕКМЭН начали с аудита действующей системы учёта и бизнес-процессов, требующих оцифровки. По итогу была составлена пользовательская документация и краткое описание технической части.</p>" +
                    "<p class='base-color'>В процессе подготовки и разработки новой системы учёта был разработан список приоритетных направлений работы. На первом месте была работа с клиентской частью, которая важна для сотрудников и мидл-менеджеров." +
                    "<br>Первые результаты, в отличие от апгрейда базы данных и действующей системы, были заметны и понятны сразу. Они помогли быстро повысить лояльность со стороны руководства Деньгимигом.</p>" +
                    "<h5 style='margin-bottom: 30px'>Список работ по проекту за 2 года:</h5>" +
                    "<div class='text--body'>" +
                    "<ol class='ol--numbers ol-two-cols height--300'>" +
                    "<li><p>Поддержка и доработка существующей системы оперативного учета на базе Delphi 7 + MSSQL.</p></li>" +
                    "<li><p>Разработка системы выдачи займов через Интернет (Онлайн Займы) Vue.js + PHP 7 + Laravel 5.4 + NodeJS + MSSQL. </p></li>" +
                    "<li><p>Интеграция с сервисами: Equifax, Scorista, ФССП, ХLombard, MandarinBank, РНКО, смс-шлюзы. </p></li>" +
                    "<li><div>" +
                    "<p>Разработка новой системы оперативного учета. Vue.js + PHP7 + Laravel 5.4 + NodeJS + MSSQL:</p>" +
                    "<ul style='list-style-type: disc'>" +
                    "<li style='list-style-position: initial'><p>Модуль автоматизации выдачи займов.</p></li>" +
                    "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу юридических служб и служб взыскания задолженности.</p></li>" +
                    "<li style='list-style-position: initial'><p>Модуль документооборота компании.</p></li>" +
                    "<li style='list-style-position: initial'><p>Модуль, автоматизирующий работу единого контакт-центра компании.</p></li>" +
                    "<li style='list-style-position: initial'><p>Глобальное дополнение существующего функционала, которое позволяет выдавать займы на карты, перечислять займы и осуществлять прием платежей на сайте.</p></li>" +
                    "</ul>" +
                    "</div></li>" +
                    "</ol>" +
                    "</div>" +
                    "</div>",
                result:
                    "<p class='base-color'>Деньгимигом –  одна из ведущих микрофинансовых компаний, в которой сочетается высокая скорость принятия решения по займу, информационная прозрачность и оперативная выдача кредита заемщику. Нам удалось разработать живую систему автоматизации, которая требует минимальных поправок и с легкостью решает большинство рабочих задач.</p>" +
                    "<div class='row no-gutters mt-11'>" +
                    "<div class='col col-12 col-md-4 base-color px-5'><h5>Новая система учета</h5><p>Новая система учета – локальное решение на серверах компании, реализованное как WEB-интерфейс. Это разгрузило IT-отдел. Теперь он занимается решением локальных вопросов пользователей, возникающих во время работы с единой системой. </p></div>" +
                    "<div class='col col-12 col-md-4 base-color px-5'><h5>Полноценный личный кабинет</h5><p>На сайте Деньгимигом теперь есть полноценный личный кабинет. Клиент может оформить, получить и погасить займ всего за 15 минут. В ближайших планах компании – максимальное сокращение оффлайн отделений и увеличение объемов онлайн-займов. </p></div>" +
                    "<div class='col col-12 col-md-4 base-color px-5'><h5>Увеличение интернет-аудитории</h5><p>Сейчас микрофинансовая компания выдает займы сразу на банковскую карту. Количество офисов уменьшается с каждым днем, а это многократно сокращает издержки и расширяет географию клиентской базы за счёт интернет-аудитории. В ТЕКМЭН удалось разработать и внедрить скидочную систему, которая мотивирует клиентов брать займы на свою карту, не выходя из дома.</p></div>" +
                    "</div>",
                published: true
            },
            rates: [
                {
                    rate: 5,
                    title: "Satisfaction"
                },
                {
                    rate: 5,
                    title: "Professionalism"
                },
                {
                    rate: 5,
                    title: "Deadlines"
                },
                {
                    rate: 5,
                    title: "Quality"
                }
            ],
            tags: [
                { name: "desktop", isSelected: false },
                { name: "МФО", isSelected: false },
                { name: "хэштег", isSelected: false },
                { name: "разработка", isSelected: false }
            ],
            text:
                "“Dengimigom is one of the leading microfinance companies, which combines the high speed of loan decision-making, the information transparency and prompt loan making to the borrower. We managed to develop a live automation system that requires minimal adjustments and easily solves most of the business related issues.”",
            link: "Деньгимигом"
        }
    ],
    articles: [
        {
            title: "Client: — HOW MUCH DOES it cost to create a facebook equivalent?",
            text:
                "<p>«Сколько стоит сделать копию Facebook (Avito, Яндекс.Такси, fl.ru...)?» — один из самых популярных вопросов   клиентов, на который мы сегодня дадим развернутый ответ и расскажем, как это выглядит со стороны людей, которым предстоит это делать.</p>" +
                "<h2>«Черный ящик»</h2>" +
                "<p>Когда нам дают задачу скопировать какой-то сервис, то для нас он представляет собой некий «черный ящик». Абсолютно неважно, что это за программа: сайт, мобильное приложение или драйвер. В любом случае, мы сможем увидеть, как он выглядит снаружи, но у нас нет доступа к тому, что находится внутри.</p>" +
                "<p>Это примерно, как если бы нам показали автомобиль и попросили сделать точную копию, но не дали возможности заглянуть под капот: мы можем ограничиться лишь внешним осмотром и посидеть за рулем. А залезть в багажник — уже нельзя!</p>" +
                "<p>Соответственно, мы будем вынуждены решать следующие задачи:<br>" +
                "Предположить и придумать — а как же внутри устроен этот «автомобиль», у которого мы видим только кузов?</p>" +
                "<p>Представить, из каких деталей он состоит. Для понимания: любой современный автомобиль — это примерно 18 000 деталей…</p>" +
                "<p>Прикинуть, какие специалисты нужны для создания этих 18 000 деталей и сколько времени займет создание каждой.</p>" +
                "<p>В разработке программного обеспечения — похожий процесс: систему, которую мы создаем, нужно разбить на кучу мелких составляющих. Придумать, как и кем их создать, и как они будут взаимодействовать друг с другом. Именно поэтому «просто скопировать» — непростая и объемная задача.</p>" +
                "<h2>«Верхушка айсберга»</h2>" +
                "<p>Avito, Facebook, Яндекс.Такси… Если бы клиент знал изнутри бизнес, на который ссылается, то он бы обнаружил, что в нем трудятся десятки, а то и сотни программистов, которые создавали сервис в течение нескольких лет.</p>" +
                "<p>Оплачены тысячи часов специалистов, которые ушли на производство продукта.</p>" +
                "<p>Считая, “сколько стоит скопировать Facebook” мы увидим все результаты их труда. И, когда мы сделаем список этих результатов, клиент всегда обнаруживает, что видел, от силы, 10% “Facebook”.</p>" +
                "<p>Остальные 90% ему становятся видны лишь после того, как мы проделали довольно большую работу. Вы ведь не видите двигатель, рулевые рейки, топливопровод, когда садитесь за руль автомобиля?</p>" +
                "<p>Что будет потом?</p>" +
                "<p>Клиент понимает, что 90% возможностей сервиса ему вообще не нужны. Это трудозатраты, которые не дадут ему никакой пользы. Тысячи человеко-часов, потраченные на возможности, которые ему никогда не пригодятся. Дорогие и бесполезные.</p>" +
                "<h2>«Скопируйте дочку соседа, да подешевле!»</h2>" +
                "<p>Почему вообще клиент приходит с таким запросом? Ему кажется, что раз эта работа уже проделана, то нет ничего проще, чем взять и скопировать. Экономит кучу денег!</p>" +
                "<p>Но есть небольшая проблема — мы ничего не можем взять из Facebook, потому что:</p>" +
                "<ol>" +
                "<li>У нас (и ни у одного другого подрядчика) нет доступа к исходному коду. Да даже если бы и был — он собственность другой компании.</li>" +
                "<li>У нас нет исходников дизайна, то есть дизайн тоже нужно будет пересоздавать.</li>" +
                "<li>У нас нет знаний об архитектуре продукта. Мы можем только предположить, как он устроен внутри. Даже если прочтем кучу статей на хабре — там будет лишь приблизительное описание.</li>" +
                "</ol>" +
                "<p>Увы, запрос “сделайте как у соседа” — не делает работу дешевле :(</p>" +
                "<h2>«Дайте мне кочергу!»</h2>" +
                "<p>Программный продукт — не самоцель: с его помощью клиент хочет решить свою бизнес-задачу. Например, заработать или сэкономить денег, захватить аудиторию, создать удобный инструмент для сотрудников.</p>" +
                "<p>Только вот парадокс: клиент не приходит к нам с вопросом о бизнес-задаче. Он приходит с вопросом о техническом решении. То есть, с запросом типа «мне нужна кочерга». А для чего она ему? Может, он собирается дрова колоть, а там топор нужен?</p>" +
                "<p>Клиент не является специалистом по решениям (обычно он решает такую задачу первый раз в жизни), но, увидев кочергу, ему кажется, что ВОТ ОНА, волшебная палочка!</p>" +
                "<p>Но, когда мы зададим вопрос «а какую бизнес-задачу вы решаете?» и подумаем, какое решение было бы действительно оптимальным, окажется, что оно не имеет вообще никакого отношения ни к Facebook, ни к кочерге. Ну, вот вообще ничего общего.</p>" +
                "<h2>Резюме</h2>" +
                "<p>По всему выходит, что запрос «сколько стоит копия…?» — бессмысленный. Для того, чтобы ответить на него буквально, нужно проделать колоссальную работу, которая никак не пригодится ни нам, ни клиенту. Почему так уверены? Да мы делали эту работу, много раз =)</p>" +
                "<p>Что же делать? Имеем мнение — писать техническое задание.</p>" +
                "<p>Любой нормальный читатель на этом месте подумал “вы говорите это потому, что хотите нам продать!!!”</p>" +
                "<p>И да, и нет. Попробуйте найти хорошего строителя, который начнет строительство дома без проектно-сметной документации. Или автомеханика, создающего автомобиль без чертежей. Или опытного предпринимателя, создающего новый бизнес без финмодели.</p>" +
                "<p>Даже если мы делаем программу для себя — мы начнем с техзадания. Нам так же, как и вам, не хочется тратить на это “лишние” деньги. Но мы знаем, что без этого не обойтись. Иначе небоскреб — завалится, бизнес будет больше забирать, чем приносить, а с автомобилем — неизвестно, кто на ком будет ездить.</p>" +
                "<p>У данной статьи лишь одна цель: избежать бесполезной работы, и делать полезную работу для вас. Поговорим, зачем вам “кочерга”?</p>",
            image: "/backgrounds/creditCard.png",
            alias: "klient-ckolko-stoit-kopija-facebook",
            preview: "/backgrounds/creditCard.png",
            description:
                "«How much does it cost to make a copy of Facebook (Avito, Yandex.Taxi, fl.ru...)?» is one of the most popular customer questions to which we will give a detailed answer today and tell you what  it looks like from the side of the experts who have to deal with it.",
            tags: [{ name: "frequent_questions", isSelected: false }],
            published: "1st of February"
        },
        {
            title: "Amo, bitrix, 1c and others: how to choose and where to start?",
            text:
                "<p>Вы ― собственник компании. Вы поняли, что пора посмотреть на свой бизнес в цифрах. Найти узкие места, утечки денег и информации, все упорядочить и посчитать.</p>" +
                "<p>Или же вы ― миддл-менеджер компании, и наконец-то донесли до Биг Босса, что учет и автоматизация бизнеса ― не только круто, а жизненно необходимо.</p>" +
                "<p>И вот вы идете в IT-компанию, чтобы специалисты помогли вам внедрить учетную систему. Идете и боитесь: «А вдруг мне зарядят сейчас 100500 мильонов денег, а я даже не буду понимать ― за что? А вдруг меня разведут, как на техосмотре, впарят вместо плановой замены масла и тормозной жидкости новые колодки и перебор двигателя?»</p>" +
                "<p>Специально чтобы собственники и миддл-менеджеры понимали, чего ждать, мы начнем с азов — расскажем, из чего исходят IT-компании, когда предлагают разные учетные решения.</p>" +
                "<h2>Три уровня задач для учетных систем</h2>" +
                "<p>Основные задачи, которые решают системы учета для бизнеса, делятся на три уровня. Каждый последующий уровень более продвинут по отношению к предыдущему.</p>" +
                "<p><strong>Уровень 1</strong>. Собрать все данные в одну большую кучу, создав единое информационное пространство. Чаще всего эта задача решается без учета удобства для пользователей. Сотрудники компаний плюются, ругаются матом, и кое-как осваивают новые функции ― хотя и считают их ненужными и излишними. Но все-таки осваивают, и единое информационное пространство начинает наполняться смыслом и цифрами.</p>" +
                "<p><strong>Уровень 2.</strong> Задача второго уровня ― сделать учетную систему «с человеческим лицом», дружественным интерфейсом и понятным функционалом. С такой системой должно быть легко работать каждому пользователю. Эффект от решения задач второго уровня может быть разным ― от уменьшения трудозатрат и количества персонала, до начала работы с задачами, к которым в принципе невозможно было подступиться до автоматизации.</p>" +
                "<p><strong>Уровень 3.</strong> На этом уровне начинают решаться задачи собственника бизнеса — повышение прозрачности и управляемости бизнеса. Системы учета помогают формировать срезы данных, на основе которых собственник или руководитель может принимать конкретные управленческие решения. Для этого в цифровом выражении должны быть представлены затраты и польза от множества конкретных операций. Это поможет увидеть утечки, неэффективное расходование средств или воровство, либо наоборот — выявить наиболее эффективные направления, и усилить их.</p>" +
                "<p>Специалисты, работающие с учетными системами, ориентируются на ваши задачи. Если вам нужно просто единое информационное пространство, ответственный профи не будет впаривать крутые инструменты, позволяющие принимать управленческие решения. Потому, что это дольше, дороже, сложнее, и скорее всего вы не сможете этим пользоваться — это требует квалификации. Если вы хотите получить удобный инструмент, например, для «осуществления быстрых продаж», а управленческие решения ― не ваша епархия, специалисты это тоже учтут.</p>" +
                "<p><strong>Промежуточный итог:</strong> система учета в бизнесе решает три основные задачи.</p>" +
                "<ol>" +
                "<li>Создание единого информационного пространства.</li>" +
                "<li>Выявление узких мест, точек роста.</li>" +
                "<li>Помощь в принятии управленческих решений.</li>" +
                "</ol>" +
                "<p><br>" +
                "<strong>Совет первый:</strong> посмотрите вокруг, трезво содрогнитесь (с) и определите те задачи, которые должна решить система учета. Как изменится ваша компания в результате того, что будет внедрена система? И если вы — не опытный пользователь учетных систем, возможно, вам стоит такого найти и спросить — «А что вообще может дать система? Покажи свою?»</p>" +
                "<h2>Три товарища</h2>" +
                "<p>Представим, что живут в одном городе три товарища ― Митя, Геннадий и Олег.</p>" +
                "<p>Митя замутил бизнес по продаже надувных шариков и оформлению праздников и мероприятий. Геннадий открыл службу такси, а Олег начал выдавать страждущим микрозаймы.</p>" +
                "<p>Митя ведет учет по-простому, в Google Docs и частично в Excel, а кое-что вообще считает на пальцах (профи иногда называют это системой учета из дерьма и палок). Когда (и если) объемы его продаж возрастут, такое ведение учета может стать проблематичным.</p>" +
                "<p>Геннадий выделил те зоны бизнеса, где важнее всего автоматизация, и купил коробочное решение, специально разработанное для сферы такси. В нем ограниченный набор модулей ― рабочее место водителя, рабочее место диспетчера и мобильное приложение для пользователей. Для налогового и кадрового учета Геннадий использует традиционные продукты 1С. Такой набор хорошо подходит именно для такси, но если Геннадий займется грузоперевозками, то эту сову на глобус ему уже не натянуть ― придется искать другие инструменты.</p>" +
                "<p>Работа хваткого Олега требует жесточайшего учета и контроля. Если он будет плохо управлять финансовыми потоками ― его бизнес скончается так же громко и быстро, как «Древпром», «Слон-финанс» и «Домашние деньги». Олег встраивал в свой бизнес несколько готовых систем учета, но быстро перерос коробочные решения, и задумался о чем-то более тонко настроенном, менее типовом ― о системе, на 100% соответствующей именно его специфике.</p>" +
                "<p>Ответа на вопрос «Вот в этих трех бизнесах — какое из решений правильное, идеальное?» у меня нет. Все варианты имеют право на существование, все они работают и помогают решать бизнес-задачи их владельцев. Разница между ними лишь в стоимости и количестве комфорта для пользователей. Поэтому когда три соседа-бизнесмена придут к IT-разработчикам за инструментами для ведения учета, им предложат разные варианты решений ― и это будет правильно.</p>" +
                "<p><strong>Совет второй:</strong> Подготовьте для IT-спецов информацию о сфере деятельности компании; о том, кто будет пользоваться системой и как; о том, как вы ведете учет сейчас; о том, что не нравится сейчас, и что захотите от системы в будущем.</p>" +
                "<p><a href='https://docs.google.com/document/d/1FPzjISVajiv_wMr1i7eHRXYq1YxqrDcgQp7kvo3Ukj0/edit'>Вот бриф</a>, который я отправляю заказчикам. С одной стороны, он облегчит поиск общего языка с IT-специалистами, которые будут разрабатывать продукт, с другой — поможет вам лучше понимать назначение будущей системы.</p>" +
                "<h2>Деньги и ограничения «сверху» и «снизу»</h2>" +
                "<p>Два ключевых параметра, которые скажутся на выборе учетных систем для компании ― бюджет и компетенции лица, принимающего решение.</p>" +
                "<p>Сегодня на рынке найдется куча инструментов, которые помогут наладить учет, от условно-бесплатных, до стоящих несколько миллионов рублей в месяц. Чем больше ваш бюджет на разработку/внедрение учетной системы для бизнеса, тем большее количество решений вам доступно. Но и бюджет, и количество подходящих вариантов зависят не только от наличия у вашей компании денег ― еще и от вашего понимания задач, которые должна решать ваша система.</p>" +
                "<p>Если отчасти наивному в бизнесе Мите предложить разработку навороченной, крутой, суперудобной и обсчитывающей вообще все бизнес-процессы учетной системы, он не согласится. Во-первых, у него нет на это денег. А во-вторых, если деньги у него есть, то все равно ему это не нужно ― система «из дерьма и палок» на его уровне вполне работает! И ему предложат ― что? ― да просто доработку и улучшение этой работающей системы. Это можно назвать ограничением «сверху»: есть решения, которые для Мити слишком хороши, явно too much.</p>" +
                "<p>Если Геннадий не собирается, помимо такси, запускать каршеринг или грузоперевозки — ему вряд ли понадобится разработка индивидуальной системы учета. Ему нужна помощь в подборе более удобных, современных и информативных коробочных решений, созданных специально для его сферы бизнеса. И, конечно, в их внедрении и интеграции между собой. Именно это хорошие специалисты ему и предложат.</p>" +
                "<p>Но когда к IT-разработчикам придет Олег, ситуация будет принципиально другой. Олег бизнесмен прошаренный, он отлично понимает, что учет и контроль ― добрая половина успеха его микрозаймового дела. Без отслеживания и оценки эффективности всех бизнес-процессов он работу своей компании не представляет. Ему нужна разработка системы учета с нуля и конкретно под его нужды. Поэтому часть решений, которые ему точно по карману, Олег отметет, как недостаточно гибкие и информативные ― и это ограничение количества вариантов учетных решений «снизу».</p>" +
                "<p><strong>Совет третий</strong>: Определитесь с тем, какую сумму, исходя из финансовой ситуации и своего понимания бизнес-задач, вы готовы потратить на инструменты учета. Часто именно с обсуждения бюджета начинают общение с вами опытные IT-специалисты (они знают десятки решений вашей задачи, и выбирают из тех, которые вам по карману).</p>" +
                "<h2>Зачем так заморачиваться? Excel — самое крутое решение!</h2>" +
                "<p>Имею мнение:<strong> автоматизация и учетные системы в бизнесе ― не просто мода</strong>. Это необходимость и возможность для бизнеса выйти на новый уровень.</p>" +
                "<p>Приведу пример: я знаком с собственником компании X, которая занималась интернет-торговлей детскими товарами. Всего за 4 года работы эта компания стала лидером рынка. Как им это удалось? Они изначально сделали очень амбициозное заявление: «Доставим ваш заказ в любую точку России за 24 часа». За сутки в любую точку огромной страны ― это очень круто! И требует большой точности и эффективности каждого бизнес-процесса. Отладив, стандартизировав и автоматизировав в своей работе все, что можно, они справились ― и, как следствие, вышли в топ-3 своей отрасли.</p>" +
                "<p>Есть и сотни других интернет-магазинов детских товаров ― которые вполне довольны своей долей рынка и своей прибылью: они вкладывают в учет и контроль бизнес-процессов минимум, получая такое же минимальное органическое развитие.</p>" +
                "<p>Могут ли «другие» функционировать и приносить прибыль без навороченной системы, разработанной компанией X? Конечно да! Но могут ли стать топовой, пользуясь Excel? Полагаю, вы сами знаете ответ.</p>" +
                "<h2>Подведем итоги</h2>" +
                "<p><strong>Чтобы понимать, что и почему предлагают вам IT- специалисты,</strong> воспользуйтесь нашими тремя советами. Определитесь со своими задачами, подумайте о том, куда и как вы будете расти, и решите, какие суммы вы готовы вкладывать в развитие ваших личных систем учета.</p>" +
                "<h2>С чего начать?</h2>" +
                "<p>У опытных бизнес-аналитиков есть поговорка: автоматизация хаоса порождает автоматизированный хаос. Если вы задаетесь вопросом «с чего начать» ― возможно, эта поговорка про вас. В таких случаях первым этапом часто становится постановка управленческого учета. Это больше про процедуры и процессы, нежели про программирование. Делает эту работу бизнес-аналитик либо финдиректор, она помогает собственнику понять, какие задачи в принципе могут решаться системой учета. И даже увидеть ее возможности работы до начала программирования и вложений в приобретение системы. Попробуйте ― и, возможно, следующим в топ-3 мы увидим вас. Удачи!</p>" +
                "<div id='gtx-trans' style='position: absolute; left: -26px; top: 2128.72px;'>" +
                "<div class='gtx-trans-icon'>&nbsp;</div>",
            image: "/backgrounds/creditCard.png",
            alias: "amo-bitriks-1c-i-drugie-kak-vybrat-s-chego-nachat",
            preview: "/backgrounds/articleB.png",
            description:
                "<p>  You are the owner of a company. You realized that it's time to look at your business in numbers. Find bottlenecks, leaks of money and information, organize and calculate everything.</p>" +
                "<p>Or you might be a middle manager of the company, and your big boss finally gets the idea that accounting and business automation are not only cool but vital.</p>",
            tags: [
                { name: "frequent_questions", isSelected: false },
                { name: "development of crm systems", isSelected: false },
                { name: "automation", isSelected: false }
            ],
            published: "1st of February"
        },
        {
            title: "How to switch  from excel to crm?",
            text:
                "<p>Практически любой бизнес начинается с Excel. В нем ведется учет клиентов, финансов и всего, на что хватит фантазии. По мере роста бизнеса данных становится больше, и наступает полная неразбериха: информация путается, теряется, используется неэффективно. Из этого следует, что Excel в условиях современных реалий стоит применять как вспомогательный инструмент в работе, но не как полноценную систему учета для бизнеса. В такой ситуации стоит задуматься о переходе на CRM. Ниже мы расскажем, какие проблемы могут возникать, когда в компании отсутствует эффективная система учета и о том, как осуществить переход к CRM.</p> " +
                "<h2>CRM — оперативность взаимодействия с клиентами</h2>" +
                "<p>Случается, что менеджеры забывают сделать повторный звонок клиенту, проинформировать о новой услуге или напомнить постоянному покупателю о давно забытой бонусной карте. Стикеры, приклеенные на монитор, не спасут. Отсутствие оперативного взаимодействия с клиентами неминуемо приведет к ухудшению взаимоотношений с ними, что равняется потере прибыли для компании. Решением этой проблемы становится внедрение CRM-системы. Она возьмет на себя работу по отправке уведомлений сотруднику, предоставит исчерпывающую информацию о клиентах, чтобы ни один из них не остался в стороне. Определиться с оптимальным вариантом системы учета для вашего бизнеса поможет консультация бизнес-аналитика.</p>" +
                "<h2>Единая база данных</h2>" +
                "<p>Большой проблемой является разрозненность информации и отсутствие единого поля для взаимодействия между сотрудниками. Это влечет за собой искажение данных, их потерю и даже кражу. CRM-система представляет из себя единую базу данных: сотрудники используют в работе актуальную и однозначную для всех информацию, а строгое разграничение прав доступа обеспечивает ее безопасность. CRM повышает прозрачность работы сотрудников, позволяет контролировать ситуацию на каждом этапе взаимодействия с клиентом, дает возможность быстрого реагирования на возникающие проблемы для устранения последних или предотвращения развития негативных последствий для бизнеса.</p>" +
                "<h2>Комплексный инструмент для автоматизации</h2>" +
                "<p>“Зоопарк ПО” - возможно, вы слышали это выражение, которое означает, что в компании используется большое количество разного программного обеспечения под определенные задачи. Это приводит к увеличению издержек в компании: ПО поглощает ресурсы и стоит немалых денег. CRM помогает решить эту проблему и являет собой комплексный инструмент для автоматизации учета. Консультация бизнес-аналитика поможет сформулировать требования к будущей CRM на предмет соответствия вашим бизнес-процессам.</p>" +
                "<h2>CRM для аналитики</h2>" +
                "<p>Сбор аналитических данных о работе компании - важная составляющая для руководителя. Статистика позволяет оценить эффективность принятой стратегии развития бизнеса и спланировать дальнейшие шаги. В Excel можно столкнуться с трудностью: ценное время уходит на сбор, обработку и анализ информации. В условиях отсутствия единой информационной базы, результат может оказаться ошибочным и неактуальным. CRM-система возьмет на себя работу над рутинными задачами, существенно снизит влияние человеческого фактора и риск возникновения ошибки. С легкостью сформирует отчет в нужном разрезе и периоде времени на основе имеющихся данных. Таким образом, руководитель сможет в режиме онлайн увидеть и оценить текущее состояние дел в компании.</p> " +
                "<h2>7 шагов к переходу на CRM</h2>" +
                "<h3>Вы уже сталкивались с проблемами, описанными выше? Тогда переходим на CRM.</h3>" +
                "<div class='step'>01.</div>" +
                "<h3>Начните с себя</h3>" +
                "<p>Определите, какие задачи хотите решить с помощью CRM. В качестве советчиков можно привлечь менеджеров, они хорошо знакомы с тонкостями работы. Составьте список, сделайте его подробным наглядным, например, в виде ментальной карты или таблицы; расставьте приоритеты.</p>" +
                "<h4>Примерами таких задач могут быть:</h4>" +
                "<ul>" +
                "<li>обеспечение прозрачности работы сотрудников;</li>" +
                "<li>повышение эффективности труда;</li>" +
                "<li>автоматизация рутинных операций;</li>" +
                "<li>минимизация влияния человеческого фактора;</li>" +
                "<li>создание единой базы данных;</li>" +
                "<li>интеграция с сервисами;</li>" +
                "<li>автоматическое создание отчетов;</li>" +
                "<li>планирование встреч, звонков;</li>" +
                "<li>контроль продаж на каждом этапе;</li>" +
                "<li>оптимизация затрат на маркетинг.</li>" +
                "<li>продуманный список в дальнейшем поможет вам определиться, какого типа CRM вам нужна для решения сформулированных задач.</li>" +
                "</ul>" +
                "<div class='step'>02.</div>" +
                "<h3>Определение требований к CRM</h3>" +
                "<p>В процессе подготовки к выбору CRM нужно учитывать, для решения какого типа задач она вам потребуется. C распространением CRM и развитием ее внутренних инструментов, стало возможным использовать систему под разные цели и задачи.</p>" +
                "<h4>Мы выделили 3 основных типа систем учета по их назначению:</h4>" +
                "<ul>" +
                "<li>операционная CRM — самая распространенная разновидность, которая призвана упростить, автоматизировать процесс взаимодействия с клиентом;</li>" +
                "<li>аналитическая CRM представляет собой инструмент для анализа накопленной информации и планирования стратегии развития;</li>" +
                "<li>коллаборационная CRM (англ. collaboration — сотрудничество) существует для совместной работы компании и клиента.</li>" +
                "</ul>" +
                "<div class='step'>03.</div>" +
                "<h3>Варианты решений</h3>" +
                "<p>По степени готовности к внедрению CRM системы бывают трёх типов:</p>" +
                "<ul>" +
                "<li>SaaS-сервисы (представляют собой программное обеспечение, которым сотрудник пользуется через web-браузер или мобильное приложение; провайдер предоставляет вам ПО в аренду);</li>" +
                "<li>коробочные решения (универсальная система со стандартным набором функций); </li>" +
                "<li>индивидуально разработанные CRM (решение, полностью разработанное под ваш бизнес).</li>" +
                "</ul>" +
                "<div class='step'>04.</div>" +
                "<h3>Пробный период</h3>" +
                "<p>Практически для всех готовых решений производители предоставляют бесплатный тестовый период в целях знакомства с системой. Конечно, такая версия далеко не всегда сможет показать весь спектр возможностей CRM, но представление составить можно. Не пренебрегайте этой возможностью: это отличный вариант опробовать решение и лучше понять, что вам нужно от работы с ней.</p>" +
                "<div class='step'>05.</div>" +
                "<h3>Выбор CRM</h3>" +
                "<h4>Резюмируем:</h4>" +
                "<ul>" +
                "<li>Saas-сервис может быть интересен малому бизнесу со стандартными бизнес-процессами, если в будущем не планируются доработки.</li>" +
                "<li>“Коробочный” вариант CRM может подойти малому и среднему бизнесу с наиболее стандартным набором бизнес-процессов.</li>" +
                "<li>собственная CRM-система подойдет среднему и крупному бизнесу, поскольку является масштабируемым, готовым к высоким нагрузкам, решением; подойдет компаниям со сложными, нетипичными, уникальными бизнес-процессами. Такая CRM может использоваться для продажи другим компаниям.</li>" +
                "</ul>" +
                "<p>Мы советуем обращаться за разработкой CRM в компании, у которых есть опыт в реализации таких проектов. Вы будете иметь дело со специалистами, знающими свое дело, и хорошим сервисом. К слову, нашей компанией реализована CRM для парка высотных развлечений SkyPark, бухгалтерской компании “Avisto”, CRM для микрофинансовой организации “ДеньгиМигом”.</p>" +
                "<div class='step'>06.</div>" +
                "<h3>Перенос данных</h3>" +
                "<p>Вы приобрели CRM, но на этом процесс не заканчивается: стоит подумать о переносе данных в CRM. Для начала, определитесь:</p>" +
                "<ul>" +
                "<li>нужны ли старые данные в новой системе? Полностью или частично?</li>" +
                "<li>каким образом будет осуществляться их перенос: вручную (здесь возможны ошибки, потеря данных), с помощью специалиста или с помощью поставщика выбранной CRM (если такая возможность существует).</li>" +
                "</ul>" +
                "<p>Мы советуем обращаться к поставщику CRM: возможно, в новой системе присутствуют встроенные механизмы переноса данных. В таком случае вы не потеряете ценные данные и сэкономите кучу времени и нервов :)</br>" +
                "В случае с индивидуально разработанной CRM, миграция данных происходит плавно, по мере внедрения модулей в работу, под контролем специалистов.</p>" +
                "<div class='step'>07.</div>" +
                "<h3>Обучение сотрудников</h3>" +
                "<p>Обучение сотрудников для работы в CRM – очень важный шаг, от которого зависит успех внедрения. Для этого вы должны:</p>" +
                "<ul>" +
                "<li>быть заинтересованным. Руководитель — вот кто, в первую очередь, должен быть заинтересован в том, чтобы CRM-система работала эффективно и деньги не были потрачены впустую, быть нацеленным на результат и верить во внедряемый продукт. Сотрудники это чувствуют;</li>" +
                "<li>правильно донести суть CRM. Рассказать, для чего она нужна, как может упростить работу, как поможет достичь больших успехов в работе каждого сотрудника, и какие плоды это принесет компании в будущем;</li>" +
                "<li>подготовить обучающие материалы: это может быть текстовый файл-инструкция или видео; по окончании обучения можно провести тестирование на понимание того, как пользоваться системой;</li> " +
                "<li>сформировать экспертную группу сотрудников, которые впоследствии будут заниматься обучением внутри компании;</li>" +
                "<li>создать базу знаний — полезный обучающий ресурс внутри CRM; пополняйте ее новыми материалами и находками, которые помогут пользователям эффективно работать с CRM, а также быстро адаптироваться новичкам.</li>" +
                "</ul>" +
                "<h2>CRM-система – важный инструмент управления бизнесом</h2>" +
                "<p>Это помощник, который упростит работу сотрудников, сделает ее прозрачной и повысит эффективность. Во время консультации с бизнес-аналитиком вы узнаете, сколько времени и средств потребуется для внедрения CRM. Средняя стоимость разработки индивидуальной CRM-системы начинается от 400 000 руб. Решив внедрить CRM в работу, вы обрекаете себя на возможность увеличить продажи, повысить лояльность клиентов и продуктивно управлять компанией.</p>",
            image: "/backgrounds/creditCard.png",
            alias: "kak-s-excel-peresest-na-crm",
            preview: "/backgrounds/creditCard.png",
            description:
                "Almost any business starts with Excel. The latter helps to keep track of clients and  finances, literally managing any aspect of the project you can think of. As the business grows, there is more data, and that’s when things can get really messy: information may get disorganized, lost, or  may be used inefficiently. Therefore, given the current realitiest Excel should be used as an auxiliary tool, not as a full-fledged accounting system for business.",
            tags: [
                { name: "frequent_questions", isSelected: false },
                { name: "automation", isSelected: false }
            ],
            published: "1st of February"
        }
    ],
    cases: [
        {
            preview: "/previews/skypark.png",
            projectType: "CRM/ERP",
            name: "Online ticket sales system for skypark (sochi)",
            path: "skypark",
            color: "#F12F04",
            background: "white",
            tags: [
                {
                    name: "разработка",
                    isSelected: false
                }
            ],
            selects: [
                {
                    title: "Industry",
                    select: "entertainments"
                },
                {
                    title: "Technology",
                    select: "React JS, Yii2, MySQL, JAVA, PHP"
                },
                {
                    title: "Solution",
                    select: "ticket sales service"
                },
                {
                    title: "Platform",
                    select: "Web"
                }
            ],
            image: {
                src: "/backgrounds/skypark_big_web.png",
                coords: {
                    lg: {
                        top: "320px",
                        left: "700px"
                    },
                    md: {
                        top: "344px",
                        left: "395px"
                    },
                    sm: {
                        top: "180px",
                        left: "175px"
                    }
                }
            },
            title: "Online ticket sales system for skypark (sochi)",
            description:
                "Development of the CRM system for a high-altitude amusement park",
            summary:
                "<p>Skypark, LLC is an amusement park for active and family holidays. It is located in the Adler district of Sochi on a picturesque section of the Akhshtyr gorge on the way from Adler to Krasnaya Pollyanna. Opened in 2014, it specializes in high-altitude rides.</p>",
            logo: "/logos/skypark_logo.svg",
            fullDescription:
                "<p>Skypark is a unique, beautiful and interesting place. Our CRM system automates the process of selling and booking tickets, accelerates and simplifies it for the buyers.</p>",
            goals:
                "<p>The main goal of the project was to automate the process of selling and booking tickets, accelerate it, and make it convenient for the buyers. Consequently, employees’ workload was reduced, there were fewer errors related to human factors, and the schedule was optimized.</p>",
            objectives:
                "<p>To achieve this goal, we needed a comprehensive CRM system that automates ticket distribution through the online store and other channels (cashiers, agents, distributors, amusement instructors), and would also allow users to register visitors in the park itself.</p>" +
                "<p>Additional system requirements:</p>" +
                "<ol class='bold-counter'>" +
                "<li><p>Integration with the customer's 1C, with SkiData turnstiles, Atoll tills, a payment system (Sberbank+Atoll including sending fiscal checks to the mail, databases of the Unified State Register of Legal Entities)</p></li>" +
                "<li><p>Optional template processing engine for reports and contracts</p></li>" +
                "<li><p>Optional manual and automatic adjustment of discounts and loyalty programs</p></li>" +
                "<li><p>Scalability (the ability to add other functional modules, connect new users)</p></li>" +
                "</ol>",
            developmentProcess: null,
            haveDone:
                "<div class='text--body base-color'><h2>Background</h2><p>The system is being developed from scratch.Project (development of the technical specification) launched March 2017.</p>" +
                "<h2>WHAT WE DO</h2>" +
                "<p style='margin-bottom: 30px'>We have developed the modular CRM system for Skypark including the following:</p>" +
                "<ol style='margin-bottom: 100px' class='ol--numbers ol-two-cols'>" +
                '<li><p>The "Client" module. This is a convenient modern online store for selling entrance tickets to the park, tickets for rides, transfers, additional services.</p></li>' +
                '<li><p>The "Self- registration" module. With its help, visitors register themselves upon arrival at the park (this is a mandatory procedure for security purposes).</p></li>' +
                '<li><p>The "Cashier" module. For selling tickets at various sites (in the park itself, in offices, etc.)</p></li>' +
                '<li><p>The "Terminal" module. For instructors of high-altitude rides.</p></li>' +
                '<li><p>The "Agent" module. For ticket agents and distributors.</p></li>' +
                '<li><p>The "Administrator" module. Allows to manage all the components of the system.</p></li>' +
                "</ol>" +
                "<p>Our CRM system does not require users to install additional software. To get started, you only need a Web browser and an individual login and password, which is equally convenient to use  from a PC as well as from mobile devices with any operating system. The interface is simple and intuitive, so the park staff quickly got used to it. New employees do not need much time to get involved in work either.</p>" +
                "<p>Since the very first weeks of using the system even with the limited functionality it’s become obvious that due to automation, the processes of selling and booking tickets was significantly simplified, accelerated and became more convenient for both the buyers and the employees.</p>" +
                "<p>We paid special attention to the design and usability of the client part of the system which was the online store, as this is one of the hallmarks of Skypark. We tried to make the store's interface concise and elegant in design, but at the same time containing all the important information for a buyer on the ticket price, its validity period, discounts, etc.</p>" +
                "<p>In addition to its main function — the automation of the park’s sales process — the system provides excellent opportunities for the data collection and analysis that can be used when preparing business plans, reports, presentations. Its functionality can be further expanded with the help of other specialized modules.</p>" +
                "<p>The implementation is carried out on a step-by-step basis. The modules of the system are relatively autonomous, so they can be put into operation as soon as they are ready, without waiting for the development of the entire set of tools.</p>" +
                "<p style=' margin-top: 100px'><b>The technologies used during the development, configuration and administration of the system:</b></p>" +
                "<ol style='margin-top: 60px; max-width: 60%' class='ol--numbers ol-tree-cols'>" +
                "<li><p>PL – PHP 7.1, node.js</p></li>" +
                "<li><p>Framework – Yii2, React Native</p></li>" +
                "<li><p>DBMS – MySQL</p></li>" +
                "</ol>" +
                "<h2>Project features</h2>" +
                "<p>The project for Skypark, LLC is in the stage of step-by-step implementation. As of November 2018, the main modules of the system — “Administration”, “Client” and “Cashier” are working under normal conditions. Most of the tickets and services of the park are sold through them. The remaining modules are being prepared for launching. The technical support of the system is provided by our specialists and a full-time Administrator on the customer`s side.</p>" +
                "</div>",
            slider: [
                "/temp/skypark1.png",
                "/temp/skypark2.png",
                "/temp/skypark3.png",
                "/temp/skypark4.png",
                "/temp/skypark5.png"
            ],
            result:
                "<p>The finalized system simplifies and speeds up the work of employees and makes the process of buying tickets fast and convenient for visitors.</p>",
            published: true
        },
        {
            preview: "/previews/avisto.png",
            projectType: "CRM/ERP",
            path: "avisto",
            color: "#63AEFC",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "accounting services"
                },
                {
                    title: "Technology",
                    select: "CodeIgniter, PHP, HTML5, CSS3, Postgresql "
                },
                {
                    title: "Solution",
                    select: "crm system"
                },
                {
                    title: "Platform",
                    select: "web"
                }
            ],
            image: {
                src: "/backgrounds/avisto_view_big_web.png",
                coords: {
                    lg: {
                        top: "230px",
                        left: "600px"
                    },
                    md: {
                        top: "320px",
                        left: "360px"
                    },
                    sm: {
                        top: "180px",
                        left: "175px"
                    }
                }
            },
            title: "CRM system for accounting services avisto",
            name: "CRM system for accounting services avisto",
            description:
                "It includes the development of a CRM system that combines many services and directories to reduce the time spent on tax and accounting processes.",
            summary:
                "The main activity of the consulting company 'Avisto' is accounting and tax outsourcing.Avisto is approached by legal entities wishing to reduce the costs and the time spent on  maintaining their own accounting department.",
            fullDescription:
                "<p>The elaborated system allows to monitor employees’ activity, circulate  questionnaires in a convenient form, plan events and tasks It also includes a database of clients and tax authorities, as well as an internal messaging and mail system.</p>",
            logo: "/logos/avisto-eng.svg",
            goals:
                "<ol style='font-weight: bold'>" +
                "<li class='mb-2'><p style='font-weight: normal'>Automation of the company's business processes.</p></li>" +
                "<li><p style='font-weight: normal'>The subsequent launch of the product to be used by third-party companies on the commercial basis.</p></li>" +
                "</ol>",
            objectives:
                "<p>The most important issues in the development of the project were:</p>" +
                "<ol style='font-weight: bold'>" +
                "<li class='mb-2'><p style='font-weight: normal'>Replacement of such programs as mail and text messengers, electronic calendars and whiteboards, as well as office software packages.</p></li>" +
                "<li><p style='font-weight: normal'>Development of functionality for planning cases and setting internal tasks .</p></li>" +
                "<li><p style='font-weight: normal'>Creation of the unified database of clients and tax authorities.</p></li>" +
                "<li><p style='font-weight: normal'>Working with corporate mail, incoming and outgoing emails.</p></li>" +
                "<li><p style='font-weight: normal'>Conducting surveys and questionnaires for employees.</p></li>" +
                "<li><p style='font-weight: normal'>Automation of services cost calculation and creation of commercial offers.</p></li>" +
                "<li><p style='font-weight: normal'>Monitoring employees’ activity.</p></li>" +
                "</ol>",
            developmentProcess: [
                {
                    imgSRC: "design.svg",
                    title: "Design ",
                    txt:
                        "Using flat design popular in recent years, we implemented a dashboard that displays up-to-date information to the employee: assigned duties and tasks, events, surveys, as well as mailbox updates."
                },
                {
                    imgSRC: "mail.svg",
                    title: "Mail",
                    txt:
                        "One of the challenges was to create a unique mail client that can combine both customer's corporate mail and the functionality of the most popular mail services. By connecting any mailbox to CRM, we provided the customer with the opportunity to communicate with their customers and tax authorities through the needed account, choosing a ready-made signature for the mail. The functionality of the spelling checker is as efficient as that of mail clients from Google, Yandex, etc."
                },
                {
                    imgSRC: "lead.svg",
                    title: "For Department Heads",
                    txt:
                        "The ability to control employees was implemented, namely – viewing all incoming and outgoing emails of all mail accounts connected to the CRM system."
                },
                {
                    imgSRC: "chat.svg",
                    title: "Chat",
                    txt:
                        "Also, to replace text messengers, an internal chat was introduced. It is accessible to employees from any screen of the CRM system. The chat functionality is similar to the popular VK social network: the ability to create private or shared conversations, rename them, a global chat for all employees, as well as pop-up messages in the browser."
                },
                {
                    imgSRC: "addressbook.svg",
                    title: "Customer Address Book",
                    txt:
                        "Customer Address Book. In conjunction with the mail client a client database accessible to all employees was launched. The address book provides the ability to both manually add contacts and automatically analyse the mailbox and then save new contacts. The functionality of automatic mailings to user groups specified in advance was also laid down."
                },
                {
                    imgSRC: "reports.svg",
                    title: "Reports",
                    txt:
                        "To automate the work of the accounting department, schedules and summary tables were developed to control financial turnover and the budget as a whole, mailings and internal activities of the company's employees, as well as a convenient vacation calendar with the possibility of coordinating annual leave."
                }
            ],
            slider: ["/temp/1.png", "/temp/2.png"],
            result: [
                "Business Process Management Console (dashboard)",
                "Mail client for corporate mail",
                "Control of employees and their mailboxes",
                "Internal chat for the company employees",
                "Address book for all company clients",
                "Charts and summary tables for financial turnover control",
                "The online library with the possibility of setting up electronic and paper publications for familiarization with further control of familiarization",
                "Shared storage of usernames and passwords",
                "The module of budget planning and work for existing clients",
                "Mail control via API “Russian Post” with printing envelopes option, and much more",
                "At this stage, the project has been fully implemented, put into operation and transferred to the monthly technical support mode"
            ],
            published: true
        },
        {
            preview: "/previews/dengimigom.png",
            projectType: "CRM/ERP",
            path: "dengimigom",
            color: "#65BB2F",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "credits, loans, mfi, mfc"
                },
                {
                    title: "Technology",
                    select: "Delphi7, MSSQL, Vue.js, PHP, Laravel, NodeJS"
                },
                {
                    title: "Solution",
                    select: "ts, audit, integration, etc"
                },
                {
                    title: "Platform",
                    select: "Web, API"
                }
            ],
            image: {
                src: "/backgrounds/dengimigom_big_web.png",
                coords: {
                    lg: {
                        top: "170px",
                        left: "740px"
                    },
                    md: {
                        top: "320px",
                        left: "420px"
                    },
                    sm: {
                        top: "230px",
                        left: "175px"
                    }
                }
            },
            title: "A new accounting system for dengimigom",
            name: "A new accounting system for dengimigom",
            description:
                "The development of the CRM system which provides high data processing speed, information transparency and efficiency of employees' work.",
            summary:
                "Dengimigom is a Russian microfinance company (MFC) that makes quick and short-term loans to individuals. Launched in 2010, today Dengimigom has 200+ branches in 100 urban centres of the Russian Federation, and the number of employees has reached 800 people.",
            fullDescription:
                "<p>“Dengimigom is one of the leading microfinance companies, which combines the high speed of loan confirmation, information transparency and prompt loan making to their borrowers. We managed to develop a live automation system that requires minimal adjustments and easily solves most of the business-related issues.”</p>",
            logo: "/logos/dengimigom.svg",
            goals:
                "<p>Dengimigom chose TECMAN as a business partner and set us goals related to the automation of business processes. Dengimigom plans to go online as much as possible and reduce the number of smaller offline branches .</p>" +
                "<p>As a result of Law No. 407-FZ, which includes significant changes to the relevant law 'On Microfinance Activities and Microfinance Organizations', the microfinance market is expected to change dramatically. In the current market conditions, the development of a fundamentally new system is required. It will accelerate business scaling by gaining traction online, all while simplifying employees’ work in the field, improving control over the work in the regions and increasing  business transparency and manageability for the owners.</p>",
            objectives:
                "<p>After a detailed customer's goals review and analysis , the following tasks  were assigned to developers:</p>" +
                "<ol style='font-weight: bold'>" +
                "<li class='mb-2'><p style='font-weight: normal'>Creation of a new accounting system that allows making quick changes to business processes, as well as facilitation of loan granting, dealing  with overdue debts, employee interaction on any issues. The microfinance activity is not an easy process, and the existing IT system did not meet the growing business requirements.</p></li>" +
                "<li><p style='font-weight: normal'>Implementation of the system developed by us and online loan market penetration</p></li>" +
                "</ol>",
            quotesdm:
                "<p>TECMAN specialists worked closely with the IT and Training departments. They shared the initial information with the personnel.<br>" +
                "This made it possible to speed up the implementation of the new system into existing business processes.<br>" +
                "The Training Department, in turn, replicated the accumulated experience, preparing special courses, instructions and video reviews for employees.</p>",
            problems:
                "<div class='col col-12 base-color'>" +
                "<h3 style='margin-bottom: 30px'>Problems  solved</h3>" +
                "<h4 style='margin-bottom: 30px'>CLIENT'S DOUBTS</h4>" +
                "<p style='margin-bottom: 30px'>The customer had different kinds of experience working with contractors, and, therefore, they were choosing new contractors very carefully. To establish fruitful cooperation, TECMAN specialists follow the rules listed below:</p>" +
                "<ul style='font-weight: bold; margin-bottom: 30px'>" +
                "<li class='mb-2'><p style='font-weight: 500'>strict compliance with deadlines and TS;</p></li>" +
                "<li class='mb-2'><p style='font-weight: 500'>high-quality performance of each stage of work;</p></li>" +
                "<li class='mb-2'><p style='font-weight: 500'> demonstration of interim results and reporting;</p></li>" +
                "<li class='mb-2'><p style='font-weight: 500'>organizing negotiations with the customer if necessary.</p></li>" +
                "</ul>" +
                "<p style='margin-bottom: 30px'>Most of the work has been done remotely. Once a month, the Project Manager makes an appointment, goes to the client and solves all the issues.</p>" +
                "<h4 style='margin-bottom: 30px'>lack of source data</h4>" +
                "<p style='margin-bottom: 30px'>The system in which the work was carried out at that time was transferred to TECMAN. They had no technical documentation, and this complicated the work of our programmers. They had to 'sort through' the code to make any changes and further support the product.</p>" +
                "<p style='margin-bottom: 30px'>No matter how good a full-time programmer is, it is difficult for him to take into account all the aspects both on the client's and the server's sides. Problems may arise in any system, and it doesn't matter how many people worked on its creation and optimization.</p>" +
                "<p style='margin-bottom: 30px'>A team of programmers specializing in various aspects and technologies is more effective than one specialist. It adheres to the uniform quality standards and rules of the system functioning, acts strictly according to the regulations, and the work is accepted taking into account the quality control. As without a robust infrastructure even a full-time programmer can turn the source code into a tangle of contradictions.</p>" +
                "</div>",
            wdone:
                "<div class='col col-12 base-color'>" +
                "<h3 style='margin-bottom: 30px'>The result of work</h3>" +
                "<ol style='font-weight: bold; margin-bottom: 30px'>" +
                "<li class='mb-2'><p style='font-weight: 500'>TECMAN achieved the priority goal of Dengimigom and quickly solved the priority tasks for the business growth. A personalized approach is used and special attention is given to the comments and recommendations of the customer. When Demonstrating intermediate results, we try to improve the quality to the maximum. TECMAN is a contractor that works strictly according to the Technical Specification, and any adjustments to the current MFC automation system are now being made by our technical support.</p></li>" +
                "<li class='mb-2'><p style='font-weight: 500'>The system of issuing online loans is working. At present, clients do not have to go to the branch office, it is enough just to open the website of Dengimigom. Once the loan has been confirmed, the money will be credited to the bank card. They can be used for any purpose.</p></li>" +
                "<li class='mb-2'><p style='font-weight: 500'>The old accounting system and the 'Money+' program are no longer used. At present, the work is performed on a new online product that is constantly changing and improving. New options are being added to it. Some modules are transferred from solving strategic tasks to current ones, and then implemented into the system.</p></li>" +
                "</ol>" +
                "</div>",
            slider: [
                "/temp/dengimigom1.png",
                "/temp/dengimigom2.png",
                "/temp/dengimigom3.png",
                "/temp/dengimigom4.png"
            ],
            haveDone:
                "<div class='col col-12 base-color'>" +
                "<img style='margin-bottom: 200px; max-width: 100%' alt='dengimigiom_ill' src='/temp/dengimigom_ill.png' />" +
                "<h2 style='margin-bottom: 30px'>Project details</h2>" +
                "<p class='base-color'>That’s what customer’s background looked like when getting the project started:</p>" +
                "<p class='base-color'>– The old automation system was written in Delphi 7 + MSSQL and made it possible to decently cope with the issues of Dengimigom. Some developers had been creating the program for several years, while others had maintained and refined the system to meet customers' needs. Recently, the MFC had accumulated a lot of tasks that a full-time programmer could not cope with. The technologies used had become outdated, and as a result, there weren’t enough professionals with required skills on the labour market.</p>" +
                "<p class='base-color'>Developing a new system is much easier than debugging errors from an existing one. Given the situation, we needed to solve the following issues:</p>" +
                "<ol class='bold-counter'>" +
                "<li><p>The old accounting system needed constant updates, support and maintenance. The program 'Money+' was installed on each PC in each city where the MFC was present where there was 1+ office, and the IT department serviced up to 400 workstations and solved user problems with the program.</p></li>" +
                "<li><p>The loan granting system. The money was provided only offline, with a personal visit of the borrower to the office.</p></li>" +
                "</ol>" +
                "<h2 style='margin-top: 200px; margin-bottom: 30px'>The result of work</h2>" +
                "<p class='base-color'>The TECMAN specialists started with an audit of the current accounting system and business processes that require digitization. As a result, the user documentation and a brief description of the technical part were compiled.</p>" +
                "<p class='base-color'>While preparing and developing a new accounting system, a list of priority areas was elaborated. Working with the client side became the first priority, which is importnt for employees and middle managers." +
                "<br> The first results, unlike the upgrade of the database and the current system, were immediately noticeable and understandable. They helped to quickly increase loyalty on the part of Dengimigom Management..</p>" +
                "<h5 style='margin-bottom: 30px'>list of things done within  2 years:</h5>" +
                "<div class='text--body'>" +
                "<ol class='ol--numbers ol-two-cols height--300'>" +
                "<li><p>Support and refinement of the existing operational accounting system based on Delphi 7 + MSSQL.</p></li>" +
                "<li><p>Development of a system for issuing loans via the Internet (Online Loans) Vue.js + PHP 7 + Laravel 5.4 + NodeJS + MSSQL.</p></li>" +
                "<li><p>Integration with the following services: Equifax, Scorista, FSSP, XLombard, MandarinBank, RNCO, and the SMS gateways. </p></li>" +
                "<li><div>" +
                "<p style='margin-bottom: 18px'>Development of a new operational accounting system. Vue.js + PHP7 + Laravel 5.4 + NodeJS + MSSQL:</p>" +
                "<ul style='list-style-type: disc; margin-top: 0'>" +
                "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>The module for automating the issuance of loans.</p></li>" +
                "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>The module that automates the work of legal services and debt collection services.</p></li>" +
                "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>The company's document management module.</p></li>" +
                "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>The module that automates the operation of the company's unified contact center.</p></li>" +
                "<li style='list-style-position: initial; margin-bottom: 0'><p style='margin-bottom: 8px'>A global addition to the existing functionality that allows you to issue loans to cards, transfer loans and payments on the site.</p></li>" +
                "</ul>" +
                "</div></li>" +
                "</ol>" +
                "</div>" +
                "</div>",
            result:
                "<p class='base-color'>'Dengimigom is one of the leading microfinance companies, which combines the high speed of loan confirmation, information transparency and prompt loan making to the borrower. We managed to develop a live automation system that requires minimal adjustments and easily solves most of the business related issues.'</p>" +
                "<div class='row no-gutters mt-11'>" +
                "<div class='col col-12 col-md-4 base-color px-5'><div class='numbers'>01.</div><h5>NEW ACCOUNTING SYSTEM</h5><p>The new accounting system is a local solution on the company's servers, implemented as a WEB interface. This relieved the IT department. Now they are engaged in solving local user issues that arise while working with the unified system.</p></div>" +
                "<div class='col col-12 col-md-4 base-color px-5'><div class='numbers'>02.</div><h5>FULL-FLEDGED PERSONAL ACCOUNT</h5><p>There is now a full-fledged personal account on the Dengimigom website. The client can issue, receive and repay the loan in just 15 minutes. In the near future, the company plans to reduce offline branches as much as possible and increase the volume of online loans.</p></div>" +
                "<div class='col col-12 col-md-4 base-color px-5'><div class='numbers'>03.</div><h5>INCREASING THE INTERNET AUDIENCE</h5><p>At present, the microfinance company issues loans directly to a bank card. The number of offices is decreasing every day, and this significantly reduces costs and expands the geography of the customer base due to the Internet audience. TECMAN managed to develop and implement a discount system that motivates customers to take loans transferred to  their banking card without leaving home.</p></div>" +
                "</div>",
            published: true
        },
        {
            preview: "/previews/hamstersoft.png",
            projectType: "Windows program",
            path: "hamster-soft",
            color: "#E59C29",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "software development"
                },
                {
                    title: "Technology",
                    select: "NET. C/C++, QT"
                },
                {
                    title: "Solution",
                    select: "pc software"
                },
                {
                    title: "Platform",
                    select: "windows"
                }
            ],
            image: {
                src: "/backgrounds/hamster_big_web.png",
                coords: {
                    lg: {
                        top: "170px",
                        left: "700px"
                    },
                    md: {
                        top: "400px",
                        left: "450px"
                    },
                    sm: {
                        top: "300px",
                        left: "175px"
                    }
                }
            },
            title: "Development of 10 finished products for hamstersoft",
            name: "Hamstersoft",
            description:
                "To date, the total volume of users of all applications developed by us for Hamstersoft is more than 20 million people, the programs created by us are launched daily by more than 1 million people.",
            summary:
                "<p>Since coming to the market in 2010, HamsterSoft has been skyrocketing. The HamsterSoft business is thriving on creating completely free software products. Free distribution, easy management, functionality and beautiful product design are the company's mission. </p>" +
                "<p>In 2010, HamsterSoft announced a competition among contractors, and we were the only team which could withstand the required quality and tight deadlines.</p>",
            fullDescription:
                "<p>Today, tens of millions of users around the world can use programs translated into more than 40 languages freely available.</p>",
            logo: "/logos/hamstersoftLogo.png",
            goals:
                "<p>We paid particular attention to the detailed study of usability. The  interfaces of all programs had to be simple and intuitive, so that all tasks could be solved with a minimum number of actions. </p>" +
                "<p>All this had to be combined with an updated, bright, memorable design.</p>",
            objectives:
                "<p>We were able to achieve high performance of each application while adapting programs to different devices.</p>" +
                "<p>Thanks to our effort, hamstersoft became an official partner of intel in 2010.</p>",
            slider: [
                "/temp/hamster1.png",
                "/temp/hamster2.png",
                "/temp/hamster3.png",
                "/temp/hamster4.png",
                "/temp/hamster5.png",
                "/temp/hamster6.png",
                "/temp/hamster7.png",
                "/temp/hamster8.png"
            ],
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>The result</h2>" +
                "<div class='row no-gutters'>" +
                "<div class='col col-12 col-md-6 pr-md-10 base-color'>" +
                "<h5 class='mb-6' style='text-transform: none;'>For several years of productive work with HamsterSoft, we have developed and put into operation the following software products:</h5>" +
                "<ul>" +
                "<li>PDF Reader</li>" +
                "<li>ZIP Archiver + Mac Archiver</li>" +
                "<li>Video Converter</li>" +
                "<li>Audio Converter</li>" +
                "<li>eBook Converter</li>" +
                "<li>YouTube Loader</li>" +
                "<li>Burner Studio</li>" +
                "<li>Installer</li>" +
                "<li>Agent</li>" +
                "<li> Chromium-based browser</li>" +
                "</ul>" +
                "</div>" +
                "<div class='col col-12 col-md-6 pl-md-10 base-color'>" +
                "<h5 class='mb-6' style='text-transform: none;'>All applications were united by common principles:</h5>" +
                "<ul>" +
                "<li>Usability ;</li>" +
                "<li>Intuitive interface;</li>" +
                "<li>Portability;</li>" +
                "<li>Nothing but  tools necessary for the job;</li>" +
                "<li>Bright design;</li>" +
                "<li>The possibility  to change the colour scheme of the program;</li>" +
                "<li>Auto-updated program;</li>" +
                "<li>Converting multiple files at a time;</li>" +
                "<li>Compact mode for convenient operation;</li>" +
                "<li>Performing the most popular operations;</li>" +
                "<li>Using open-source libraries.</li>" +
                "</ul>" +
                "</div>" +
                "</div>" +
                "</div>",
            result:
                "<p>Work on these projects has been continuously implemented for 5 years, during this time we have been developing, improving and expanding the entire range of HamsterSoft software products.To date, the total number of users of all applications developed by us for HamsterSoft is more than 20 million people, the programs created by us are launched daily by more than 1 million people..</p>",
            published: true
        },
        {
            preview: "/previews/asap.png",
            projectType: "CRM/ERP",
            name: "ASAP",
            description: "Crowdfunding platform in Kazakhstan",
            path: "asap",
            color: "#A8BA74",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "crowdfunding"
                },
                {
                    title: "Technology",
                    select: "PHP, HTML5, CSS3, JQuery"
                },
                {
                    title: "Solution",
                    select: "payment systems, website"
                },
                {
                    title: "Platform",
                    select: "Web"
                }
            ],
            image: {
                src: "/backgrounds/asap_ipad_big_web.png",
                coords: {
                    lg: {
                        top: "300px",
                        left: "470px"
                    },
                    md: {
                        top: "260px",
                        left: "350px"
                    },
                    sm: {
                        top: "160px",
                        left: "150px"
                    }
                }
            },
            title: "Development of the asap crowdfunding platform",
            summary:
                "<p>ASAP is a crowdfunding platform in the Republic of Kazakhstan. It is similar in functionality to boomstarter.ru or kickstarter.com.</p>" +
                "<p>The main idea of the platform is to attract investment for new or already established businesses in Kazakhstan as well as to make the investment process systematic and safe for both parties..</p>",
            fullDescription:
                "<p>The main idea of the platform is to attract investment for new or already established businesses in Kazakhstan as well as to make the investment process systematic and safe for both parties.</p>",
            logo: "/logos/platforma_ASAP.svg",
            goals: null,
            objectives: null,
            slider: ["/temp/asap1.svg"],
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Features of the project implementation:</h2>" +
                "<ul class='base-color'>" +
                "<li>several opportunities combined on one site: charity, crowdfunding, lending/crediting and equity.</li>" +
                "<li>user accounts are protected  not only by using a phone number and email , but also by checking the documents of individuals and legal entities.</li>" +
                "<li>automatic estimation   of  user's internal reliability rating, as well as personal credit rating and FICO-scoring provided by the First Credit Bureau.</li>" +
                "<li>protection of all parties in the transaction using an Electronic Digital Signature (EDS) when signing contracts .</li>" +
                "<li>using the security systems of electronic payment systems and leading banks of the Republic of Kazakhstan, the security of all financial transactions is guaranteed.</li>" +
                "</ul>" +
                "<h2 class='base-color'>Stages:</h2>" +
                "<ul class='base-color no-markers'>" +
                "<li><div class='numbers'>01.</div>" +
                "<p>Design and technical specifications.</p></li>" +
                "<li><div class='numbers'>02.</div>" +
                "<p>Design and interface development.</p></li>" +
                "<li><div class='numbers'>03.</div>" +
                "<p>Negotiations with payment systems and contract signing.</p></li>" +
                "<li><div class='numbers'>04.</div>" +
                "<p>Development of the server side and web interface.</p></li>" +
                "<li><div class='numbers'>05.</div>" +
                "<p>Testing and debugging.</p></li>" +
                "</ul>" +
                "<p class='base-color'><b>Interface design.</b>  When developing the interface, we relied on standards created by major market players, such as Kickstarter and Boomstarter. This made the platform easy to understand even for beginner investors.</p>" +
                "<h2 class='base-color'>E-SIGNATURE</h2>" +
                "<p class='base-color'>One of the important aspects of the development was the mandatory registration of all platform participants using an E-signature (Electronic Digital Signature). The local residents are required to have an E-signature on the territory of Kazakhstan. In fact, it is a passport, or in other words an electronic document that contains all the necessary information to identify a person. We developed a mechanism for secure data transmission with respect to all state-level requirements for working with E-signatures.</p>" +
                "</div>",
            result:
                "<p class='base-color'>Started in 2015, the ASAP project took 2 years. During this time, a team of 7 people was involved and more than 1,000 pages of technical documentation were developed, all standards and requirements for electronic document management at the state level were met. A turnkey crowdfunding platform has been created.</p>" +
                "<ul class='base-color'>" +
                "<li>Multilingual interface: KZ, RU, EN.</li>" +
                "<li>Automatic formation of the documents set required for signing the contract.</li>" +
                "<li>Filling the site with the content.</li>" +
                "<li>Integration by an authorized acquiring bank.</li>" +
                "<li>Secure data transmission over encrypted channels.</li>" +
                "<li>A complete security audit of servers and software.</li>" +
                "<li>Instructions and personnel training.</li>" +
                "</ul>",
            published: true
        },
        {
            preview: "/previews/rosseti.png",
            projectType: "mobile apps",
            name: "rosseti",
            path: "rosseti",
            color: "#2D8FFF",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "trading"
                },
                {
                    title: "Technology",
                    select:
                        "Java, PHP, Ruby ,C/C++, MS SQL, Titanium, Oracle Database, Apache"
                },
                {
                    title: "Solution",
                    select: "payment systems, mobile applications"
                },
                {
                    title: "Platform",
                    select: " iOS, Web"
                }
            ],
            image: {
                src: "/backgrounds/rosseti.svg",
                coords: {
                    lg: {
                        top: "380px",
                        left: "570px"
                    },
                    md: {
                        top: "500px",
                        left: "300px"
                    },
                    sm: {
                        top: "250px",
                        left: "150px"
                    }
                }
            },
            title: "Mobile application for checking the readings of electric meters",
            description:
                "A mobile solution that allows you to collect data <br> from electricity meters remotely.",
            summary:
                "<p>The total revenue of the company in recent years has approached the mark of 100 billion roubles. An impressive amount, isn’t it? In fact, it could and should be even more impressive, thanks to  prompt and careful accounting of electricity consumption. It is not easy to do this because in addition to large cities with good infrastructure, IDGC also serves remote and hard-to-reach points. Some of them don't even have a cellular connection. Therefore, it is impossible to collect electricity meter data automatically, and as for a manual data collection – it is too time-consuming.</p>" +
                "<p>That’s how things used to be before  our solution was developed.</p>",
            logo: "/logos/rosseti_logo.svg",
            fullDescription:
                "<p>Rosseti – Interregional Distribution Grid Companies (IDGC) is a division of the Rosseti Center organization, which controls almost 90% of the electricity  network in Russia. It's about all kinds of networks: household, industrial, transport, agriculture, at all kinds of sites.</p>",
            goals: null,
            objectives:
                "<p>The data collection from electric meters is carried out by checking controllers. These are IDGC's employees who come to the site, inspect the seals on the device, and take readings. In some cases, replacement or additional verification may be required. The controllers also check whether the owner's data is up to date.</p>" +
                "<p>The work is routine, it sounds pretty simple. However, given the scale of IDGC's activities and the number of connections (there are millions of them), even a bit of  time saved on each check up will have an appreciable economic effect in total. In addition, automation allows faster and more accurate comparison of the expected (based on the experience of previous years) power consumption with the current  one. So, it is possible to identify violations and prevent theft.</p>" +
                "<p><b>This is why speeding up the check ups, increasing their accuracy and informativeness became the main objectives of the project.</b></p>",
            slider: [
                "/temp/rosseti1.svg",
                "/temp/rosseti2.svg",
                "/temp/rosseti3.svg"
            ],
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Technical complexity</h2>" +
                "<p class='base-color'>The software, information archives and IDGC protocols have been formed step by step over many years. Therefore, extracting the source data and writing reports turned out to be quite time-consuming:</p>" +
                "<ul class='base-color'>" +
                "<li>Integration with MS Active Directory is required to gain access to controller accounts.</li>" +
                "<li>Tasks are uploaded from SAP.</li>" +
                "<li> The format for uploading tasks is a very large file in MS Excel format that needs to be processed before use.</li>" +
                "<li>Shortcuts to the location of the counter should be indicated on the map .</li>" +
                "<li> Further, the coordinates of the readings are determined by the geolocation of photos of electric meters.</li>" +
                "<li>And in the reverse order – Excel, SAP – the reports are saved.</li>" +
                "</ul>" +
                "<p class='base-color'><b>Thus, the development of a mobile application for Android has become almost the easiest task of the project.</b></p>" +
                "</div>",
            result:
                "<p>At present, the pilot version has been completed and delivered. This is a working prototype, which is operating in one of the regional subdivisions of IDGC, and is also being studied and approved in the head office in Moscow.</p>" +
                "<p>The next step is the development of the server part with domain authentication of users, analytical verification of readings with predicted values and other functions.</p>" +
                "<p>The solution already fulfills the initial tasks. Bypass routes are automatically built, supervisors spend less time on the road, carry out each check faster, all results are unified, they are saved for processing and generating reports.</p>" +
                "<p>In the future, this project may become a full-fledged part of the Russian energy system. If scaling to the federal network is  approved, the development will continue, and the economic effect of its implementation will be reflected in the financial reports of IDGC / Rosseti Center in the coming years.</p>",
            published: true
        },
        {
            preview: "/previews/basecam.png",
            projectType: "mobile apps",
            name: "basecam",
            path: "basecam",
            color: "#31B742",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "electronic stabilizers"
                },
                {
                    title: "Technology",
                    select: "Java, PHP"
                },
                {
                    title: "Solution",
                    select: "mobile apps"
                },
                {
                    title: "Platform",
                    select: "iOS, Android"
                }
            ],
            image: {
                src: "/backgrounds/basecam.svg",
                coords: {
                    lg: {
                        top: "380px",
                        left: "570px"
                    },
                    md: {
                        top: "450px",
                        left: "450px"
                    },
                    sm: {
                        top: "280px",
                        left: "150px"
                    }
                }
            },
            title: "Application for controlling action camera gimbals",
            description:
                "BaseCam Electronics provides solutions for camera stabilization based on direct-drive brushless motors.",
            summary: null,
            logo: "/logos/basecam_logo.svg",
            fullDescription:
                "<p>BaseCam Electronics has a network of partners from the USA, Europe, Russia, China and Japan. The company is in demand all over the world due to the flexibility in connected cameras from GoPro Hero to Red Epic and the possibility of installation on quadcopters and other UAVs.</p>",
            goals: null,
            objectives:
                "<p>Developing a control program for a video camera gimbal with a stabilization system. Previously, the customer tested a similar product for Android devices. The new technical specification included the development of an application for smartphones and tablets on iOS (iPhone, iPad of any versions). </p>",
            developmentProcess:
                "<p>To begin with, we documented the existing developments, which allowed us to quickly get involved in the work, as well as understand the complex calculations that are used in gimbal control.</p>",
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Problems with Bluetooth</h2>" +
                "<p class='base-color'>Communication with the equipment is carried out via a Bluetooth wireless interface with support for the latest versions of the protocol. At the time of the development process the iOS software market encountered a problem of interaction through the Bluetooth channel. The connection between the device and the phone was not stable, there were communication interruptions during operation. It took our team several weeks to study the problem and find a software solution. The final testing of the product showed a stable Bluetooth connection.</p>" +
                "</div>",
            slider: [
                "/temp/basecam1.svg",
                "/temp/basecam2.svg",
                "/temp/basecam3.svg"
            ],
            result:
                "<p>The task assigned to us was completed in just 4 months by a small team of two programmers, a designer and a project manager.</p>",
            published: true
        },
        {
            preview: "/previews/rompetrol.png",
            projectType: "mobile apps",
            name: "rompetrol",
            path: "rompetrol",
            color: "#ED4330",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "trading"
                },
                {
                    title: "Technology",
                    select: "laravel, react native , postgresql"
                },
                {
                    title: "Solution",
                    select: "loyalty program, mobile applications"
                },
                {
                    title: "Platform",
                    select: "iOS, Android"
                }
            ],
            image: {
                src: "/backgrounds/rompetrol.svg",
                coords: {
                    lg: {
                        top: "540px",
                        left: "570px"
                    },
                    md: {
                        top: "560px",
                        left: "400px"
                    },
                    sm: {
                        top: "320px",
                        left: "150px"
                    }
                }
            },
            title: "Mobile application for the loyalty program of gas stations",
            description:
                'A mobile solution for the loyalty program on iOS and Android platforms that meets the "Fill&Go" business logic',
            summary:
                "<p>The Rompetrol Group of Companies is engaged in crude oil production, refining and petrochemistry. The volume of production comprises millions of tons of petroleum products per year. The list of personnel consists of more than 6 thousand employees. It is a significant market player in 11 countries of the Black Sea and the Mediterranean. </p>",
            logo: "/logos/rompetrol_logo.svg",
            fullDescription:
                "<p>Rompetrol owns more than 1,100 gas stations in Romania, Moldova, Bulgaria and Georgia.'Fill&Go' technology operates at branded gas stations, which allows you to pay for fuel once a month. Thanks to the loyalty card, not only do customers save  time, but also money. The bonuses are awarded taking into account each refueling.</p>",
            goals:
                "<p>A federal or even international loyalty program without a mobile app? It sounds strange these days. A hotline phone number, subscription to mailing lists and other communication channels do not give a comparable effect. With the help of a mobile application, you can collect detailed statistics (including  geotargeting), quickly respond to requests, inform customers about new products and promotions. There are a lot of advantages, and some of them are irreplaceable. </p>",
            objectives:
                "<p>Therefore, the management of Rompetrol ordered the development of applications for iOS and Android platforms that meet the 'Fill&Go' business logic from our company. it was especially necessary to take into account the following requirements:</p>" +
                "<ul style='margin-bottom: 30px'>" +
                "<li>Two types of clients (individuals and legal entities).</li>" +
                "<li> Control of fuel costs (both for a personal car and for the entire fleet of a business client).</li>" +
                "<li>Geographical maps with the ability to select gas stations and get directions.</li>" +
                "<li>Display of the company's promotions and offers from partners.</li>" +
                "<li>Detailed statistics of purchases and discounts of users.</li>" +
                "<li>Feedback including the assessment of the quality of service at the gas station to maintain the rating system.</li>" +
                "<li>Secure data storage and transmission</li>" +
                "<li>Optimized application performance, especially the speed aspect.</li>" +
                "<li>Offline mode, available without internet connection, followed by data synchronization once back online again.</li>" +
                "</ul>" +
                "<p>In addition, we were entrusted with the development of an application design that meets the brand's corporate identity – as clear and user-friendly as possible.</p>",
            developmentProcess: null,
            haveDone:
                "<div class='text--body base-color'>" +
                "<h2>Stage 1. Application design</h2>" +
                "<p>The first thing we did after agreeing on the terms of TS was the design of a mobile application. The customer was offered a choice of several concept options. One of them was singled out and worked out in detail.</p>" +
                "<p>The structure of the application, screens and controls is concise, does not contain anything superfluous and fully covers all possible situations. The list of application interaction scenarios:</p>" +
                "<ul>" +
                "<li>Registration and login</li>" +
                "<li>Map of the way to the gas station</li>" +
                "<li>Prices and Invoices</li>" +
                "<li>Reports</li>" +
                "<li>User statistics</li>" +
                "<li>News and advertising</li>" +
                "<li>Notification system</li>" +
                "<li>Error messages</li>" +
                "<li>Personal profile</li>" +
                "<li>Feedback</li>" +
                "<li>Settings</li>" +
                "</ul>" +
                "<p>The user sees only the currently necessary information and navigation elements for the active screen. It is always obvious what can be done here and now, how to go to the main menu or go to another section.</p>" +
                "<p>Rompetrol branded color scheme, bright and contrasting messages, a lot of 'air' and visual  infographics. It’s a functional, user-friendly and an aesthetically appealing application.</p>" +
                "<h2>Stage 2. Business Logic Server</h2>" +
                "<p>At the second stage, we performed the design of the application database, as well as the integration with the information system of Rompetrol loyalty program.</p>" +
                "<p>According to the requirements of the TS, the application works both online and offline. In case there are internet connection issues, the user can view previously received information and perform various actions. Synchronization with the shared server will be performed automatically after the connection is resumed.</p>" +
                "<p>If there is an active online connection (main, normal operation), all functions are available. This includes determining your own positioning on the map and the points of the nearest gas stations to which you can get directions.</p>" +
                "<p>The application helps the customer to find the optimal places for refueling, informs about prices, discounts, and special offers. All information is taken directly from the server, so the data are relevant at the moment.</p>" +
                "" +
                "<h2>Stage 3. Mobile apps for iOS and Android</h2>" +
                "<p>At the third and final stage, we made screens according to the approved design, developed mobile applications for two platforms (iOS and Android), as well as internal tools to support them.</p>" +
                "<p>Then we tested all the functionality, made the necessary improvements and tested it again. Ready-to-use mobile applications, the integration, and the server part were the final result of the project. And all of them function flawlessly.</p>" +
                "</div>",
            slider: [
                "/temp/rompetrol1.png",
                "/temp/rompetrol2.png",
                "/temp/rompetrol3.png"
            ],
            result:
                "<p>The project in total required more than six months of work by a group of 9 specialists:</p>" +
                "<ul style='margin-bottom: 30px'>" +
                "<li>Project Manager</li>" +
                "<li>System Architect</li>" +
                "<li>Designer</li>" +
                "<li>3 Programmers</li>" +
                "<li>Layout/Make-up Designer</li>" +
                "<li>Tester</li>" +
                "<li>Technical Author</li>" +
                "</ul>" +
                "<p>It may seem that this is “a lot of people and a lot of time”. But such an assessment is usually given by people outside program development.</p>" +
                "<p>The full cycle of the project includes five large stages  of work, each of them requiring hundreds of man-hours. Most of these tasks  are carried out in parallel, and the company's previous and own designs are used. Strictly speaking, the actual development period should be longer – we just know how to 'cut corners' without harming the quality and reliability of the system..<br>In addition to development, integrations with external services were performed.<br>For example:</p>" +
                "<ul style='margin-bottom: 30px'>" +
                "<li>With a loyalty system on ORPAK</li>" +
                "<li>PLURIVA system for 'Fill&Go' system services</li>" +
                "<li>SMS gateway</li>" +
                "<li>GoogleMaps mapping service</li>" +
                "<li>Rompetrol services, news feed parsing and promotions</li>" +
                "<li>Mechanism for scanning and recognizing QR codes</li>" +
                "<li>Push notification distribution serviceй</li>" +
                "<li>Facebook Social Network API</li>" +
                "</ul>" +
                '<p><b>Any integration is a "pitfall" making the project less predictable and manageable.</b></p>' +
                "<p>The full scope of work is very large. Its description takes hundreds of pages of project documentation, let alone the initial and subsequent interviews, descriptions of all intermediate drafts  and solutions, a detailed test report, and many other aspects of development. We had to work hard. But In the end we are proud of the result. Refueling has become even easier and more convenient with the “Fill&Go” program. Consumers received a pocket control panel for fuel costs, and Rompetrol got a universal tool for interacting with their customers</p>",
            published: true
        },
        {
            preview: "/previews/amigo.png",
            projectType: "CRM/ERP",
            name: "amigo",
            path: "amigo",
            color: "#FED544",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "TRADING"
                },
                {
                    title: "Technology",
                    select: "php, vue.js, three.js, webgl, mysql, laravel, blender 3d"
                },
                {
                    title: "Solution",
                    select: "loyalty program, mobile applications"
                },
                {
                    title: "Platform",
                    select: "web"
                }
            ],
            image: {
                src: "/backgrounds/amigo_ipad_big_web.png",
                coords: {
                    lg: {
                        top: "500px",
                        left: "370px"
                    },
                    md: {
                        top: "480px",
                        left: "250px"
                    },
                    sm: {
                        top: "280px",
                        left: "120px"
                    }
                }
            },
            title: "Online service for an international dealer network",
            description:
                "At present, it's possible to sell curtains and blinds without inconvenient heavy folios and unnecessary visits to customers using the advantages of the Internet, which is faster, clearer, and more profitable.",
            summary:
                "<p>The brand consists of 39 own representative offices, as well as 11 thousand partners. It's a huge network. In fact it’s so big its employees are trained in 56 corporate institutes. In addition, 28 thousand catalogues are required annually to visually demonstrate the product range. Well, they were required. </p>" +
                "<p>Our project has changed the rules of the game. At present, it's possible to sell curtains and blinds without inconvenient heavy folios and unnecessary visits to customers using the advantages of the Internet,  which is a faster, clearer, and more profitable way of doing business.</p>",
            logo: "/logos/amigo_logo.svg",
            fullDescription:
                "<p>AMIGO GROUP is the largest manufacturer and supplier of blinds, roller blinds and pleated curtains in Russia, Ukraine, Belarus and Kazakhstan. 16 thousand products are produced using Amigo components every day. That is, an item is manufactured every 6 seconds!</p>",
            goals:
                "<p>We had to automate all these operations, and while keeping  visual clarity, we needed to achieve greater realism. A difficult goal, especially if you take into account the variety of the product range  and even a greater number of final options  because the configuration of windows can also be different.</p>",
            objectives:
                "<p>Curtains and blinds may seem primitive products only to those who are used to looking at them through the eyes of a user. Pulling them back and then pulling them out is the easiest thing to do . Behind a deceptively easy functionality there’s a   carefully thought-out design. This applies to  the visual aspect,  dimensions, fasteners, and the entire structure as a whole. AMIGO GROUP produces more than 1,200 different materials. The combinatorics of their use is too great, and  special knowledge and tools are  needed at each stage of the work.</p>" +
                "<p>Therefore, the first task of the project is  creating  a flexible, convenient online service for coordinating the work of numerous dealers in the network. Moreover, it is convenient not only for the parent company, but for all participants, each dealer and a master. The resource should demonstrate all commercially available types of materials and components, help them navigate and quickly prepare the detailed estimates for orders.</p>" +
                "<p>The second important task of the project is  accelerating  the sales. This is a complex indicator; it depends on many reasons. Previously,  a certain amount of time  was spent on delivery and demonstration of samples and drafts  of new interiors.</p>",
            developmentProcess: null,
            quotes:
                "<p>As a rule, the delivery and confirmation  of the project takes place in a relatively narrow circle – within developers and customer's project team. But not this time around because the first presentation of the service was supposed to take place at a corporate conference, in front of tens of thousands of dealers. Each of them is perfectly familiar with the specifics of production, conducts sales and is able to evaluate the new tool as meticulously as possible.</p>" +
                "<p>On top of that we had to meet  tight deadlines. Just a few months from start to finish. A high tempo without the ability to postpone part of the key functionality 'for later'... Well, it was a challenge! And we accepted it.</p>",
            haveDone:
                "<div class='text--body'>" +
                "<h2 class='base-color'>Preparation</h2>" +
                "<p class='base-color'>Despite the time constraints, we started with a detailed study of the AMIGO GROUP business. We talked a lot, visited offices and the production site. As a result of these meetings, the developers' room gradually turned into a workshop. For example, some catalogues of samples of blinds and curtains already occupy an entire room. All these materials go to work, they are used for scanning and digitization.</p>" +
                "<p class='base-color'>Interviews of our designers and UX specialists with the technologists and sellers of the customer helped to develop a 'control panel' for sales. It does not look like a regular online store because it is addressed to dealers, not end users. The main task of the resource is to quickly model and calculate orders, taking into account all technological aspects, as well as the number of items in stock .</p>" +
                "<p class='base-color'>Nevertheless, the interests of the end customers were also taken into account. The architecture of the online service involves the creation of a virtual showcase, which is convenient to view without any special knowledge. Everyone can look through the catalogues, check what the product looks like in the interior similar to their room and save the cart changes as they usually do when shopping online. However, the order can be placed only after the dealer's review, check and completion .</p>" +
                "</div>",
            slider: ["/temp/amigo1.png", "/temp/amigo2.png"],
            result:
                "<h3>The service visual aspect</h3>" +
                "<p>The designers came up with an unusual solution – one single (1) screen. Not the first one, as a sort of a 'Home page' of a large online resource, but the only one. How can you fit diverse functionality and a wide product range in such a tiny space ?<br>That’s the way you can do it:</p>" +
                "<img alt='result1' src='/temp/amigo_res_1.png' />" +
                "<p>Please note, that despite a  large number of different options, navigation is not overloaded. Everything is right at your fingertips  yet not everything is actually displayed. This is somewhat a remote control, and it works in a classic way – the engine is hidden under the hood, the dashboard, steering wheel and control levers are visible. There are large chunks of data and complex data analysis    behind each button, list, and switch. The deceptively simple and 'light' interface required two months of hard work, during which dozens of prototypes were created. We replaced and refined them according to the comments of AMIGO GROUP specialists, gradually approaching the result with each step.</p>" +
                "<div class='row no-gutters'>" +
                "<div class='col col-12 col-md-6 pr-md-2'><img alt='result2' src='/temp/amigo_res_2.png' /></div>" +
                "<div class='col col-12 col-md-6 pl-md-2'><img alt='result3' src='/temp/amigo_res_3.png' /></div>" +
                "</div>" +
                "<p>Special attention was paid to how realistic the product looks when shown in a specific interior. Not only is it important for aesthetic reasons (although they were also taken into account), but it’s also crucial to ensure a full-fledged demonstration and online 'fitting'. interior designers are very sensitive to the transition from the tactile to the visual level. They need a really flawless picture, one that would take into account geometry, lighting, and other parameters.<br>" +
                "    Therefore, after some thought and debate, we abandoned the obviously simpler way with 'flat' drawing of samples on the screen in favor of a full-fledged 3D model. <b>The scope of work has grown dramatically (we still continue to digitize sample catalogues). But the layered formation of each slide allows us to achieve a photorealistic look .</b></p>" +
                "<p>That’s what a photo represents for a viewer, each fitting is like a frame with different colors and texture of curtains or blinds. In fact, all materials are recorded separately, and so is the configuration of the interior (including the window opening), as well as all fittings and other details too.<br>When you click on the list, choosing a new material to be shown, the scene is completely redrawn. Thanks to the optimization of the 'engine under the hood', this happens so quickly that it seems as if the next photo has just been uploaded <br>" +
                "<b>The flexible architecture completely satisfied all the customer's specialists and experts who have already tried our solution in their work. You can choose the shape of the windows, the colors of the frame and accessories. You can even control the level of illumination, getting the right idea of what a particular type of product will look like in a certain interior.</b></p>" +
                "<p>Careful drawing of the interior also helps the  end users. It is easier to understand exactly what to choose from hundreds and thousands of color options when you can immediately see exactly how it will look after the installation. It is more difficult for buyers to make a decision without a three-dimensional visualization, and ultimately they waste not only their time, but also the time of the dealer's specialists. <b>Thus, the investment in the three-dimensional graphics will pay off for our customer twice – it will save time both when confirming and executing the order.</b></p>" +
                "<p>The online service also automates all the basic steps for making estimates. They are formed in just a few clicks. The site calculates the cost of the selected products by itself. The dealer only has to press the button to send the detailed order to production.</p>" +
                "<p>It should be added that besides the showcase, the system also has a technical part. In other words, pros can look 'under the hood' to get quick access to large volumes of official and commercial information. For example, this is how the specification clarification for the ordered items looks like:</p>" +
                "<div class='row no-gutters'>" +
                "<div class='col col-12 col-md-6 pr-md-2'><img alt='result4' src='/temp/amigo_res_4.png'></div>" +
                "<div class='col col-12 col-md-6 pl-md-2'><img alt='result5' src='/temp/amigo_res_5.png'></div>" +
                "</div>" +
                "<p>This part of the service is not visible from the outside, it is for official use, that is, available only to dealers – and the brand management company, of course. <br><b>Now it has become much easier to run  sales.</b></p>" +
                "<p>What is especially important is that AMIGO GROUP will be able to save time and money on communicating its quality standards to every regional representative, every dealer. It is enough to transfer a new material to the database and describe the parameters of its implementation. In a certain sense, we have automated not only the demonstration and selection of products, but also the customer's business itself.</p>" +
                "<h2>Summary</h2>" +
                "<p>We continue working on this project. We are adding new types of materials to the service database, expanding the library of profiles, textures, and other visualization options. In addition, according to the results of the pilot testing, dealers receive useful feedback in the form of proposals for new functions, 'polishing' the system for their business processes. We collect and process such signals, they form a stack of improvements at the next stage of service development.</p>" +
                "<p>The result that has already been achieved is the unusual and functional tool for dealers (b2b), as well as a colorful and visual way to showcase the product range even to end consumers (b2c). And all this while using one screen!!</p>",
            published: true
        },
        {
            preview: "/previews/payme.png",
            projectType: "mobile apps",
            name: "Pay-Me",
            path: "pay-me",
            color: "#D3AA17",
            background: "white",
            selects: [
                {
                    title: "Industry",
                    select: "trading"
                },
                {
                    title: "Technology",
                    select:
                        "Java, PHP, Ruby ,C/C++, MS SQL, Titanium, Oracle Database, Apache"
                },
                {
                    title: "Solution",
                    select: "payment systems, mobile apps"
                },
                {
                    title: "Platform",
                    select: "iOS, Web"
                }
            ],
            image: {
                src: "/backgrounds/payme.svg",
                coords: {
                    lg: {
                        top: "342px",
                        left: "630px"
                    },
                    md: {
                        top: "450px",
                        left: "395px"
                    },
                    sm: {
                        top: "230px",
                        left: "94px"
                    }
                }
            },
            title: "Pay-me payment <br>system",
            description:
                "A mobile solution that allows you to accept Visa and MasterCard payments.",
            summary:
                "<p>The customer who contacted us, in partnership with Alfa-Bank, VISA and Beeline, decided to create in Russia a service for accepting payments via smartphones, called 'Pay-Me'. The essence of the technology is to use any smartphone or a digital tablet as a credit card scanning machine. To do this, a special reader is connected to a smartphone.</p>",
            logo: "/logos/payme_logo.png",
            fullDescription:
                "<p>Everyone who has traveled to Europe knows that you can pay with a bank card almost everywhere there, whether it’d be a small shop or a hypermarket. The situation with supermarkets in Russia is not bad, but cashless payment at small points of sale is minimal even though everyone has bank cards these days. In fact, small businesses are unable to develop a system for accepting non-cash payments.</p>",
            goals: null,
            objectives: null,
            developmentProcess: null,
            haveDone:
                "<div class='text--body base-color'>" +
                "<h2>The result of work</h2>" +
                "<p>The customer who contacted us, in partnership with Alfa-Bank, VISA and Beeline, decided to create in Russia a service for accepting payments via smartphones, called 'Pay-Me'. The essence of the technology is to use any smartphone or a digital tablet as a credit card scanning machine. To do this, a special reader is connected to a smartphone.</p>" +
                "<h5 class='my-8'>FOR 5 MONTHS OF WORK THE FOLLOWING HAS BEEN DONE ON 'THE TURNKEY BASIS':</h5>" +
                "<ul class='base-color no-markers two-cols'>" +
                "<li><div class='numbers'>01.</div>" +
                "<p>The needed equipment selected, purchased, stitched, and configured</p></li>" +
                "<li><div class='numbers'>02.</div>" +
                "<p>Mobile applications for smartphones using iOS and Android have been developed</p></li>" +
                "<li><div class='numbers'>03.</div>" +
                "<p>Drivers for working with card readers have been written</p></li>" +
                "<li><div class='numbers'>04.</div>" +
                "<p>Integration with the processing bank has been implemented</p></li>" +
                "<li><div class='numbers'>05.</div>" +
                "<p>Dozens of regulations and other technical documents have been developed</p></li>" +
                "<li><div class='numbers'>06.</div>" +
                "<p>Company and product website created</p></li>" +
                "<li><div class='numbers'>07.</div>" +
                "<p>The second and third technical support lines have been launched, working 24/7</p></li>" +
                "</ul>" +
                "</div>",
            slider: ["/temp/payme1.png", "/temp/payme2.png", "/temp/payme3.png"],
            result:
                "<p>As a result, the final product  currently operates in four countries (Russia, Kazakhstan, Ukraine, Belarus) and  is used by top banks and mobile operators. Beeline-Business and Alfa-Bank actively sell devices to corporate customers (in total, more than 15,000 devices have been sold since 2013 and more than 4,000 connection applications have been received).<br>" +
                "The work on the project lasted 18 months. A team of 20 people worked on the project including mobile application developers, web programmers, an SDK developer for the bank card reader module and a design team.<br>" +
                "After the end of the main cycle of work and semi-annual technical support, the product was transferred to the customer's balance sheet. At present, it’s completely independent of the developer company.</p>",
            published: true
        },
    ],
    tags: [{ name: "development" }, { name: "mobile" }, { name: "design" }],
    reviewTags: [
        { name: "desktop", isSelected: false },
        { name: "МФО", isSelected: false },
        { name: "хэштег", isSelected: false },
        { name: "development", isSelected: false }
    ],
    selectList: [
        {
            title: "Industry",
            isSelected: false,
            select: [{ text: "Car alarms", value: 1 }],
            id: 1
        },
        {
            title: "​Technology",
            isSelected: false,
            select: [{ text: "​Technology", value: 3 }],
            id: 2
        },
        {
            title: "Solution",
            isSelected: false,
            select: [{ text: "Solution", value: 4 }],
            id: 3
        },
        {
            title: "Platform",
            isSelected: false,
            select: [
                { text: "IOS", value: 5 },
                { text: "Android", value: 6 },
                { text: "PC", value: 7 }
            ],
            id: 4
        }
    ]
};

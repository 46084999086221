
























































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import { i18n } from "@/main";
import { Locales } from "@/i18n/locales";

const Locale = namespace("Locale");

const Modal = namespace("Modal");

@Component({
  components: { TecBtn },
})
export default class TecFooter extends Vue {
  @Modal.Action
  public showModal!: (switcher: boolean) => void;
  @Modal.Action
  public ModalType!: (ModalType: string) => void;

  public currentYear = new Date().getFullYear();

  private body = document.body;

  public showModalFunc() {
    this.showModal(true);
    this.ModalType("request");
    this.body.classList.add("noscroll");
  }
  @Locale.State
  public lang!: Locales;
  @Locale.Action
  public SAVE_LANG!: (newLang: Locales) => void;
  get selectedLang(): Locales {
    return this.lang;
  }
  set selectedLang(newValue: Locales) {
    this.SAVE_LANG(newValue);
    i18n.locale = newValue;
  }
}

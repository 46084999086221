




























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Tectabs, Tabs } from "@/interfaces";
import TecBtn from "@/components/tecBtn/TecBtn.vue";
import Slider from "@/components/slider/Slider.vue";

@Component({
  components: { Slider, TecBtn },
})
export default class TecTabs extends Vue {
  @Prop({ default: undefined }) readonly tabs?: Tectabs;

  public tab = null;
  public value: Tabs | null = null;

  mounted() {
    this.value = this.tabs ? this.tabs.items[0] : null;
  }
  updated() {
    this.value = this.tabs ? this.tabs.items[0] : null;
  }
}
